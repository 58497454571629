import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import { SERVER_URL } from '../../utils/environment';
import SearchDropdown from '../SearchDropdown';

function CompanySearch({ intl, setCompany, setError, planType, companyName }) {
  const isMounted = useRef(false);
  const [companies, setCompanies] = useState([]);
  const [query, setQuery] = useState('');

  const placeholder = intl.formatMessage({
    id: 'searchDropdown.placeholder',
  });

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const searchDelay = setTimeout(() => searchCompanies(), 300);

    return () => clearTimeout(searchDelay);
  }, [query]);

  function handleInputChange(newQuery) {
    setQuery(newQuery);
  }

  function searchCompanies() {
    if (
      query !== '' &&
      query !== null &&
      query !== undefined &&
      query.length >= 3
    ) {
      const requestUrl = `${SERVER_URL}/api/v2/company/${companyName}_search?query=${query}&plan_type=${planType}`;

      axios
        .get(requestUrl)
        .then(response => {
          if (isMounted.current) {
            setCompanies(response.data);
          }
        })
        .catch(error => setError(error));
    } else {
      setCompanies([]);
    }
  }

  return (
    <SearchDropdown
      label={<FormattedMessage id="eap.employer" />}
      options={companies}
      name="company"
      handleChange={setCompany}
      handleInputChange={handleInputChange}
      placeholder={placeholder}
    />
  );
}

CompanySearch.propTypes = {
  intl: intlShape.isRequired,
  setCompany: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  planType: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
};

CompanySearch.defaultProps = {};

export default injectIntl(CompanySearch);

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'gatsby';
import { unauthAuditProgress } from '../../../utils/pageUtils';
import { logEvent, OPEN_SIGNUP } from '../../../utils/amplitude';
import checkboxOn from '../../../images/ilob/checkbox-on.png';

const Section9 = () => {
  const toggleSignUp = () => {
    localStorage.setItem('signUp', 'open');
    logEvent(OPEN_SIGNUP, { page: '' });
    unauthAuditProgress('', 'open_signup');
    window.scrollTo({
      top: window.pageYOffset + 2,
    });
  };
  return (
    <div className="section-9">
      <div className="section-9-header">
        <FormattedMessage
          id="ilob.yourSecurity"
          defaultMessage="Your security is our top priority:"
        />
      </div>
      <div className="text-container">
        <div className="step-item">
          <div className="checkbox">
            <img src={checkboxOn} alt="Checkbox" />
          </div>
          <p>
            <FormattedMessage
              id="ilob.weFollow"
              defaultMessage="We follow strict Canadian regulations for online health services."
            />
          </p>
        </div>
        <div className="step-item">
          <div className="checkbox">
            <img src={checkboxOn} alt="Checkbox" />
          </div>
          <p>
            <FormattedMessage
              id="ilob.allInformation"
              defaultMessage="All information is fully encrypted on a regularly audited server in Toronto."
            />
          </p>
        </div>
        <div className="step-item">
          <div className="checkbox">
            <img src={checkboxOn} alt="Checkbox" />
          </div>
          <p>
            <FormattedMessage
              id="ilob.onlyYou"
              defaultMessage="Only you and your counsellor have access to your mental health information."
            />
          </p>
        </div>
        <div className="step-item">
          <div className="checkbox">
            <img src={checkboxOn} alt="Checkbox" />
          </div>
          <p>
            <FormattedMessage
              id="ilob.allCounsellors"
              defaultMessage="All counsellors reside in Canada, are highly experienced, and are regulated professionals."
            />
          </p>
        </div>
      </div>
      <div className="button-container">
        <button className="medium-blue-button" onClick={toggleSignUp}>
          <FormattedMessage
            id="navigation.createMyAccount"
            defaultMessage="Get Started"
          />
        </button>
      </div>
    </div>
  );
};

Section9.propTypes = {};

export default Section9;

import React, { useState } from 'react';

import GatedSignUp from './GatedSignUp';
import NonGatedSignUp from './NonGatedSignUp';

function DomainGated(props) {
  const [showSignup, setShowSignup] = useState(false);

  return showSignup ? (
    <NonGatedSignUp {...props} />
  ) : (
    <GatedSignUp {...props} redirectToSignup setShowSignup={setShowSignup} />
  );
}

export default DomainGated;

import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { isOmers } from '../../utils/pageUtils';

const Section2 = () => {
  const path =
      typeof window !== 'undefined' &&
      window &&
      window.location &&
      window.location.pathname;
  return (
    <div className="section-2-league">
      <div className="by-the-numbers">
        {
              isOmers(path) ?
                <div className="stat">
                  <h5>1,000,000+</h5>
                  <FormattedHTMLMessage
                    tagName="p"
                    id="league.haveAccess"
                    defaultMessage="Employees have access to Inkblot"
                  />
                </div>
            :
                <div className="stat">
                  <h5>7500+</h5>
                  <FormattedHTMLMessage
                    tagName="p"
                    id="new.canadians"
                    defaultMessage="Canadians have used Inkblot to feel better"
                  />
                </div>
            }
        <div className="stat">
          <h5>400+</h5>
          <FormattedHTMLMessage
            tagName="p"
            id="new.familyPhysicians"
            defaultMessage="Family physicians have referred to Inkblot"
          />
        </div>
        <div className="stat">
          <h5>1900+</h5>
          <FormattedHTMLMessage
            tagName="p"
            id="league.organizationsUsed"
            defaultMessage="Companies have used Inkblot"
          />
        </div>
      </div>
    </div>
  );
};


Section2.propTypes = {};

export default Section2;

import React from 'react';
import { FormattedMessage } from 'react-intl';
import blob from '../../../images/cfs/blob.png';

const Section4 = () => (
  <div className="section-4">
    <div className="flex-row">
      <div className="half-column">
        <div className="s4-header">
          <FormattedMessage
            id="cfs.accessingC"
            defaultMessage="Accessing care is easy."
          />
        </div>
      </div>
      <div className="s4-image">
        <img src={blob} alt="Background Blob" height="438.4px" width="398px" />
      </div>
    </div>
  </div>
);

Section4.propTypes = {};

export default Section4;

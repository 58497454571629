import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ErrorText from '../ErrorText';
import EyeIcon from '../EyeIcon';
function TextInput({ errorText, handleChange, label, name, type }) {
  const [visiblePassword, setVisiblePassword] = useState(false);

  if (type === 'password') {
    return (
      <div className="text-input">
        <h4>{label}</h4>
        <div className="password-input">
          <div className="input-wrapper">
            <input
              onChange={handleChange}
              type={visiblePassword ? 'text' : 'password'}
              name={name}
            />
          </div>
          <EyeIcon isVisible={visiblePassword[name]} onClick={() => setVisiblePassword(!visiblePassword)} />
        </div>
        <ErrorText>{errorText}</ErrorText>
      </div>
    );
  }

  return (
    <div className="text-input">
      <h4>{label}</h4>
      <input name={name} type={type} onChange={handleChange} />
      <ErrorText>{errorText}</ErrorText>
    </div>
  );
}

TextInput.propTypes = {
  errorText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  handleChange: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
};

TextInput.defaultProps = {
  errorText: '',
  handleChange: () => { },
  label: '',
  type: 'text',
};

export default TextInput;

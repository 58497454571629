import React from 'react';
import _ from 'lodash';
import SectionContainer from '../SectionContainer';
import { aboutData } from '../../pagePartials/gallivan/about';
import inkblotLogo from '../../images/_ink-logo.svg';

const About = () => (
  <div className="gallivan-about-page">
    <SectionContainer container num={1}>
      <h1>About</h1>
      {_.map(aboutData, ({ id, text, title }) => (
        <div key={id}>{title ? <h2>{text}</h2> : <p>{text}</p>}</div>
      ))}
      <figure>
        <img src={inkblotLogo} alt="Inkblot" />
      </figure>
      <div style={{ height: 70 }} />
    </SectionContainer>
  </div>
);

export default About;

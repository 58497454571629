/* eslint-disable consistent-return */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import _ from 'lodash';

import { validEmail } from 'src/utils/form';
import Consent from '../auth/consent';
import PropTypes from 'prop-types';
import axios from 'axios';
import { injectIntl } from 'react-intl';
import { Link } from 'gatsby';

import {
  SERVER_URL,
  COUNTRY,
  OTHER_MARKETING_URL,
  ENVIRONMENT,
} from '../../utils/environment';
import { redirectToTherapy } from '../../utils/redirect';
import {
  unauthAuditProgress,
  auditProgress,
  parseCompanyCodeFromPath,
  whichCompany,
} from '../../utils/pageUtils';
import LogEventOnMount from '../LogEventOnMount';
import { OPEN_SIGNUP, logEvent, COMPLETED_SIGNUP } from '../../utils/amplitude';
import { minSignupData } from '../../pagePartials/auth';
import EyeIcon from '../EyeIcon';

class DMZSignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signUpDisabled: false,
      error: {},
      visiblePassword: {},
    };
  }

  componentWillMount() {
    const company = new URL(window.location.href).searchParams.get('company');
    const americanOnly = ['cbn'].includes(company);
    if (americanOnly && COUNTRY === 'CA' && ENVIRONMENT !== 'DEVELOPMENT') {
      window.location.replace(`${OTHER_MARKETING_URL}/sign_up?company=${company}`);
    }
  }

  componentDidMount() {
    unauthAuditProgress(parseCompanyCodeFromPath(), 'open_signup');
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.recentlyUpdated !== prevState.recentlyUpdated) {
      this.validateForm(this.state.recentlyUpdated);
    }
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      recentlyUpdated: name,
    });
  };

  handleChangeCheckbox = event => {
    this.setState({
      privacyConsent: event.target.checked,
      recentlyUpdated: event.target.name,
    });
  };

  handleSubmit = event => {
    const { signUpDisabled } = this.state;
    event.preventDefault();

    if (!signUpDisabled) this.signUp();
  };

  emailExists = email => {
    const { path } = whichCompany();

    axios
      .get(`${SERVER_URL}/api/signup/check_email?email=${email}`)
      .then(() => {
        this.setState({ error: { ...this.state.error, email: undefined } });
      })
      .catch(() => {
        this.setState({
          error: {
            ...this.state.error,
            email: <span>
              An account already exists. Please <Link to={`/sign_in?company=${path}`} style={{ color: '#2a5ccd' }}>sign in</Link> instead.
            </span>,
          }
        });
      });
  };

  signUp = () => {
    const companyParam = new URL(window.location.href).searchParams.get('company');
    let corporate_account = companyParam === 'eastcoaststrong' ? null : companyParam;
    corporate_account = corporate_account.replace('\\')
    const referral_source =
    companyParam === 'eastcoaststrong' &&
        !this.state.referral_source
        ? 'East Coast Strong'
        : this.state.referral_source;

    const { privacyConsent } = this.state;
    const values = _.omit(this.state, [
      'showForm',
      'message',
      'signUpDisabled',
      'error',
      'signUpError',
      'recentlyUpdated',
      'privacyConsent'
    ]);
    const mergedValues = { ...values, source: 'therapy', consent_yn: privacyConsent, corporate_account, referral_source };
    axios
      .post(`${SERVER_URL}/api/signup`, mergedValues)
      .then(response => {
        const token = response.data.access_token;
        logEvent(COMPLETED_SIGNUP, { page: parseCompanyCodeFromPath() });
        auditProgress(parseCompanyCodeFromPath(), 'completed_signup', token);
        redirectToTherapy(token);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log(e);
      });
  };

  validateForm = (recentlyUpdated) => {
    const requiredField = 'This is a required field';
    const emailValid = 'Please enter an email address - Example, user@example.com';
    const passwordSix = 'Password must be at least 8 characters';
    const passwordMatch = 'Passwords must match';

    const {
      first_name,
      last_name,
      email,
      password,
      privacyConsent,
      password_confirm,
    } = this.state;
    const error = {};

    if (first_name === '') {
      error.first_name = requiredField;
    }
    if (last_name === '') {
      error.last_name = requiredField;
    }
    if (email === '') {
      error.email = requiredField;
    }
    if (!privacyConsent) {
      error.privacyConsent = requiredField;
    }
    if (email) {
      if (validEmail.test(email)) {
        //to reduce memory usage, we will call check_email api only when email field updated
        if (recentlyUpdated === 'email') {
          this.emailExists(email);
        }
      } else {
        error.email = emailValid;
      }
    }
    if (password) {
      if (password.length < 8) {
        error.password = passwordSix;
      } else if (password !== password_confirm) {
        error.password_confirm = passwordMatch;
      }
    }

    this.setState({
      recentlyUpdated: null,
      signUpDisabled:
        !_.isEmpty(error) ||
        !first_name ||
        !last_name ||
        !privacyConsent ||
        !email ||
        !password ||
        !password_confirm,
      error,
    });
  };

  renderConsent = () => {
    const { error } = this.state;
    return (
      <Consent error={error} handleChangeCheckbox={this.handleChangeCheckbox} />
    );
  };

  renderInputs = inputs => {
    const { error, visiblePassword } = this.state;
    return (
      _.map(inputs, ({
        id, name, type, fullWidth, text, defaultText,
      }) => (
        <div key={id}>
          <h4>
            {this.props.intl.formatMessage({
              id: text,
              defaultMessage: defaultText,
            })}
          </h4>
          {type === 'password' ? (
            <div className="password-input">
              <div className="text-input">
                <input
                  id={name}
                  className={fullWidth ? 'full-width' : ''}
                  onChange={event => this.handleChange(event)}
                  type={visiblePassword[name] ? 'text' : 'password'}
                  name={name}
                  placeholder={defaultText}
                  value={this.state[name]}
                />
              </div>
              <EyeIcon isVisible={visiblePassword[name]} onClick={() => {
                const visible = { ...visiblePassword };
                visible[name] = !visiblePassword[name];
                this.setState({ visiblePassword: { ...visible } });
              }}
              />
            </div>
          ) : (
            <input
              id={name}
              className={fullWidth ? 'full-width' : ''}
              onChange={event => this.handleChange(event)}
              type={type}
              name={name}
              placeholder={defaultText}
              value={this.state[name]}
            />
          )}
          <p className={`error-text ${error[name] ? '' : 'hidden'}`} style={{ fontSize: '11px', position: 'absolute' }}>
            {error[name]}
          </p>
        </div>
      ))
    );
  };

  render() {
    const companyParam = new URL(window.location.href).searchParams.get('company')
    const showRole = ['mapleleaffoods', 'thesweetpotato', 'cbn'].includes(companyParam);
    const showCompany = ['cbn'].includes(companyParam);
    const showCountry = ['mapleleaffoods'].includes(companyParam);
    const showFullWidth = [
      'dmz',
      'mapleleaffoods',
      'cbn',
      'eastcoaststrong',
      'dream',
    ].includes(companyParam);
    const showSchool = ['eastcoaststrong'].includes(companyParam);
    const { path } = whichCompany();
    return (
      <div className="custom-auth custom-dependants" role="main">
        <MetaTags>
          <meta name="robots" content="noindex" />
        </MetaTags>

        <div
          className="dependants-container custom-auth-container"
          style={{ margin: '50px auto' }}
        >
          <div className="dependant-auth-form">
            <h2>
              {this.props.intl.formatMessage({
                id: 'eap.signup',
                defaultMessage: 'Sign up',
              })}
            </h2>
            <form onSubmit={this.handleSubmit}>
              {this.renderInputs(minSignupData)}
              {/* <div>
                <h4>
                  {this.props.intl.formatMessage({
                    id: 'eap.firstName',
                    defaultMessage: 'First Name',
                  })}
                </h4>
                <input
                  onChange={value => this.handleChange(value)}
                  required
                  type="text"
                  name="first_name"
                  placeholder="John"
                />
              </div>
              <div>
                <h4>
                  {this.props.intl.formatMessage({
                    id: 'eap.lastName',
                    defaultMessage: 'Last Name',
                  })}
                </h4>
                <input
                  onChange={value => this.handleChange(value)}
                  required
                  type="text"
                  name="last_name"
                  placeholder="Doe"
                />
              </div> */}
              {/* <div>
                <h4>
                  {this.props.intl.formatMessage({
                    id: 'eap.email',
                    defaultMessage: 'Email',
                  })}
                </h4>
                <input
                  onChange={value => this.handleChange(value)}
                  required
                  type="text"
                  name="email"
                  placeholder="jdoe@example.com"
                  className={showFullWidth && 'full-width'}
                />
                {this.state.emailError && (
                  <p className="error-text">
                    An account already exists. Please <Link to={`/sign_in?company=${path}`} style={{ color: '#2a5ccd' }}>sign in</Link> instead.
                  </p>
                )}
              </div> */}
              {showSchool && (
                <div>
                  <h4>
                    {this.props.intl.formatMessage({
                      id: 'eap.school',
                      defaultMessage: 'Institution/School (Optional)',
                    })}
                  </h4>
                  <input
                    onChange={value => this.handleChange(value)}
                    type="text"
                    name="referral_source"
                    className="full-width"
                  />
                </div>
              )}
              {showRole && (
                <div>
                  <h4>
                    {this.props.intl.formatMessage({
                      id: 'eap.role',
                      defaultMessage: 'Status',
                    })}
                  </h4>
                  <select
                    className="role-dropdown"
                    onChange={value => this.handleChange(value)}
                    type="text"
                    name="status"
                    defaultValue=""
                    required
                  >
                    <option value="">Select an option</option>
                    <option value="1">Employee/Member</option>
                    <option value="3">Spouse</option>
                    <option value="4">Dependent</option>
                  </select>
                </div>
              )}
              {showCountry && (
                <div>
                  <h4>
                    {this.props.intl.formatMessage({
                      id: 'eap.country',
                      defaultMessage: 'Country',
                    })}
                  </h4>
                  <select
                    className="role-dropdown"
                    onChange={value => this.handleChange(value)}
                    type="text"
                    name="country"
                    defaultValue=""
                    required
                  >
                    <option value="">Select an option</option>
                    <option value="Canada">Canada</option>
                    <option value="United States">United States</option>
                  </select>
                </div>
              )}
              {showCompany && (
                <div>
                  <h4>
                    {this.props.intl.formatMessage({
                      id: 'eap.company',
                      defaultMessage: 'Company',
                    })}
                  </h4>
                  <select
                    className="role-dropdown"
                    onChange={value => this.handleChange(value)}
                    type="text"
                    name="company_alias"
                    defaultValue=""
                    required
                  >
                    <option value="">Select an option</option>
                    <option value="CBNSTI">
                      CBN Secure Technologies Inc. (CBNSTI)
                    </option>
                    <option value="NFCBN">Nanofactory CBN Inc. (NFCBN)</option>
                    <option value="CBNSTIL">CBNSTI-Lottery</option>
                  </select>
                </div>
              )}
              {/* <div>
                <h4>
                  {this.props.intl.formatMessage({
                    id: 'eap.password',
                    defaultMessage: 'Password',
                  })}
                </h4>
                <input
                  onChange={value => this.handleChange(value)}
                  required
                  min="8"
                  type="password"
                  name="password"
                />
              </div>
              <div>
                <h4>
                  {this.props.intl.formatMessage({
                    id: 'eap.confirmPassword',
                    defaultMessage: 'Retype Password',
                  })}
                </h4>
                <input
                  onChange={value => this.handleChange(value)}
                  required
                  min="8"
                  type="password"
                  name="password_confirm"
                />
              </div> */}
              {this.renderConsent()}
              <div>
                <button type="submit">
                  {this.props.intl.formatMessage({
                    id: 'eap.signup',
                    defaultMessage: 'Sign Up',
                  })}
                </button>
              </div>
            </form>
            <span className="accessibility-h4">
              {this.props.intl.formatMessage({
                id: 'eap.alreadyHave',
                defaultMessage: 'Already have an account?',
              })}
              <Link to={`/sign_in?company=${path}`} style={{ color: '#2a5ccd' }}>
                {this.props.intl.formatMessage({
                  id: 'eap.signin',
                  defaultMessage: 'Sign In',
                })}
              </Link>
            </span>
          </div>
        </div>
        <LogEventOnMount
          eventType={OPEN_SIGNUP}
          eventProperties={{ page: parseCompanyCodeFromPath() }}
        />
      </div>
    );
  }
}

DMZSignup.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(DMZSignup);

import React from 'react';
import { FormattedMessage } from 'react-intl';
import section2Image from '../../../images/cfs/grid-icons.png';
import { isCFS } from '../../../utils/pageUtils';

const Section2 = () => {
  const path =
    typeof window !== 'undefined' &&
    window &&
    window.location &&
    window.location.pathname;
  return (
    <div className="section-2">
      <div className="flex-row">
        <div className="half-column-image">
          <img
            src={section2Image}
            alt="Grid Icon"
            height="562px"
            width="521px"
          />
        </div>
        <div className="half-column">
          <div className="section-2-header">
            <FormattedMessage
              id="cfs.gettingStarted"
              defaultMessage="Getting started is simple."
            />
          </div>
          <div className="v2-s4-text">
            <div className="steps">
              <div className="s2-steps">
                <div className="step-item">
                  <div className="ellipse one"> 1 </div>
                  <p>
                    <FormattedMessage
                      id="cfs.fillOut"
                      defaultMessage="Fill out our simple survey."
                    />
                  </p>
                </div>
                <div className="step-item">
                  <div className="ellipse two"> 2 </div>
                  <p>
                    <FormattedMessage
                      id="cfs.chooseA"
                      defaultMessage="Choose a counsellor matched specifically to you."
                    />
                  </p>
                </div>
                <div className="step-item">
                  <div className="ellipse three"> 3 </div>
                  <p>
                    {isCFS(path) ? (
                      <FormattedMessage
                        id="cfs.scheduleY"
                        defaultMessage="Schedule your first session with no wait times. Evening and weekend appointments available."
                      />
                    ) : (
                      <FormattedMessage
                        id="nshn.scheduleY"
                        defaultMessage="Schedule your first session within 24-72 hours. Evening and weekend appointments available."
                      />
                    )}
                  </p>
                </div>
                <div className="step-item">
                  <div className="ellipse four"> 4 </div>
                  <p>
                    <FormattedMessage
                      id="cfs.speakWith"
                      defaultMessage="Speak with your counsellor and feel better."
                    />
                  </p>
                </div>
                {!isCFS(path) && (
                  <div className="step-item">
                    <div className="ellipse one"> 5 </div>
                    <p>
                      <FormattedMessage
                        id="nshn.additionally"
                        defaultMessage="Additionally, book an appointment with a lawyer, financial advisor, health coach, life coach or career coach on the platform, with appointments readily available."
                      />
                    </p>
                  </div>
                )}
                <div className="step-vertical-line">
                  {isCFS(path) ? (
                    <div className="svl-1"></div>
                  ) : (
                    <div className="svl-2"></div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Section2.propTypes = {};

export default Section2;

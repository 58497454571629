import isEmpty from 'lodash/isEmpty';

export default function disableSubmit(values, errors, dependant = false) {
  return (
    !isEmpty(errors) ||
    !values.first_name ||
    !values.last_name ||
    !values.email ||
    !values.password ||
    !values.password_confirm ||
    (dependant && !values.relationship)
  );
}

import React from 'react';
import Section1 from './Sections/Section1';
import Section2 from './Sections/Section2';
import Section3 from './Sections/Section3';
import Section4 from './Sections/Section4';
import Section5 from './Sections/Section5';
import Section6 from './Sections/Section6';
import Section7 from './Sections/Section7';
import Section8 from './Sections/Section8';
import Section9 from './Sections/Section9';
import Section10 from './Sections/Section10';
import { MetaTags } from 'react-meta-tags';

const Index = () => (
  <div className="new-cfs" role="main">
    <Section1 />
    <Section2 />
    <Section3 />
    <Section4 />
    <Section5 />
    <Section6 />
    <Section7 />
    <Section8 />
    <Section9 />
    <Section10 />
    <MetaTags>
      <meta name="robots" content="noindex" />
    </MetaTags>
  </div>
);

Index.propTypes = {};

export default Index;

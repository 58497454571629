import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import _ from 'lodash';
import { injectIntl } from 'react-intl';
import { Link } from 'gatsby';

import { validEmail } from 'src/utils/form';
import Consent from '../auth/consent';
import EyeOpenImg from '../../images/eye-open.svg';
import EyeHideImg from '../../images/eye-hide.svg';
import { SERVER_URL } from '../../utils/environment';
import { redirectToTherapy } from '../../utils/redirect';
import LogEventOnMount from '../LogEventOnMount';
import { OPEN_SIGNUP } from '../../utils/amplitude';
import { parseCompanyCodeFromPath } from '../../utils/pageUtils';
import { indigoSignupData } from '../../pagePartials/auth';
import { validateBirthday } from '../../utils/form';
import EyeIcon from '../EyeIcon';

class IndigoSignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dob: '',
      recentlyUpdated: null,
      signUpDisabled: false,
      errorMessage: '',
      error: {},
      visiblePassword: {},
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.recentlyUpdated !== prevState.recentlyUpdated) {
      this.validateForm(this.state.recentlyUpdated);
    }
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: name === 'dob' ? this.normalizeDate(value) : value,
      recentlyUpdated: name,
    });
  };

  handleChangeCheckbox = event => {
    this.setState({
      privacyConsent: event.target.checked,
      recentlyUpdated: event.target.name,
    });
  };

  handleSubmit = event => {
    const { signUpDisabled } = this.state;
    event.preventDefault();

    if (!signUpDisabled) this.signUp();
  };

  emailExists = email => {
    axios
      .get(
        `${SERVER_URL}/api/signup/check_email?email=${encodeURIComponent(
          email,
        )}`,
      )
      .then(() => {})
      .catch(e => {
        this.setState({
          error: {
            ...this.state.error,
            email: e.response.data.error,
          },
        });
      });
  };

  signUp = () => {
    if (this.state.emailError) {
      return false;
    }
    const { privacyConsent } = this.state;
    const values = _.omit(this.state, [
      'showForm',
      'message',
      'signUpDisabled',
      'error',
      'signUpError',
      'recentlyUpdated',
      'privacyConsent'
    ]);
    const mergedValues = { ...values, source: 'therapy', consent_yn: privacyConsent, referral_source: "indigo" };
    axios
      .post(`${SERVER_URL}/api/signup`, mergedValues)
      .then(response => {
        const token = response.data.access_token;
        redirectToTherapy(token);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log(e);
      });
    return true;
  };

  normalizeDate = value => {
    if (!value) {
      return value;
    }
    const onlyNums = value.replace(/[^\d]/g, '');
    if (onlyNums.length <= 2) {
      return onlyNums;
    }
    if (onlyNums.length <= 4) {
      return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2)}`;
    }
    return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}/${onlyNums.slice(
      4,
      8,
    )}`;
  };

  validateForm = recentlyUpdated => {
    const requiredField = 'This is a required field';
    const emailValid =
      'Please enter an email address - Example, user@example.com';
    const passwordSix = 'Password must be at least 8 characters';
    const passwordMatch = 'Passwords must match';

    const {
      first_name,
      last_name,
      dob,
      email,
      privacyConsent,
      password,
      password_confirm,
    } = this.state;
    const error = {};

    if (first_name === '') {
      error.first_name = requiredField;
    }
    if (!privacyConsent) {
      error.privacyConsent = requiredField;
    }
    if (last_name === '') {
      error.last_name = requiredField;
    }
    if (dob === '') {
      error.dob = requiredField;
    }
    if (dob && validateBirthday(dob)) {
      error.dob = validateBirthday(dob);
    }
    if (email) {
      if (validEmail.test(email)) {
        // to reduce memory usage, we will call check_email api only when email field updated
        if (recentlyUpdated === 'email') {
          this.emailExists(email);
        }
      } else {
        error.email = emailValid;
      }
    }
    if (password) {
      if (password.length < 8) {
        error.password = passwordSix;
      } else if (password !== password_confirm) {
        error.password_confirm = passwordMatch;
      }
    }

    this.setState({
      recentlyUpdated: null,
      signUpDisabled:
        !_.isEmpty(error) ||
        !first_name ||
        !last_name ||
        !dob ||
        !email ||
        !privacyConsent ||
        !password ||
        !password_confirm,
      error,
    });
  };

  renderConsent = () => {
    const { error } = this.state;
    return (
      <Consent error={error} handleChangeCheckbox={this.handleChangeCheckbox} />
    );
  };

  renderInputs = inputs => {
    const { error, visiblePassword } = this.state;
    return (
      _.map(inputs, ({
        id, name, type, fullWidth, text, defaultText,
      }) => (
        <div key={id}>
          <h4>
            {this.props.intl.formatMessage({
              id: text,
              defaultMessage: defaultText,
            })}
          </h4>
          {type === 'password' ? (
            <div className="password-input">
              <div className="text-input">
                <input
                  id={name}
                  className={fullWidth ? 'full-width' : ''}
                  onChange={event => this.handleChange(event)}
                  type={visiblePassword[name] ? 'text' : 'password'}
                  name={name}
                  placeholder={defaultText}
                  value={this.state[name]}
                />
              </div>
              <EyeIcon isVisible={visiblePassword[name]} onClick={() => {
                const visible = { ...visiblePassword };
                visible[name] = !visiblePassword[name];
                this.setState({ visiblePassword: { ...visible } });
              }}
              />
            </div>
        ) : (
          <input
            id={name}
            className={fullWidth ? 'full-width' : ''}
            onChange={event => this.handleChange(event)}
            type={type}
            name={name}
            placeholder={defaultText}
            value={this.state[name]}
          />
        )}
        <p
          className={`error-text ${error[name] ? '' : 'hidden'}`}
          style={{ fontSize: '11px', position: 'absolute' }}
        >
          {error[name]}
        </p>
      </div>
    ))
    );
  };

  render() {
    return (
      <div className="custom-auth custom-dependants" role="main">
        <div className="dependants-container custom-auth-container">
          <div className="dependant-auth-form">
            <h2>
              {this.props.intl.formatMessage({
                id: 'eap.signup',
                defaultMessage: 'Sign up',
              })}
            </h2>
            <form onSubmit={this.handleSubmit}>
              {this.renderInputs(indigoSignupData)}
              {this.renderConsent()}
              <div>
                <button type="submit">
                  {this.props.intl.formatMessage({
                    id: 'eap.signup',
                    defaultMessage: 'Sign Up',
                  })}
                </button>
              </div>
            </form>
            <span className="accessibility-h4">
              {this.props.intl.formatMessage({
                id: 'eap.alreadyHave',
                defaultMessage: 'Already have an account?',
              })}
              <Link to="/sign_in?company=indigo" style={{ color: '#2a5ccd' }}>
                {this.props.intl.formatMessage({
                  id: 'eap.signin',
                  defaultMessage: 'Sign In',
                })}
              </Link>
            </span>
          </div>
        </div>
        <LogEventOnMount
          eventType={OPEN_SIGNUP}
          eventProperties={{ page: parseCompanyCodeFromPath() }}
        />
      </div>
    );
  }
}

IndigoSignup.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(IndigoSignup);

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';

import Consent from '../auth/consent';
import { SERVER_URL } from '../../utils/environment';
import { getLocale } from '../../utils/locale';
import { redirectToTherapy } from '../../utils/redirect';
import {
  COMPLETED_SIGNUP,
  OPEN_SIGNUP,
  logEvent,
  setUserId,
} from '../../utils/amplitude';
import TextInput from '../TextInput';
import LogEventOnMount from '../LogEventOnMount';
import formValidation from './formValidation';
import disableSubmit from './disableSubmit';
import FormButton from './FormButton';
import SelectDropdown from '../SelectDropdown';

const dependantOptions = [
  { value: 'spouse', label: 'eap.spouse' },
  { value: 'dependant', label: 'eap.dependant' },
];

function NonGatedSignUp({
  clearError,
  companyId,
  companyName,
  dependant,
  goBack,
  path,
  setError,
  setHideImage,
}) {
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);

  useEffect(() => {
    setHideImage(true);

    return () => setHideImage(false);
  });

  function handleChange(event) {
    const { name, value } = event.target;
    const newValues = {
      ...formValues,
      [name]: value,
    };
    const errors = formValidation(newValues);

    setFormErrors(errors);
    setFormValues(newValues);
    setSubmitDisabled(disableSubmit(newValues, errors, dependant));
  }

  function handleChangeCheckbox(event) {
    const { checked } = event.target;
    const newValues = {
      ...formValues,
      privacyConsent: checked,
    };
    const errors = formValidation(newValues);
    setFormErrors(errors);
    setFormValues(newValues);
    setSubmitDisabled(disableSubmit(newValues, errors, dependant));
  }

  function handleSubmit(event) {
    const requestUrl = `${SERVER_URL}/api/v2/b2b_auth`;
    const params = {
      company_id: companyId,
      dependant,
      locale: getLocale(),
      consent_yn: formValues.privacyConsent,
      ...formValues,
    };

    event.preventDefault();
    clearError();
    setLoading(true);

    axios
      .post(requestUrl, params)
      .then(response => {
        const accessToken = response.data.access_token;
        const userId = response.data.user_id;

        setLoading(false);
        redirectToTherapy(accessToken);

        setUserId(userId);
        logEvent(COMPLETED_SIGNUP, {
          company: companyName,
          page: path,
          page_type: 'reseller portal - gsc, honeybee',
          dependant,
          flow_type: 'b2b registration',
        });
      })
      .catch(error => {
        setLoading(false);
        setError(error);
      });
  }

  return (
    <form onSubmit={handleSubmit}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="non-gated-row">
          <TextInput
            errorText={formErrors.first_name}
            handleChange={handleChange}
            label={<FormattedMessage id="eap.firstName" />}
            name="first_name"
          />
          <TextInput
            errorText={formErrors.last_name}
            handleChange={handleChange}
            label={<FormattedMessage id="eap.lastName" />}
            name="last_name"
          />
        </div>
        <div className="non-gated-row full-row">
          <TextInput
            errorText={formErrors.email}
            handleChange={handleChange}
            label={<FormattedMessage id="eap.email" />}
            name="email"
          />
        </div>
        <div className="non-gated-row">
          <TextInput
            errorText={formErrors.password}
            handleChange={handleChange}
            label={<FormattedMessage id="eap.password" />}
            name="password"
            type="password"
          />
          <TextInput
            errorText={formErrors.password_confirm}
            handleChange={handleChange}
            label={<FormattedMessage id="eap.confirmPassword" />}
            name="password_confirm"
            type="password"
          />
        </div>
        {dependant && (
          <div>
            <div className="non-gated-row full-row">
              <SelectDropdown
                errorText={formErrors.relationship}
                handleChange={handleChange}
                label={<FormattedMessage id="eap.relationship" />}
                name="relationship"
                options={dependantOptions}
              />
            </div>
          </div>
        )}
        <Consent error={formErrors} handleChangeCheckbox={handleChangeCheckbox} />
      </div>

      <FormButton disabled={submitDisabled} loading={loading} goBack={goBack} />
      <LogEventOnMount
        eventType={OPEN_SIGNUP}
        eventProperties={{
          company: companyName,
          page: path,
          dependant: dependant || '',
          page_type: 'reseller portal - gsc, honeybee',
          flow_type: 'b2b registration',
        }}
      />
    </form>
  );
}

NonGatedSignUp.propTypes = {
  clearError: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  companyName: PropTypes.string.isRequired,
  dependant: PropTypes.bool,
  goBack: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  setError: PropTypes.func.isRequired,
  setHideImage: PropTypes.func.isRequired,
};

NonGatedSignUp.defaultProps = {
  dependant: false,
};

export default NonGatedSignUp;

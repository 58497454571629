import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import TelusHealthLogo from '../../images/custom/logos/telus-health-logo.png';
import TelusSanteLogo from '../../images/custom/logos/telus-sante-logo.png';

const Section4 = ({locale}) => (
  <div className="section section-4">
    <div className="text">
      <h3>EFAP</h3>
      <hr style={{ width: '276px', marginBottom: '20px', backgroundColor: '#ffffff' }} />
      <FormattedHTMLMessage tagName='h2' id='mlf.header' defaultMessage='Telus Health'/>
      <FormattedHTMLMessage
        tagName="p"
        id="mlf.yourEmployee"
        defaultMessage="Your Employee and Family Assistance Program (EFAP) provides you
        with immediate and confidential help for any work, health or life
        concern. We’re available anytime and anywhere. Let us help: 1.844.880.9142"
      />
      <br />
      <FormattedHTMLMessage
        tagName="span"
        id="mlf.visitWebsite"
        defaultMessage="<a href='http://one.telushealth.com'>VISIT WEBSITE</a>"
      />
    </div>
    <div className="image">
      {
        locale === 'fr' ?
          <img src={TelusSanteLogo} alt="Telus Santé logo" /> :
          <img src={TelusHealthLogo} alt="Telus Health logo" />
      }
    </div>
  </div>
);

export default Section4;

import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import mbcPlatformDemoImage from '../../images/mbcToolkitENPlatformDemo.png';
import mbcPlatformDemoImageFR from '../../images/mbcToolkitFRPlatformDemo.png';
import { getLocale } from '../../utils/locale';


const MBCToolkitVideoShelf = () => {
  const [isOpen, setIsOpen] = useState(false);
  const locale = getLocale();

  return (
    <div className='shelf-container'>
      <h1>
        <FormattedMessage 
          defaultMessage={"Watch a recording of a previous session"}
          id="eap.watchARecording"
        />
      </h1>
      <button 
        className='button-link'
        onClick={() => setIsOpen(!isOpen)} 
      >
        {isOpen ? 
          <FormattedMessage id="eap.hideSessions" defaultMessage={'hide previous sessions'}/> : 
          <FormattedMessage id="eap.showSessions" defaultMessage={'show previous sessions'}/>
        }
      </button>
      {isOpen &&
        <div className='shelf'>
          <div className='shelf-item'>
            <a 
              href={
                locale === "en" ?
                  "https://inkblottherapy.zoom.us/rec/share/cnjH6y6hYfSMx-xBXBN8ZYjLF_yo1--4OtREbwSZDXBEiiYl25HpT-F7VQrGZ1E.6nxXG9Za-CJprgoi?startTime=1696516294000" :
                  "https://inkblottherapy.zoom.us/rec/share/V_pe76rKVC11oEIEtZ36oJkHXHSobvykXGUCkTZs1irKtTyyLyYIabw5uS7HnwDm.fywAanVyWGU1WZmz?startTime=1695321173000"
              }
            >
              <img
                src={ locale === "en" ? mbcPlatformDemoImage : mbcPlatformDemoImageFR }
                alt='screenshot of a video of an older recording'
              />
            </a>
            <h2>
              <FormattedMessage 
                defaultMessage={"Employee Orientation Session"}
                id="eap.employeeOrientationSession"
              />
            </h2>
          </div>
          <div className='shelf-item'>
            <a 
              href={
                locale === "en" ?
                  "https://inkblottherapy.zoom.us/rec/share/5FQ4PVWaOBLv0runkyRNDiMiDytD3Z7hEafgYasqnz9ERSFUwQzweJM50xMLSC1S.cXYfv8BvGi6qaWbb" :
                  "https://inkblottherapy.zoom.us/rec/play/X1lJELhkH0sfORz1l8MSDEKr5DXef120MmVpUFgD_qeyY46ZRvGJWr1QaDtmNkcAJrnHwx5T5aEuyP7A.iHj_Jigg-0ezc850?canPlayFromShare=true&from=my_recording&startTime=1704740566000&componentName=rec-play&originRequestUrl=https%3A%2F%2Finkblottherapy.zoom.us%2Frec%2Fshare%2F37Vh5CpEvIeJTK70MBDNGOxQfoLCU_5_r1f673Li5gi-OQPD9dqwuF8AkvhuNa98.yx8cDRfp2zIKKYj7%3FstartTime%3D1704740566000"
              }
            >
              <img
                src={ locale === "en" ? mbcPlatformDemoImage : mbcPlatformDemoImageFR }
                alt='screenshot of a video of an older recording'
              />
            </a>
            <h2>
              <FormattedMessage 
                defaultMessage={"People Leader Orientation Session"}
                id="eap.peopleLeaderOrientation"
              />
            </h2>
          </div>
          <div className='shelf-item'>
            <a  
              href={
                locale === "en" ?
                  "https://www.loom.com/share/45427469fe6e4f51a0f408b9de0b6a1f?sid=18736519-0e19-4325-942e-6897f636a0ce" :
                  "https://www.loom.com/share/b34b8c611e1742f2a5fe35ef0ba06fe9?sid=37655a56-fdd3-434e-a9d3-980f7f0644b4"
              }
            >
              <img
                src={ locale === "en" ? mbcPlatformDemoImage : mbcPlatformDemoImageFR }
                alt='screenshot of a video of an older recording'
              />
            </a>
            <h2>
              <FormattedMessage 
                defaultMessage={"Platform Demonstration"}
                id="eap.platformDemonstration"
              />
            </h2>
          </div>
        </div>
      }
    </div>
  )
}

export default MBCToolkitVideoShelf
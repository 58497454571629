/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { scroller } from 'react-scroll';
import MetaTags from 'react-meta-tags';
import { whichCorp } from '../../utils/pageUtils';
import ProviderSection from './ProviderSection';

const Index = () => {
  const setLanguage = lang => {
    localStorage.setItem('selectedLanguage', lang);
    window.location.reload();
  };

  const scrollToElement = element => {
    scroller.scrollTo(element, {
      duration: 1500,
      smooth: 'easeInOutQuart',
    });
  };
  const company = whichCorp();
  const selectedLang = localStorage.getItem('selectedLanguage');

  useEffect(() => {
    if (
      company.frenchOnly &&
      localStorage.getItem('selectedLanguage') !== 'fr'
    ) {
      localStorage.setItem('selectedLanguage', 'fr');
      window.location.reload();
    }
  }, []);

  return (
    <div className="corporate-nav-page">
      <MetaTags>
        <meta name="robots" content="noindex" />
      </MetaTags>
      <div className="section-1">
        <div className="en-fr">
          {company.french && !company.frenchOnly && (
            <>
              {selectedLang === 'fr' ? (
                <div>
                  <span
                    role="presentation"
                    onClick={() => setLanguage('en')}
                    style={{ fontWeight: 300 }}
                  >
                    EN
                  </span>
                  <span>FR</span>
                </div>
              ) : (
                <div>
                  <span>EN</span>
                  <span
                    role="presentation"
                    onClick={() => setLanguage('fr')}
                    style={{ fontWeight: 300 }}
                  >
                    FR
                  </span>
                </div>
              )}
            </>
          )}
        </div>
        <div className="logos">
          {
            company.secondLogo &&
              <img src={company.secondLogo}/>
          }
          <img
            src={
              company.french && selectedLang === 'fr' && company.frLogo
                ? company.frLogo
                : company.logo
            }
            alt={`${company.title} logo`}
          />
        </div>
        <FormattedHTMLMessage
          tagName="h1"
          id="corporateNavPage.title"
          defaultMessage={
            company.french && selectedLang === 'fr' && company.frTitle
              ? company.frTitle
              : company.title
          }
        />
        <FormattedHTMLMessage
          tagName="h2"
          id="corporateNavPage.subTitle"
          defaultMessage={
            company.french && selectedLang === 'fr' && company.frSubTitle
              ? company.frSubTitle
              : company.subTitle
          }
        />

        <div className="divider" />
        {!company.excludeYourProvider &&
          <FormattedHTMLMessage
            tagName="h4"
            id="corporateNavPage.subTitle"
            defaultMessage={
              company.french && selectedLang === 'fr'
                ? 'Vos prestataires:'
                : 'Your providers:'
            }
          />
        }
        { !company.noProvider &&
          <div className="corporate-nav-page-company-container">
            {company.providers.map(provider => (
              <div
                style={{
                  maxWidth: provider.maxWidth,
                  width: provider.width,
                  minWidth: provider.minWidth,
                }}
              >
                <div
                  onClick={
                    provider.path
                      ? () => scrollToElement(`${provider.path}`)
                      : undefined
                  }
                  role="button"
                  tabIndex={0}
                  onKeyPress={() => scrollToElement(`${provider.path}`)}
                  disabled={!provider.path}
                >
                  <img
                    src={
                      company.french && selectedLang === 'fr' && provider.frLogo
                        ? provider.frLogo
                        : provider.logo
                    }
                    alt={`${provider.title} logo`}
                  />
                </div>
                {(provider.frSecondLogo || provider.secondLogo) && (
                  <div
                    onClick={
                      provider.path
                        ? () => scrollToElement(`${provider.path}`)
                        : undefined
                    }
                    role="button"
                    tabIndex={0}
                    onKeyPress={() => scrollToElement(`${provider.path}`)}
                    style={{ marginTop: '0.4rem' }}
                    disabled={!provider.path}
                  >
                    <img
                      src={
                        company.french &&
                        selectedLang === 'fr' &&
                        provider.frSecondLogo
                          ? provider.frSecondLogo
                          : provider.secondLogo
                      }
                      alt={`${provider.title}-second-logo`}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        }
      </div>

      {company.provider_sections.map((provider, key) => {
        return (
          <>
            <ProviderSection
              french={company.french}
              provider={provider}
              sectionNo={(key % 4) + 2}
              key={key}
              selectedLang={selectedLang}
              isMedavie={company.path === 'mbctoolkit'}
            ></ProviderSection>
          </>
        );
      })}
    </div>
  );
};

export default Index;

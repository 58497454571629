/* eslint-disable arrow-body-style */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import step4 from '../../../images/referral/step-4.png';
import french4 from '../../../images/custom/screens/group2.png';

const featureFour = {
  id: 'ilob.bookFirst',
  title: 'Book your first session',
  descriptionId: 'ilob.whenYouve',
  description:
    'When you’ve found your Provider, you’ll be able to book your initial session.',
  image: step4,
  frImage: french4,
};

const Section8 = props => {
  const { id, title, description, descriptionId, image, frImage } = featureFour;
  return (
    <div className="section-5">
      <div className="v2-s4-text">
        <div className="text">
          <span>{props.intl.formatMessage({ id, defaultMessage: title })}</span>
        </div>
        <div className="subheader">
          {props.intl.formatMessage({
            id: descriptionId,
            defaultMessage: description,
          })}
        </div>
      </div>
      <div className="v2-s4-image">
        <div
          alt=""
          className="bg-url-image"
          style={{
            backgroundImage: `url(${
              localStorage.getItem('selectedLanguage') !== 'fr'
                ? image
                : frImage
            })`,
          }}
        />
        <img
          className="url-image"
          src={
            localStorage.getItem('selectedLanguage') !== 'fr' ? image : frImage
          }
          alt={title}
        />
      </div>
    </div>
  );
};

Section8.propTypes = {
  intl: PropTypes.object,
};

Section8.defaultProps = {
  intl: {},
};

export default injectIntl(Section8);

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import ErrorText from '../ErrorText';

function SelectDropdown({
  errorText,
  handleChange,
  intl,
  label,
  name,
  options,
}) {
  return (
    <div className="select-dropdown">
      <h4 className="label">{label}</h4>
      <select onChange={handleChange} type="text" name={name} defaultValue="">
        <option value="">
          {intl.formatMessage({ id: 'eap.pleaseSelect' })}
        </option>
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {intl.formatMessage({ id: option.label })}
          </option>
        ))}
      </select>
      <ErrorText>{errorText}</ErrorText>
    </div>
  );
}

SelectDropdown.propTypes = {
  errorText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  handleChange: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
};

SelectDropdown.defaultProps = {
  errorText: '',
  label: '',
  options: [],
};

export default injectIntl(SelectDropdown);

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Link } from 'gatsby';
import { whichCompany, whichPathGetStarted } from '../../utils/pageUtils';
import SectionContainer from '../../components/SectionContainer';
import teamworkWhite12 from '../../images/custom/illustrations/teamwork-white-12.svg';
import crisisLine from '../../images/custom/illustrations/crisis-line.svg';

const renderRedText = str => <span style={{ color: 'red' }}>{str}</span>;
const SectionOne = ({ EFAP, intl, EAP, hideSignUp }) => {
  const { path } = whichCompany();
  const getInconfidenceOrgNames = () => {
    if (path === 'inconfidence' || path === 'medaviebsb')
      return intl.formatMessage({ id: 'eap.medavie' });
    if (path === 'inconfidencepersonal')
      return intl.formatMessage({ id: 'eap.bluecross' });
  };
  function renderHeader() {
    let messageId;
    let defaultMessage;

    switch (path) {
      case 'arta':
        return (
          <FormattedHTMLMessage
            id="eap.artaHeader"
            defaultMessage="Conveniently access counselling services directly embedded within your MyARTA account, in the new MyARTA Health Hub.<br/><br/>If you are a spouse or dependant of an ARTA member, you can <a href='https://healthhub.myarta.net/signin'>create your own ARTA Virtual Care account.</a>"
          />
        );
      case 'endy':
        messageId = 'eap.takeCareEnhanced';
        defaultMessage =
          'Take care of it with Inkblot’s confidential enhanced well-being program.';
        break;
      case 'feminuity':
        messageId = 'eap.takeCareTMAP';
        defaultMessage =
          'Take care of it with your confidential Team Member Assistance Program.';
        break;
      case 'eiqc':
        messageId = 'eap.takeCareEIQC';
        defaultMessage =
          "Take care of it with your confidential Student Assistance Program (SAP) with the International students' plan - Centre d’acquisitions Gouvernementales (CAG).";
        break;
      case 'inconfidence':
      case 'inconfidencepersonal':
      case 'medaviebsb':
        return (
          <div>
            <div>
              <FormattedMessage
                id="eap.takeCareInConfidence"
                defaultMessage="Your “{OrganizationCode}“ is part of the {OrganizationName} policy number on your Member card or mobile app. Enter the 5 digits starting with the 3rd. (e.g. 00{b}000)."
                values={{
                  b: renderRedText(12345),
                  OrganizationCode: renderRedText(
                    intl.formatMessage({ id: 'eap.organizationCode' }),
                  ),
                  OrganizationName: getInconfidenceOrgNames(),
                }}
              />
            </div>
            <br />
            <div>
              <FormattedMessage
                id="eap.toStart"
                defaultMessage="To create your account, click Get Started."
                values={{ b: renderRedText(12345) }}
              />
            </div>
          </div>
        );
      case 'eastcoaststrong':
        messageId = 'eap.weStandBy';
        defaultMessage = 'We stand by you in this extremely difficult time.';
        break;
      case 'roomforher':
        messageId = 'eap.takeCareRFH';
        defaultMessage =
          'Start your journey to feeling better with Inkblot’s confidential video counselling.';
        break;
      default:
        if (EAP) {
          messageId = 'eap.takeCareEap';
          defaultMessage =
            'Take care of it with your confidential Employee Assistance Program (EAP).';
          const employeeEN = ['arta', 'pas', 'cima'].includes(path)
            ? 'Member'
            : 'Employee';
          const eapEN = ['arta', 'pas', 'cima'].includes(path) ? 'MAP' : 'EAP';
          const employeeFR = ['arta', 'pas', 'cima'].includes(path)
            ? 'Membres'
            : 'Employés';
          const eapFR = ['arta', 'pas', 'cima'].includes(path) ? 'PAM' : 'PAE';
          return intl.formatMessage(
            {
              id: messageId,
              defaultMessage,
            },
            {
              employeeEN,
              eapEN,
              employeeFR,
              eapFR,
            },
          );
        }
        if (EFAP) {
          if (path === 'asebp') {
            messageId = 'eap.yourTakeCare';
          } else {
            messageId = 'eap.takeCare';
            defaultMessage =
              'Take care of it with your confidential Employee and Family Assistance Program (EFAP).';
          }
        } else {
          messageId = 'eap.takeCareWith';
          defaultMessage =
            'Take care of it with Inkblot’s confidential video counselling.';
        }
    }

    return intl.formatMessage({
      id: messageId,
      defaultMessage,
    });
  }

  const renderCustomHeader = () => {
    let messageId;
    let defaultMessage;

    switch (path) {
      case 'arta':
        messageId = 'eap.artaCustomHeader';
        defaultMessage = 'Introducing the MyARTA Health Hub';
        break;
      case 'eastcoaststrong':
        messageId = 'eap.sincereCondolences';
        defaultMessage =
          'Our sincere condolences to all of those affected by the recent tragedy in Nova Scotia.';
        break;
      case 'roomforher':
        messageId = 'eap.itsTime';
        defaultMessage =
          'It’s time to make room for women who need accessible mental health solutions.';
        break;
      case 'inconfidencepersonal':
        messageId = 'eap.yourWellBeingInConfidence';
        defaultMessage = 'inConfidence powered by Inkblot';
        break;
      default:
        messageId = 'eap.yourMentalHealth';
        defaultMessage =
          'Your mental health is as important as your physical health.';
    }

    return intl.formatMessage({
      id: messageId,
      defaultMessage,
    });
  };

  return (
    <SectionContainer container num={1}>
      <div className="row">
        <div className="col-6">
          <div className="header">
            <p className="custom-header">
              <span className="dark-blue">{renderCustomHeader()}</span>
            </p>
            <p className="custom-header">{renderHeader()}</p>
          </div>
        </div>
        <div
          className="col-6 custom-svg"
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <img src={teamworkWhite12} alt="" />
          {(EFAP || EAP) && (
            <div className="crisis-line">
              <img src={crisisLine} alt="Orange alert" />
              <FormattedHTMLMessage
                tagName="span"
                defaultMessage="If you are in crisis, contact: <b>1-855-933-0103</b> (toll-free, 24/7/365)"
                id="eap.crisisText"
              />
            </div>
          )}
        </div>
      </div>
      {!hideSignUp && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '3rem',
            marginBottom: '3rem',
          }}
        >
          <Link to={whichPathGetStarted()}>
            <div
              className="custom-button-blue start-btn"
              style={{ margin: 'auto', cursor: 'pointer' }}
            >
              {intl.formatMessage({
                id: 'navigation.createMyAccount',
                defaultMessage: 'Get Started',
              })}
            </div>
          </Link>
        </div>
      )}
    </SectionContainer>
  );
};

SectionOne.propTypes = {
  EFAP: PropTypes.bool,
  intl: PropTypes.object,
  EAP: PropTypes.bool,
  hideSignUp: PropTypes.bool,
};

SectionOne.defaultProps = {
  intl: {},
  EFAP: false,
  EAP: false,
};

export default injectIntl(SectionOne);

/* eslint-disable arrow-body-style */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import step1 from '../../../images/referral/step-1.svg';
import french1 from '../../../images/custom/screens/Group8.png';

const featureOne = {
  id: 'eap.shortQuestionnaire',
  title: 'Short Questionnaire',
  description:
    'Fill out our matching survey to help you connect to a provider best suited to your needs. You can match based on your symptoms, stressors, and even language and religion.',
  descriptionId: 'eap.fillOut',
  image: step1,
  frImage: french1,
};

const Section5 = props => {
  const { id, title, description, descriptionId, image, frImage } = featureOne;
  return (
    <div className="section-5">
      <div className="v2-s4-text">
        <div className="text">
          <div className="step-title font-barlow-34">
            <span>
              {props.intl.formatMessage({ id, defaultMessage: title })}
            </span>
          </div>
          <div className="step-description font-open-sans-20">
            {props.intl.formatMessage({
              id: descriptionId,
              defaultMessage: description,
            })}
          </div>
        </div>
      </div>
      <div className="v2-s4-image">
        <div
          alt=""
          className="bg-url-image"
          style={{
            backgroundImage: `url(${
              localStorage.getItem('selectedLanguage') !== 'fr'
                ? image
                : frImage
            })`,
          }}
        />
        <img
          alt=""
          className="url-image"
          src={
            localStorage.getItem('selectedLanguage') !== 'fr' ? image : frImage
          }
        />
      </div>
    </div>
  );
};

Section5.propTypes = {
  intl: PropTypes.object,
};

Section5.defaultProps = {
  intl: {},
};

export default injectIntl(Section5);

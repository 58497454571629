import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { injectIntl } from 'react-intl';
import { Link } from 'gatsby';
import _ from 'lodash';

import { validEmail } from 'src/utils/form';
import Consent from '../auth/consent';
import { SERVER_URL } from '../../utils/environment';
import { redirectToTherapy } from '../../utils/redirect';
import {
  unauthAuditProgress,
  auditProgress,
  parseCompanyCodeFromPath,
  whichCompany,
} from '../../utils/pageUtils';
import LogEventOnMount from '../LogEventOnMount';
import { OPEN_SIGNUP, logEvent, COMPLETED_SIGNUP } from '../../utils/amplitude';
import { minSignupData } from '../../pagePartials/auth';
import EyeIcon from '../EyeIcon';

class TrustBasedNoEfSignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signUpDisabled: false,
      error: {},
      visiblePassword: {},
    };
  }

  componentDidMount() {
    if(whichCompany().path === 'roomforher') window.location.href = 'https://www.greenshield.ca/en-ca/cares/womens-mental-health'
    unauthAuditProgress(parseCompanyCodeFromPath(), 'open_signup');
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.recentlyUpdated !== prevState.recentlyUpdated) {
      this.validateForm(this.state.recentlyUpdated);
    }
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      recentlyUpdated: name,
    });
  };

  handleChangeCheckbox = event => {
    this.setState({
      privacyConsent: event.target.checked,
      recentlyUpdated: event.target.name,
    });
  };

  handleSubmit = event => {
    const { signUpDisabled } = this.state;
    event.preventDefault();

    if (!signUpDisabled) this.signUp();
  };

  emailExists = email => {
    axios
      .get(
        `${SERVER_URL}/api/signup/check_email?email=${encodeURIComponent(
          email,
        )}`,
      )
      .then(() => { })
      .catch(e => {
        this.setState({
          error: {
            // eslint-disable-next-line react/no-access-state-in-setstate
            ...this.state.error,
            email: e.response.data.error,
          },
        });
      });
  };

  signUp = () => {
    if (this.state.emailError) {
      return false;
    }
    const corporate_account = new URL(window.location.href).searchParams.get('company');
    const values = _.omit(this.state, [
      'showForm',
      'message',
      'signUpDisabled',
      'error',
      'signUpError',
      'recentlyUpdated',
      'privacyConsent'
    ]);
    const mergedValues = { ...values, source: 'therapy', consent_yn: this.state.privacyConsent, corporate_account };
    axios
      .post(`${SERVER_URL}/api/signup`, mergedValues)
      .then(response => {
        const token = response.data.access_token;
        logEvent(COMPLETED_SIGNUP, {
          page: parseCompanyCodeFromPath(),
          flow_type: 'b2b registration',
          b2b_page_type: 'custom portal',
          gating_type: 'trust based no ef',
          company: parseCompanyCodeFromPath(),
        });
        auditProgress(parseCompanyCodeFromPath(), 'completed_signup', token);
        redirectToTherapy(token);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log(e);
      });
    return true;
  };

  validateForm = recentlyUpdated => {
    const isFr = localStorage.getItem('selectedLanguage') === 'fr';
    const requiredField = isFr
      ? 'Ceci est un champ obligatoire'
      : 'This is a required field';
    const emailValid = isFr
      ? 'Veuillez saisir une adresse courriel - Exemple, utilisateur@exemple.com'
      : 'Please enter an email address - Example, user@example.com';
    const passwordSix = isFr
      ? 'Le mot de passe doit être au moins de 8 caractères '
      : 'Password must be at least 8 characters';
    const passwordMatch = isFr
      ? 'Les mots de passe doivent correspondre'
      : 'Passwords must match';

    const {
      first_name,
      last_name,
      email,
      privacyConsent,
      password,
      password_confirm,
    } = this.state;
    const error = {};

    if (first_name === '') {
      error.first_name = requiredField;
    }
    if (last_name === '') {
      error.last_name = requiredField;
    }
    if (!privacyConsent) {
      error.privacyConsent = requiredField;
    }
    if (email) {
      if (validEmail.test(email)) {
        // to reduce memory usage, we will call check_email api only when email field updated
        if (recentlyUpdated === 'email') {
          this.emailExists(email);
        }
      } else {
        error.email = emailValid;
      }
    }
    if (password) {
      if (password.length < 8) {
        error.password = passwordSix;
      } else if (password !== password_confirm) {
        error.password_confirm = passwordMatch;
      }
    }

    this.setState({
      recentlyUpdated: null,
      signUpDisabled:
        !_.isEmpty(error) ||
        !first_name ||
        !last_name ||
        !email ||
        !privacyConsent ||
        !password ||
        !password_confirm,
      error,
    });
  };

  renderConsent = () => {
    const { error } = this.state;
    return (
      <Consent error={error} handleChangeCheckbox={this.handleChangeCheckbox} />
    );
  };

  renderInputs = inputs => {
    const { error, visiblePassword } = this.state;
    return _.map(inputs, ({ id, name, type, fullWidth, text, defaultText }) => (
      <div key={id}>
        <h4>
          {this.props.intl.formatMessage({
            id: text,
            defaultMessage: defaultText,
          })}
        </h4>
        {type === 'password' ? (
          <div className="password-input">
            <div className="text-input">
              <input
                id={name}
                className={fullWidth ? 'full-width' : ''}
                onChange={event => this.handleChange(event)}
                type={visiblePassword[name] ? 'text' : 'password'}
                name={name}
                placeholder={this.props.intl.formatMessage({
                  id: text,
                  defaultMessage: defaultText,
                })}
                value={this.state[name]}
              />
            </div>
            <EyeIcon isVisible={visiblePassword[name]} onClick={() => {
              const visible = { ...visiblePassword };
              visible[name] = !visiblePassword[name];
              this.setState({ visiblePassword: { ...visible } });
            }}
            />
          </div>
        ) : (
          <input
            id={name}
            className={fullWidth ? 'full-width' : ''}
            onChange={event => this.handleChange(event)}
            type={type}
            name={name}
            placeholder={
              type === 'email'
                ? 'example@example.com'
                : this.props.intl.formatMessage({
                  id: text,
                  defaultMessage: defaultText,
                })
            }
            value={this.state[name]}
          />
        )}
        <p
          className={`error-text ${error[name] ? '' : 'hidden'}`}
          style={{ fontSize: '11px', position: 'absolute' }}
        >
          {error[name]}
        </p>
      </div>
    ));
  };

  render() {
    const { dependants, path } = whichCompany();
    return (
      <div className="custom-auth custom-dependants" role="main">
        <div
          className="dependants-container custom-auth-container"
          style={{ margin: '50px auto' }}
        >
          <div className="dependant-auth-form">
            <h2>
              {this.props.intl.formatMessage({
                id: 'eap.signup',
                defaultMessage: 'Sign up',
              })}
            </h2>
            <form onSubmit={this.handleSubmit}>
              {this.renderInputs(minSignupData)}
              {this.renderConsent()}
              <div>
                <button type="submit">
                  {this.props.intl.formatMessage({
                    id: 'eap.signup',
                    defaultMessage: 'Sign Up',
                  })}
                </button>
              </div>
            </form>
            <span className="accessibility-h4">
              {this.props.intl.formatMessage({
                id: 'eap.alreadyHave',
                defaultMessage: 'Already have an account?',
              })}
              <Link
                to={`/sign_in?company=${path}`}
                style={{ color: '#2a5ccd' }}
              >
                {this.props.intl.formatMessage({
                  id: 'eap.signin',
                  defaultMessage: 'Sign In',
                })}
              </Link>
            </span>
            {dependants && (
              <h4>
                {this.props.intl.formatMessage({
                  id: 'eap.spouseOrDependant',
                  defaultMessage: 'Are you a spouse or a dependant?',
                })}
                {' '}
                <Link to={`/dependants?company=${path}`} style={{ color: '#2a5ccd' }}>
                  {this.props.intl.formatMessage({
                    id: 'eap.registerHere',
                    defaultMessage: 'Sign Up Here',
                  })}
                </Link>
              </h4>
            )}
          </div>
        </div>
        <LogEventOnMount
          eventType={OPEN_SIGNUP}
          eventProperties={{
            page: parseCompanyCodeFromPath(),
            flow_type: 'b2b registration',
            b2b_page_type: 'custom portal',
            gating_type: 'trust based no ef',
            company: parseCompanyCodeFromPath(),
          }}
        />
      </div>
    );
  }
}

TrustBasedNoEfSignup.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(TrustBasedNoEfSignup);

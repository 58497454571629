import React, { Component } from 'react';
import PropTypes from 'prop-types';

class FaqDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleClick = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { question, answer } = this.props;
    const { open } = this.state;

    return (
      <div className="faq">
        <p className="question" role="presentation" onClick={this.handleClick}>
          {question}
        </p>
        {open ? <div className="answer">{answer}</div> : null}
      </div>
    );
  }
}

FaqDropdown.propTypes = {
  answer: PropTypes.element.isRequired,
  question: PropTypes.string.isRequired,
};

export default FaqDropdown;

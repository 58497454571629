import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import ReactModal from 'react-modal';
import logo from '../../images/custom/logos/lifespeak-logo.jpg';
import CloseIcon from '../../images/business/events/mixer/bios/close.svg';
import step2 from '../../images/custom/tucows/step-2.png';
import step3 from '../../images/custom/tucows/step-3.png';

class SectionTwocows extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  handleOpenModal = () => this.setState({
    showModal: true,
  });

  handleCloseModal = () => this.setState({
    showModal: false,
  });

  render() {
    const {
      title,
    } = this.props;
    return (
      <div className="section-2" style={{ boxShadow: 'none', padding: 0 }}>
        <div className="section-2-wrapper">
          <div className="logo-container">
            <div className="logo-block" style={{ margin: '0 30px' }}>
              <img src={logo} alt={`${title} logo`} id="customLogo" />
            </div>
          </div>
          <div className="custom-inkblot-collab">
            <p style={{ fontSize: '1.125rem' }}>
              Tucows offers all employees (and family members) free access to
              <button
                className="b8b-button"
                onClick={this.handleOpenModal}
                style={{ textDecoration: 'underline', fontWeight: 'bold' }}
              >
                LifeSpeak
              </button>
              – a total well-being platform, available 24/7/365, from your laptop, smartphone or
              tablet. Access is anonymous and confidential!
            </p>
          </div>
        </div>
        <div className="b8-modal">
          <ReactModal
            onRequestClose={this.handleCloseModal}
            isOpen={this.state.showModal}
            className="b8m-faq-modal"
            overlayClassName="b8m-faq-modal-overlay"
          >
            <div className="b8mfq-contents">
              <div className="b8mfqc-close">
                {/* eslint-disable-next-line */}
                <img src={CloseIcon} onClick={this.handleCloseModal} alt="close" />
              </div>
              <div className="b8mfqc-faq">
                <div className="b8mfqcf-title">
                  <h2>Accessing LifeSpeak</h2>
                </div>
                <div className="b8mfqcf-qas">
                  <div className="b8mfqcfq-qa" >
                    <div className="b8mfqcfqq-question">
                      <h3>1. Go to <a href="https://tucows.lifespeak.com/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline' }}>tucows.lifespeak.com</a></h3>
                    </div>
                    <div className="b8mfqcfqq-question">
                      <h3>2. Select <i style={{ fontStyle: 'italic', textTransform: 'uppercase' }}>Access through group account</i></h3>
                    </div>
                    <div className="b8mfqcfqq-answer">
                      <img src={step2} alt="Tucows x LifeSpeak Step 2" />
                    </div>
                    <div className="b8mfqcfqq-question">
                      <h3>3. Use <i style={{ fontStyle: 'italic' }}>lifespeak</i> as your client password</h3>
                    </div>
                    <div className="b8mfqcfqq-answer">
                      <img src={step3} alt="Tucows x LifeSpeak Step 3" />
                    </div>
                    <div className="b8mfqcfqq-question">
                      <h3>4. Explore your LifeSpeak Dashboard.</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ReactModal>
        </div>
      </div>
    );
  }
}

SectionTwocows.propTypes = {
  title: PropTypes.string.isRequired,
};

SectionTwocows.defaultProps = {};

export default injectIntl(SectionTwocows);

import React from 'react';
import PropTypes from 'prop-types';

const divStyle = {
  height: '20px',
  marginTop: '5px',
};

const textStyle = {
  color: '#cf1a1a',
  fontFamily: "'Source Sans Pro', sans-serif",
  fontSize: '12px',
  fontWeight: '600',
  marginBottom: '0px',
};

function ErrorText({ children }) {
  return (
    <div style={divStyle}>
      <p style={textStyle}>{children}</p>
    </div>
  );
}

ErrorText.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

ErrorText.defaultProps = {
  children: '',
};

export default ErrorText;

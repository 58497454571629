/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { motion } from 'framer-motion';
import InkblotLogo from '../../images/nav/inkblot-logo.svg';
import Assessment from '../../images/meditation/section-3-animation.svg';

const InkblotAssess = ({ handleNext }) => (
  <div className="onboarding-auth">
    <motion.div
      initial={{ scale: 0 }}
      animate={{ rotate: 0, scale: 1 }}
      transition={{
        type: 'spring',
        stiffness: 100,
        damping: 15,
      }}
    >
      <div className="onboarding-container custom-onboarding-container">
        <div className="custom-onboard custom-auth-text custom-inkblot-therapy">
          <h2 style={{ marginBottom: 60 }}>Inkblot Assess</h2>
          <p>
            <FormattedMessage
              id="onboarding.assess.description"
              defaultMessage="Inkblot Assess uses cutting-edge research to give you rapid feedback on your psychological health."
            />
          </p>
          <button type="button" onClick={handleNext}>
            <FormattedMessage id="onboarding.next" defaultMessage="Next" />
          </button>
        </div>
        <img src={Assessment} alt="" />
        <div className="custom-auth-logo">
          <img src={InkblotLogo} alt="inkblot logo" />
        </div>
      </div>
    </motion.div>
  </div>
);

InkblotAssess.propTypes = {
  handleNext: PropTypes.func.isRequired,
};

export default InkblotAssess;

import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import section1Image from '../../../images/ilob/header-1.png';
import gscLogo from '../../../images/custom/logos/gsc-logo.png';
import gscLogoFr from '../../../images/custom/logos/gsc-logo-fr.png';
import inkblotLogo from '../../../images/nav/inkblot-logo.svg';
import { getLocale } from '../../../utils/locale';

const Section1 = () => {
  const isFr = getLocale() === 'fr';
  return (
    <div className="section-1">
      <div className="flex-row">
        <div className="half-column">
          <div className="logos">
            <img title="Inkblot" src={inkblotLogo} alt="Logo" />
            <img
              style={{ marginLeft: '44px' }}
              title="Gsc"
              src={isFr ? gscLogoFr : gscLogo}
              alt="Gsc logo"
            />
          </div>
          <div className="custom-header">
            <FormattedMessage
              id="ilob.yourWellBeing"
              defaultMessage="Your mental health is as important as your physical health."
            />
          </div>
          <div className="custom-sub-header">
            <FormattedHTMLMessage
              id="ilob.takeCare"
              defaultMessage="Take care of it with Inkblot therapy -- confidential video counselling with the therapist who best matches you. <br><br> Inkblot's unique matching process provides an extra element of comfort.  It incorporates diversity, inclusivity and considers personal preferences, as well as therapist credentials and expertise that align with your needs."
            />
          </div>
        </div>
        <div className="s1-image">
          <img
            src={section1Image}
            alt="Canadian video counselling"
            height="377px"
            width="594px"
          />
        </div>
      </div>
    </div>
  );
};

Section1.propTypes = {};

export default Section1;

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'gatsby';
import { unauthAuditProgress } from '../../../utils/pageUtils';
import { logEvent, OPEN_SIGNUP } from '../../../utils/amplitude';

const Section2 = () => {
  const toggleSignUp = () => {
    localStorage.setItem('signUp', 'open');
    logEvent(OPEN_SIGNUP, { page: '' });
    unauthAuditProgress('', 'open_signup');
    window.scrollTo({
      top: window.pageYOffset + 2,
    });
  };
  return (
    <div className="section-2">
      <div className="section-2-header">
        <FormattedMessage
          id="ilob.gettingStarted"
          defaultMessage="Getting started is simple."
        />
      </div>
      <div className="s2-steps">
        <div className="step-item">
          <div className="ellipse one"> 1 </div>
          <p>
            <FormattedMessage
              id="ilob.fillOut"
              defaultMessage="Fill out our simple survey."
            />
          </p>
        </div>
        <div className="step-item">
          <div className="ellipse two"> 2 </div>
          <p>
            <FormattedMessage
              id="ilob.chooseA"
              defaultMessage="Choose a counsellor matched specifically to you."
            />
          </p>
        </div>
        <div className="step-item">
          <div className="ellipse three"> 3 </div>
          <p>
            <FormattedMessage
              id="ilob.scheduleY"
              defaultMessage="Schedule your first session with no wait times. Evening and weekend appointments available."
            />
          </p>
        </div>
        <div className="step-item">
          <div className="ellipse four"> 4 </div>
          <p>
            <FormattedMessage
              id="ilob.speakWith"
              defaultMessage="Speak with your counsellor and feel better."
            />
          </p>
        </div>
        <div className="step-vertical-line">
          <div className="svl-1"></div>
        </div>
      </div>
      <div className="button-container">
          <button className="medium-blue-button" onClick={toggleSignUp}>
            <FormattedMessage
              id="navigation.createMyAccount"
              defaultMessage="Get Started"
            />
          </button>
      </div>
    </div>
  );
};

Section2.propTypes = {};

export default Section2;

import React from 'react';
import MetaTags from 'react-meta-tags';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import Mind from '../../images/people-connect-total-health/mind-logo.png';
import BCI from '../../images/people-connect-total-health/bci-logo.svg';

import Inkblot from '../../images/people-connect-total-health/inkblot-logo.svg';
import Morneau from '../../images/people-connect-total-health/morneau-shepell-logo.png';
import Lifespeak from '../../images/people-connect-total-health/lifespeak-logo.png';
import Aroga from '../../images/people-connect-total-health/aroga-logo.png';
import MHCC from '../../images/people-connect-total-health/mhcc-logo.png';
import CanadaLife from '../../images/people-connect-total-health/canada-life-logo.png';

const Index = () => (
  <div className="people-connect-health">
    <MetaTags>
      <meta name="robots" content="noindex" />
    </MetaTags>
    <div className="section-1 bci-section-1">
      <div className="bci-logos">
        <img src={Mind} alt="Mind Logo" />
        <img src={BCI} alt="BCI Logo" />
      </div>
      <h2 className="bci-h2">Learn more about your mental health benefits available.</h2>
      <FormattedHTMLMessage tagName="h4" id="pch.yourProviders" />
      <div className="bci-company-container">
        <div className="inkblot">
          <img src={Inkblot} alt="Inkblot logo" />
        </div>
        <div className="morneau">
          <img src={Morneau} alt="Morneau shepell" />
        </div>
        <div className="lifespeak">
          <img src={Lifespeak} alt="Lifespeak" />
        </div>
        <div className="aroga">
          <img src={Aroga} alt="Aroga" />
        </div>
        <div className="mhcc">
          <img src={MHCC} alt="MHCC" />
        </div>
        <div className="canadalife">
          <img src={CanadaLife} alt="Canada Life" />
        </div>
      </div>
    </div>
    <div className="bci-section-2">
      <div className="container">
        <div className="left">
          <div className="bci-white-box">
            <img src={Inkblot} alt="Inkblot logo" />
          </div>
        </div>
        <div className="right">
          <FormattedHTMLMessage
            tagName="h2"
            id="pch.onlineCounselling"
            defaultMessage="Online Counselling"
          />
          <FormattedHTMLMessage
            tagName="h1"
            id="pch.inkblot"
            defaultMessage="Inkblot"
          />
          <FormattedHTMLMessage
            tagName="p"
            id="pch.canadasTop"
            defaultMessage="Inkblot Therapy is Canada's top provider of online video-based one-on-one or couples counselling. Care is provided by highly experienced professionals across Canada. Inkblot highly values long-term relationships with care providers. Your initial individual consultation is complimentary. Subsequent sessions are reimbursable through your Canada Life Mental Health Services coverage or Health Spending Account (HSA)."
          />
          <div className="divider" />
          <p className="details">
            <FormattedMessage
              id="pch.mostSuitable"
              defaultMessage="Most suitable for those seeking online counselling and a longer-term relationship with their counsellor. Inkblot services are available to permanent, temporary and Co-op Students * for employees not under the Canada Life Benefits plan please check your insurance benefits for mental healths services."
            />
          </p>
          <a
            className="bci-button"
            href="https://inkblottherapy.com/bci"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedHTMLMessage
              tagName="a"
              id="pch.launchInkblot"
              defaultMessage="Launch Inkblot"
            />
          </a>
        </div>
      </div>
    </div>
    <div className="bci-section-3">
      <div className="container">
        <div className="left">
          <FormattedHTMLMessage
            tagName="h2"
            id="pch.efas"
            defaultMessage="EMPLOYEE AND FAMILY ASSISTANCE SERVICES (EFAS)"
          />
          <FormattedHTMLMessage
            tagName="h1"
            id="pch.morneau"
            defaultMessage="Morneau Shepell"
          />
          <FormattedHTMLMessage
            tagName="p"
            id="pch.efasProvider"
            defaultMessage="Morneau Shepell is our Employee and Family Assistance Services (EFAS) provider. The services offered are designed to help optimize our health, mental health, and productivity.  The EFAS program is available to all permanent and temporary employees. This program includes other counselling services including short-term counselling, career support services, financial and legal services, and healthy living services.<br /><br />Confidentiality is a top priority.  If you access these services, that information is not shared without your written consent. The costs associated with these services (assessment, counselling and/or referral services) are paid for by BCI."
          />
          <div className="divider" />
          <p className="details">
            <FormattedHTMLMessage
              tagName="span"
              id="pch.callTollFree"
              defaultMessage="Call Morneau Shepell toll-free, 24 hours per day, seven days a week at: <a href='tel:1-800-655-5004'>1-800-655-5004</a>. Morneau Shepell EFAS program is available to permanent and temporary staff."
            />
          </p>
        </div>
        <div className="right">
          <div className="bci-white-box">
            <img src={Morneau} alt="Morneau" />
          </div>
        </div>
      </div>
    </div>
    <div className="bci-section-4">
      <div className="container">
        <div className="left">
          <div className="bci-white-box">
            <img src={MHCC} alt="Lifespeak" />
          </div>
        </div>
        <div className="right">
          <FormattedHTMLMessage
            tagName="h2"
            id="pch.mentalHealth"
            defaultMessage="Mental Health"
          />
          <FormattedHTMLMessage
            tagName="h1"
            id="pch.mentalHealthTeam"
            defaultMessage="BCI Mental First Aid Team"
          />
          <FormattedHTMLMessage
            tagName="p"
            id="pch.allBCIers"
            defaultMessage="Available to support all BCIers, these individuals are trained in Mental Health First Aid from the Canadian Mental Health Association. They are a confidential resource, there to help those who are struggling to find the resources, professionals, and support they need to maintain their mental health."
          />
          <a
            className="bci-button"
            href="https://intranet.bci.ca/content/50334/mental-health-first-aid"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedHTMLMessage
              tagName="a"
              id="pch.launchWebsite"
              defaultMessage="Launch Website"
            />
          </a>
        </div>
      </div>
    </div>
    <div className="bci-section-5">
      <div className="container">
        <div className="left">
          <FormattedHTMLMessage
            tagName="h2"
            id="pch.virtualHealthCare"
            defaultMessage="Virtual health care"
          />
          <FormattedHTMLMessage
            tagName="h1"
            id="pch.arogaLM"
            defaultMessage="Aroga Lifestyle Medicine"
          />
          <FormattedHTMLMessage
            tagName="p"
            id="pch.onDemand"
            defaultMessage="Aroga Virtual Health Care Services (Aroga) provides BCIers and your family members access to an app for on-demand, virtual care appointments with healthcare professionals. Aroga Virtual Heath Carre Services are only available to permanent employees. Aroga's care team also has a clinical counsellor that can be referred to through the app.  "
          />
          <div className="divider" />
          <a
            className="bci-button"
            href="https://aroga.com/BCI/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedHTMLMessage
              tagName="a"
              id="pch.launchAroga"
              defaultMessage="Launch Aroga"
            />
          </a>
        </div>
        <div className="right">
          <div className="bci-white-box">
            <img src={Aroga} alt="Aroga" />
          </div>
        </div>
      </div>
    </div>
    <div className="bci-section-6">
      <div className="container">
        <div className="left">
          <div className="bci-white-box">
            <img src={Lifespeak} alt="Lifespeak" />
          </div>
        </div>
        <div className="right">
          <FormattedHTMLMessage
            tagName="h2"
            id="pch.wellbeingPlatform"
            defaultMessage="Wellbeing Platform"
          />
          <FormattedHTMLMessage
            tagName="h1"
            id="pch.lifespeak"
            defaultMessage="LifeSpeak"
          />
          <FormattedHTMLMessage
            tagName="p"
            id="pch.lifeincSaveonDrug"
            defaultMessage="LifeSpeak is a total well-being online platform that helps staff remain present, productive and thrive in life. The platform makes it easy for you and your families to access the support you need to overcome hurdles and accomplish goals, both personally and professional. LifeSpeak offers both proactive and reactive solutions to your total well-being (i.e. stress management, nutrition and more).<br /><br />You can tailor your user experience to suit your needs. The platform grants access to over 1,000 videos and podcasts on topics ranging from physical and mental health to personal development. LifeSpeak also allows you to connect with experts, acclaimed authors, medical professionals, and professors who are passionate about what they do, just like you!"
          />
          <div className="divider" />
          <p className="details">
            <FormattedHTMLMessage
              id="pch.availableToYou"
              tagName="span"
              defaultMessage="LifeSpeak is available to you at anytime from anywhere. To access the resource, visit the LifeSpeak website and sign in with our Corporate ID “lifespeak” (case sensitive). For external login, visit <a href='https://bcpsagency.lifespeak.com'>https://bcpsagency.lifespeak.com</a> and sign in with our Corporate ID 'lifespeak' (case sensitive). avaiable to permanent and temporary employees."
            />
          </p>
          <a
            className="bci-button"
            href="https://bcpsagency.lifespeak.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedHTMLMessage
              tagName="a"
              id="pch.launchLifespeak"
              defaultMessage="Launch Lifespeak"
            />
          </a>
        </div>
      </div>
    </div>
    <div className="bci-section-7">
      <div className="container">
        <div className="left">
          <FormattedHTMLMessage
            tagName="h2"
            id="pch.extendedHealthBenefits"
            defaultMessage="Extended Health Benefits"
          />
          <FormattedHTMLMessage
            tagName="h1"
            id="pch.canadaLife"
            defaultMessage="Canada Life"
          />
          <FormattedHTMLMessage
            tagName="p"
            id="pch.partneredLifeIncAkira"
            defaultMessage="Canada Life coverage for the fees for mental health services are available to all eligible plan members. The coverage can be used towards Recognized Social Workers, Registered Clinical Counsellors either in person or virtual platforms like Inkblot Therapy.<br /><br />Permanent employees are eligible for up to $500 coverage per calendar year. Service fees are also eligible for coverage through the healthcare spending account. "
          />
          <div className="divider" />
          <p className="details">
            <FormattedHTMLMessage
              id="pch.claimsCanBe"
              tagName="span"
              defaultMessage="Claims can be submitted to Canada Life/GroupNet for extended health and HSA top-up. GroupNet provides online claim submission for eligible plan members through their website."
            />
          </p>
          <a
            className="bci-button"
            href="https://gwl.greatwestlife.com/MyLogin"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedHTMLMessage
              tagName="a"
              id="pch.launchCanadaLife"
              defaultMessage="Launch Canada Life"
            />
          </a>
        </div>
        <div className="right">
          <div className="bci-white-box">
            <img src={CanadaLife} alt="Canada Life" />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Index;

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import SpinnerButton from '../SpinnerButton';
import PreviousButton from '../PreviousButton';

const containerStyle = {
  display: 'inline-flex',
  flexDirection: 'column',
  alignItems: 'center',
};

function FormButton({ disabled, loading, goBack }) {
  return (
    <div style={containerStyle}>
      <SpinnerButton
        disabled={disabled}
        label={<FormattedMessage id="eap.submit" />}
        loading={loading}
        style={{ marginTop: '40px' }}
      />
      <PreviousButton handleClick={goBack} />
    </div>
  );
}

FormButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  goBack: PropTypes.func.isRequired,
};

export default FormButton;

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { unauthAuditProgress } from '../../../utils/pageUtils';
import { logEvent, OPEN_SIGNUP } from '../../../utils/amplitude';
import section10Image from '../../../images/cfs/grid-multiple.png';
import section10ImageShort from '../../../images/cfs/grid-multiple-short.png';
import { isCFS } from '../../../utils/pageUtils';

const Section10 = () => {
  const toggleSignUp = () => {
    localStorage.setItem('signUp', 'open');
    logEvent(OPEN_SIGNUP, { page: '' });
    unauthAuditProgress('', 'open_signup');
    window.scrollTo({
      top: window.pageYOffset + 2,
    });
  };
  const path =
    typeof window !== 'undefined' &&
    window &&
    window.location &&
    window.location.pathname;
  return (
    <div className="section-10">
      <div className="section-10-header">
        <FormattedMessage
          id="cfs.feelBetter"
          defaultMessage="Feel better, live better."
        />
      </div>
      <div className="s10-text">
        <FormattedMessage
          id="cfs.counsellingIs"
          defaultMessage="Counselling is the best way to achieve mental well-being, learn to cope with work and life stressors and improve your relationships."
        />
      </div>
      <div className="s10-image">
        <img
          src={section10Image}
          alt="Grid Multiple People"
          height="300px"
          width="1338px"
        />
      </div>
      <div className="s10-image-short">
        <img
          src={section10ImageShort}
          alt="Grid Multiple People Short"
          height="134px"
          width="335px"
        />
      </div>
      <div className="s10-text-bold">
        <FormattedMessage
          id="cfs.bookYour"
          defaultMessage="Book your first free consultation today:"
        />
      </div>
      <div className="button-container">
        <button onClick={toggleSignUp} className="medium-green-button">
          {isCFS(path) ? <FormattedMessage
            id="navigation.findCounsellor"
            defaultMessage="FIND A COUNSELLOR"
          /> : <FormattedMessage id="navigation.getStarted" defaultMessage="Get started"/>}
        </button>
      </div>
    </div>
  );
};
Section10.propTypes = {};

export default Section10;

import React, { Component } from 'react';
import { FormattedHTMLMessage } from 'react-intl';

class Section3a extends Component {
  constructor(props) {
    super(props);
    this.state = { showChart: false };
  }
  toggleChart = () => {
    this.setState(prevState => ({ showChart: !prevState.showChart }));
  };
  render() {
    const { showChart } = this.state;
    return (
      <div className="section-3a">
        <div className="header">
          <FormattedHTMLMessage
            tagName="h2"
            id="mlf.viewComparison"
            defaultMessage="View a comparison chart of Inkblot vs Beacon"
          />
          <button onClick={this.toggleChart}>
            {showChart ? (
              <FormattedHTMLMessage
                tagName="span"
                id="mlf.hideChart"
                defaultMessage="HIDE CHART"
              />
            ) : (
              <FormattedHTMLMessage
                tagName="span"
                id="mlf.viewChart"
                defaultMessage="VIEW CHART"
              />
            )}
          </button>
        </div>
        {showChart && (
          <div className="chart">
            <div className="compare-table">
              <div />
              <FormattedHTMLMessage
                tagName="h4"
                id="mlf.offers"
                defaultMessage="OFFERS"
              />
              <FormattedHTMLMessage
                tagName="h4"
                id="mlf.idealFor"
                defaultMessage="IDEAL FOR"
              />
              <FormattedHTMLMessage
                tagName="h4"
                id="mlf.coverage"
                defaultMessage="COVERAGE"
              />
              <FormattedHTMLMessage
                tagName="h3"
                id="mlf.inkblotTitle"
                defaultMessage="Inkblot Therapy"
              />
              <FormattedHTMLMessage
                tagName="p"
                id="mlf.inkblotOffers"
                defaultMessage="Accessible video counselling on a secure and encrypted platform for use on any device (desktop, laptop, tablet, phone) by an accredited therapist."
              />
              <FormattedHTMLMessage
                tagName="p"
                id="mlf.inkblotIdealFor"
                defaultMessage="Any personal, family or work-related stressor or mental health concern, including depression, anxiety, post traumatic stress, panic, insomnia. Inkblot also provides addiction treatment."
              />
              <FormattedHTMLMessage
                tagName="p"
                id="mlf.inkblotCoverage"
                defaultMessage="MLF will cover the cost of next 10 hours of counselling for employees and their family members. Appointments are booked directly on-line."
              />
              <FormattedHTMLMessage
                tagName="h3"
                id="mlf.mBeaconTitle"
                defaultMessage="MindBeacon"
              />
              <FormattedHTMLMessage
                tagName="p"
                id="mlf.mBeaconOffers"
                defaultMessage="A licensed therapist guides you through exercises, readings and direct messages to help you learn skills to improve your mental health over a 6-12 week program."
              />
              <FormattedHTMLMessage
                tagName="p"
                id="mlf.mBeaconIdealFor"
                defaultMessage="Mental health concerns such as stress, anxiety, burnout, depression, alcohol use, insomnia, trauma and more."
              />
              <FormattedHTMLMessage
                tagName="p"
                id="mlf.mBeaconCoverage"
                defaultMessage="MLF will cover the full cost of the MindBeacon Therapist Guided Program for MLF employees and family members."
              />
            </div>

            {/* mobile table starts here */}
            <div className="compare-table-mobile">
              <div/>
              <div className="block">
                <p className="title">
                  <FormattedHTMLMessage tagName="span" id="mlf.offers" />
                </p>
                <div className="table-block-mobile">
                  <FormattedHTMLMessage
                    tagName="h2"
                    id="mlf.inkblotTitle"
                    defaultMessage="Inkblot Therapy"
                  />
                  <FormattedHTMLMessage tagName="p" id="mlf.inkblotOffers" />
                </div>
                <div className="table-block-mobile">
                  <FormattedHTMLMessage
                    tagName="h2"
                    id="mlf.mBeaconTitle"
                    defaultMessage="MindBeacon"
                  />
                  <FormattedHTMLMessage tagName="p" id="mlf.mBeaconOffers" />
                </div>
              </div>

              <div className="block">
                <p className="title">
                  <FormattedHTMLMessage tagName="span" id="mlf.idealFor" />
                </p>
                <div className="table-block-mobile">
                  <FormattedHTMLMessage
                    tagName="h2"
                    id="mlf.inkblotTitle"
                    defaultMessage="Inkblot Therapy"
                  />
                  <FormattedHTMLMessage tagName="p" id="mlf.inkblotIdealFor" />
                </div>
                <div className="table-block-mobile">
                  <FormattedHTMLMessage
                    tagName="h2"
                    id="mlf.mBeaconTitle"
                    defaultMessage="MindBeacon"
                  />
                  <FormattedHTMLMessage tagName="p" id="mlf.mBeaconIdealFor" />
                </div>
              </div>

              <div className="block">
                <p className="title">
                  <FormattedHTMLMessage tagName="span" id="mlf.coverage" />
                </p>
                <div className="table-block-mobile">
                  <FormattedHTMLMessage
                    tagName="h2"
                    id="mlf.inkblotTitle"
                    defaultMessage="Inkblot Therapy"
                  />
                  <FormattedHTMLMessage tagName="p" id="mlf.inkblotCoverage" />
                </div>
                <div className="table-block-mobile">
                  <FormattedHTMLMessage
                    tagName="h2"
                    id="mlf.mBeaconTitle"
                    defaultMessage="MindBeacon"
                  />
                  <FormattedHTMLMessage tagName="p" id="mlf.mBeaconCoverage" />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Section3a;

import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import mindBeaconLogo from '../../images/custom/logos/mind-beacon-logo.png';

const Section3 = () => (
  <div className="section section-3">
    <div className="image">
      <img src={mindBeaconLogo} alt="Beacon logo" />
    </div>
    <div className="text">
      <FormattedHTMLMessage
        tagName="h3"
        id="mlf.guidedDigital"
        defaultMessage="THERAPIST GUIDED PROGRAM"
      />
      <hr style={{ width: '276px', marginBottom: '20px', backgroundColor: '#18274b' }} />
      <h2>MindBeacon</h2>
      <FormattedHTMLMessage
        tagName="p"
        id="mlf.beaconDigital"
        defaultMessage="Over 100 Canadians start MindBeacon’s Therapist Guided Program every single day. You are matched one-to-one with a licensed therapist who communicates with you via secure, private direct messages. Unlike talk therapy, there are no appointments to keep, instead you can send your therapist a message any time. MindBeacon provides Cognitive Behavioural Therapy (CBT), a proven form of treatment where your therapist will guide you through personalized exercises and readings customized over a 6-12 week program. It also works. Within the first two weeks of the program, 71% of people see a reduction in their symptoms related to stress, anxiety, burnout, depression, alcohol use, insomnia, trauma and more."
      />
      <FormattedHTMLMessage
        tagName="p"
        id="mlf.mostSuitableBeacon"
        defaultMessage="<span class='second-p'>Most suitable for those who are busy, seeking flexible support and who feel more comfortable not communicating face-to-face.</span>"
      />
      <br />
      <FormattedHTMLMessage
        tagName="span"
        id="mlf.launchBeacon"
        defaultMessage="<a href='www.mindbeacon.com/mlf' target='_blank'>LAUNCH MINDBEACON</a>"
      />
    </div>
  </div>
);

export default Section3;

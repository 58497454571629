/* eslint-disable arrow-body-style */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import step4 from '../../../images/referral/step-4.png';
import french4 from '../../../images/custom/screens/group2.png';

const featureFour = {
  id: 'eap.confidential',
  title: 'Confidential video sessions any time, any place',
  descriptionId: 'eap.enjoySecure',
  description:
    'Enjoy secure sessions from the comfort of your own space and start feeling better.',
  image: step4,
  frImage: french4,
};

const Section8 = props => {
  const { id, title, description, descriptionId, image, frImage } = featureFour;
  return (
    <div className="section-8">
      <div className="v2-s7-image">
        <div
          alt=""
          className="bg-url-image"
          style={{
            backgroundImage: `url(${
              localStorage.getItem('selectedLanguage') !== 'fr'
                ? image
                : frImage
            })`,
          }}
        />
        <img
          className="url-image"
          src={
            localStorage.getItem('selectedLanguage') !== 'fr' ? image : frImage
          }
          alt={title}
        />
      </div>
      <div className="v2-s7-text">
        <div className="text">
          <div className="step-title font-barlow-34">
            <span>
              {props.intl.formatMessage({ id, defaultMessage: title })}
            </span>
          </div>
          <div className="step-description font-open-sans-20">
            {props.intl.formatMessage({
              id: descriptionId,
              defaultMessage: description,
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

Section8.propTypes = {
  intl: PropTypes.object,
};

Section8.defaultProps = {
  intl: {},
};

export default injectIntl(Section8);

import React from 'react';
import { FormattedMessage } from 'react-intl';
import section9Image from '../../../images/cfs/female-art.png';
import section9ImageBlob from '../../../images/cfs/s9-blob.png';
import checkboxOn from '../../../images/cfs/checkbox-on.png';

const Section9 = () => (
  <div className="section-9">
    <div className="flex-row">
      <div className="half-column">
        <div className="section-9-header">
          <FormattedMessage
            id="cfs.yourSecurity"
            defaultMessage="Your security is our top priority:"
          />
        </div>
        <div className="v2-s4-text">
          <div className="steps">
            <div className="s9-steps">
              <div className="step-item">
                <div className="checkbox">
                  <img src={checkboxOn} alt="Checkbox" />
                </div>
                <p>
                  <FormattedMessage
                    id="cfs.weFollow"
                    defaultMessage="We follow strict Canadian regulations for online health services."
                  />
                </p>
              </div>
              <div className="step-item">
                <div className="checkbox">
                  <img src={checkboxOn} alt="Checkbox" />
                </div>
                <p>
                  <FormattedMessage
                    id="cfs.allInformation"
                    defaultMessage="All information is fully encrypted on a regularly audited server in Toronto."
                  />
                </p>
              </div>
              <div className="step-item">
                <div className="checkbox">
                  <img src={checkboxOn} alt="Checkbox" />
                </div>
                <p>
                  <FormattedMessage
                    id="cfs.onlyYou"
                    defaultMessage="Only you and your counsellor have access to your mental health information."
                  />
                </p>
              </div>
              <div className="step-item">
                <div className="checkbox">
                  <img src={checkboxOn} alt="Checkbox" />
                </div>
                <p>
                  <FormattedMessage
                    id="cfs.allCounsellors"
                    defaultMessage="All counsellors reside in Canada, are highly experienced, and are regulated professionals."
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="s9-image">
        <img
          src={section9Image}
          alt="Female Art"
          height="354px"
          width="589px"
        />
      </div>
    </div>
    <div className="s9-image-blob">
      <img src={section9ImageBlob} alt="Blob" height="519px" width="450px" />
    </div>
  </div>
);

Section9.propTypes = {};

export default Section9;

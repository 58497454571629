import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { press } from '../../pagePartials/new/partials/pressData';

const Section4 = () => (
  <div className="section-2 section-4-league">
    <FormattedHTMLMessage
      tagName="p"
      id="new.asSeenOn"
      defaultMessage="As seen on:"
    />
    <div className="flex-row">
      {
        press.map((publication) => (
          <img key={publication.id} src={publication.source} alt={publication.name} />
        ))
      }
    </div>
  </div>
);

Section4.propTypes = {};

export default Section4;

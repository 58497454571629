export const aboutData = [
  {
    id: 1,
    text:
      'As a student, your life is increasingly filled with uncertainty, complicated relationships and frequent change. It can be an overwhelming and difficult time to navigate. Often, balancing these competing factors leaves people suffering in silence: feeling empty, withdrawn, and unsettled without sufficient support and help.',
    title: false,
  },
  {
    id: 2,
    text: 'At mywellness powered by Inkblot, we aim to create a better way.',
    title: true,
  },
  {
    id: 3,
    text:
      'Some of the best-trained mental health care professionals in the world are located in Canada, but their services and insights are out of reach for most people. There are several key barriers: cost, finding the right counsellor, finding time during the day, and the stigma associated with having difficulty coping.\n\nWe believe that seeking help is the highest form of strength and that when someone asks for help, there should be no barriers.\n\nFuelled by these core beliefs, we have worked hard to create a platform that allows for more convenient, high-quality counselling. We have cut costs by automating the administrative tasks and minimizing overhead. We have over fifty psychotherapists who specialize in most areas of mental health. We have developed an advanced matching algorithm to help you find the right counsellor for your needs. We offer appointments at all times of the day - including evenings and weekends - and there is no waiting room.\n\nIt’s easy to start. Simply fill out our simple survey, choose a counsellor, schedule your first session and begin. Your first session is always free and no payment information is needed. We want you to feel comfortable and get to know your counsellor.',
    title: false,
  },
  {
    id: 4,
    text: "Let's talk.",
    title: true,
  },
];

import React from 'react';

const setLanguage = lang => {
  localStorage.setItem('selectedLanguage', lang);
  window.location.reload();
};

const LanguageSelector = () => {
  return (
    <div className="en-fr">
      {localStorage.getItem('selectedLanguage') === 'en' ? (
        <div>
          <span>EN</span>
          <span
            role="presentation"
            onClick={() => setLanguage('fr')}
            style={{ fontWeight: 300 }}
          >
            FR
          </span>
        </div>
      ) : (
        <div>
          <span
            role="presentation"
            onClick={() => setLanguage('en')}
            style={{ fontWeight: 300 }}
          >
            EN
          </span>
          <span>FR</span>
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;

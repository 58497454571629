import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { whichCompany } from '../../utils/pageUtils';

const SectionFour = ({ EFAP, intl, removeLegalFinancial, EAP, path }) => {
  function renderServices() {
    if (whichCompany().path === 'endy') {
      return intl.formatMessage({ id: 'eap.yourEnhancedServices' });
    }
    if (whichCompany().path === 'feminuity') {
      return intl.formatMessage({
        id: 'eap.yourTeamMemberAssistanceProgramServices',
      });
    }
    if (whichCompany().path === 'inconfidence' || whichCompany().path === 'inconfidencepersonal') {
      return intl.formatMessage({
        id: 'eap.inConfidenceServices',
      })
    }
    if (EAP) {
      return intl.formatMessage(
        {
          id: 'eap.yourEap',
          defaultMessage: 'Your {eapEN} Services',
        },
        {
          eapEN:
            whichCompany().path === 'arta' || whichCompany().path === 'pas' || whichCompany().path === 'cima'
              ? 'MAP'
              : whichCompany().path === 'eiqc'
                ? 'SAP'
                : 'EAP',
          eapFR: whichCompany().path === 'arta' || whichCompany().path === 'cima' || whichCompany() === 'pas' ? 'PAM' : 'PAE',
        },
      );
    }
    if (EFAP) {
      return intl.formatMessage({
        id: 'eap.yourEfap',
        defaultMessage: 'Your EFAP Services',
      });
    }
  }

  return (
    <div className={EFAP || EAP ? 'section-4' : 'EFAP-hidden'}>
      <div className="your-efap-services">{renderServices()}</div>
      <ul>
        <li>
          {intl.formatMessage({
            id: 'eap.mentalHealth',
            defaultMessage:
              'Mental health management',
          })}
        </li>
        <li>
          {intl.formatMessage({
            id: 'eap.stressManagement',
            defaultMessage: 'Stress management and reduction',
          })}
        </li>
        <li>
          {intl.formatMessage({
            id: 'eap.relationshipFamily',
            defaultMessage: 'Relationship and family support',
          })}
        </li>
        {!removeLegalFinancial && (
          <li>
            {intl.formatMessage({
              id: 'eap.legalFinancial',
              defaultMessage: 'Legal and financial consultation',
            })}
          </li>
        )}
        <li>
          {path === 'atb'
            ? 'Coaching services'
            : intl.formatMessage({
                id: 'eap.researchAdvisory',
                defaultMessage: 'Research/advisory services',
              })}
        </li>
      </ul>
    </div>
  );
};

SectionFour.propTypes = {
  EFAP: PropTypes.bool,
  intl: PropTypes.object,
  removeLegalFinancial: PropTypes.bool,
  EAP: PropTypes.bool,
  path: PropTypes.string,
};

SectionFour.defaultProps = {
  intl: {},
  EFAP: false,
  EAP: false,
  removeLegalFinancial: false,
  path: null,
};

export default injectIntl(SectionFour);

/* eslint-disable arrow-body-style */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';
import step3 from '../../../images/referral/step-3.png';
import french3 from '../../../images/custom/screens/group-1.png';
import { COUNTRY } from '../../../utils/environment';

const featureThree = {
  id: 'eap.firstFree',
  titleValue: COUNTRY === 'CA' ? 'free' : '',
  title: 'Book your first {free} Session',
  descriptionId: 'eap.foundProvider',
  descriptionValue:
    COUNTRY === 'CA'
      ? 'Your first session is always free until you find the right provider.'
      : '',
  description:
    'When you’ve found your provider, you’ll be able to book your initial session. {free}',
  image: step3,
  frImage: french3,
};

const Section7 = props => {
  const {
    id,
    titleValue,
    title,
    descriptionValue,
    description,
    descriptionId,
    image,
    frImage,
  } = featureThree;
  return (
    <div className="section-7">
      <div className="v2-s6-text">
        <div className="text">
          <div className="step-title font-barlow-34">
            <FormattedHTMLMessage
              tagName="span"
              id={id}
              values={{ free: titleValue }}
              defaultMessage={title}
            />
          </div>
          <div className="step-description font-open-sans-20">
            <FormattedHTMLMessage
              tagName="span"
              id={descriptionId}
              values={{ free: descriptionValue }}
              defaultMessage={description}
            />
          </div>
        </div>
      </div>
      <div className="v2-s6-image">
        <div
          alt=""
          className="bg-url-image"
          style={{
            backgroundImage: `url(${
              localStorage.getItem('selectedLanguage') !== 'fr'
                ? image
                : frImage
            })`,
          }}
        />
        <img
          className="url-image"
          src={
            localStorage.getItem('selectedLanguage') !== 'fr' ? image : frImage
          }
          alt=""
        />
      </div>
    </div>
  );
};

Section7.propTypes = {
  intl: PropTypes.object,
};

Section7.defaultProps = {
  intl: {},
};

export default injectIntl(Section7);

import React from 'react';
import { FormattedMessage } from 'react-intl';
import section1Image from '../../../images/cfs/header-1.png';
import cfsLogo from '../../../images/custom/logos/cfs-logo.png';
import nshnLogo from '../../../images/custom/logos/nshn-logo.png';
import nshnLogoFr from '../../../images/custom/logos/nshn-logo-fr.png';
import inkblotLogo from '../../../images/nav/inkblot-logo.svg';
import { isCFS } from '../../../utils/pageUtils';
import { getLocale } from '../../../utils/locale';

const Section1 = () => {
  const path =
    typeof window !== 'undefined' &&
    window &&
    window.location &&
    window.location.pathname;
  const isFr = getLocale() === 'fr';
  return (
    <div className="section-1">
      <div className="flex-row">
        <div className="half-column">
          <div className="logos">
            <img title="Inkblot" src={inkblotLogo} alt="Logo" />
            {isCFS(path) ? (
              <img
                style={{ marginLeft: '44px' }}
                title="Omers"
                src={cfsLogo}
                alt="CFS logo"
              />
            ) : (
              <img
                style={{ marginLeft: '44px' }}
                title="Omers"
                src={isFr ? nshnLogoFr : nshnLogo}
                alt="NSHN logo"
              />
            )}
          </div>
          <div className="custom-header">
            <FormattedMessage
              id="cfs.yourWellBeing"
              defaultMessage="Your mental health is as important as your physical health."
            />
          </div>
          <div className="custom-sub-header">
            {isCFS(path) ? (
              <FormattedMessage
                id="cfs.takeCare"
                defaultMessage="Take care of it with Inkblot’s confidential video counselling."
              />
            ) : (
              <FormattedMessage
                id="nshn.takeCare"
                defaultMessage="TAKE CARE OF IT WITH INKBLOT’S CONFIDENTIAL STUDENT ASSISTANCE PROGRAM (SAP)."
              />
            )}
          </div>
        </div>
        <div className="s1-image">
          <img
            src={section1Image}
            alt="Canadian video counselling"
            height="377px"
            width="594px"
          />
        </div>
      </div>
    </div>
  );
};

Section1.propTypes = {};

export default Section1;

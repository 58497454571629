import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { unauthAuditProgress, isOmers } from '../../utils/pageUtils';
import { logEvent, OPEN_SIGNUP } from '../../utils/amplitude';
import section1Animation from '../../images/new/animations/section-1-animation.png';

const Section1 = () => {
  const toggleSignUp = () => {
    localStorage.setItem('signUp', 'open');
    logEvent(OPEN_SIGNUP, { page: '' });
    unauthAuditProgress('', 'open_signup');
    window.scrollTo({
      top: window.pageYOffset + 1,
    });
  };
  const path =
    typeof window !== 'undefined' &&
    window &&
    window.location &&
    window.location.pathname;
  return (
    <div className="section-1">
      <div className="flex-row">
        <div className="half-column">
          <div className="hero">
            <h1>
              <FormattedMessage
                id="league.yourWellBeing"
                defaultMessage="Your well-being matters. Take care of it with Inkblot’s confidential mental health services."
              />
            </h1>
            {isOmers(path) ? (
              <FormattedHTMLMessage
                tagName="p"
                id="league.partneredOmers"
                defaultMessage="OMERS has partnered with Inkblot to provide you with easy to access support for your life, work and mental health challenges - any time, any place."
              />
            ) : (
              <FormattedHTMLMessage
                tagName="p"
                id="league.yourCompany"
                defaultMessage="Your company has partnered with Inkblot to provide you with easy to access support for your life, work and mental health challenges - any time, any place."
              />
            )}
            <FormattedHTMLMessage
              tagName="p"
              id="league.servicesAvailable"
              defaultMessage="<b>Inkblot’s services are voluntary and immediately available, 24/7/365.</b>"
            />
            <button onClick={toggleSignUp}>
              <FormattedMessage
                id="league.getStarted"
                defaultMessage="Get Started With Inkblot"
              />
            </button>
          </div>
        </div>
        <div className="half-column">
          <img src={section1Animation} alt="Canadian video counselling" />
        </div>
      </div>
    </div>
  );
};

Section1.propTypes = {};

export default Section1;

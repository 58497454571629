import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const buttonStyle = {
  background: 'none',
  cursor: 'pointer',
  marginTop: '5px',
};

const textStyle = {
  fontFamily: "'Source Sans Pro', sans-serif",
  fontSize: '16px',
  fontWeight: '600',
  color: '#2e5fca',
  letterSpacing: '.2px',
  marginBottom: '0px',
};

function PreviousButton({ label, handleClick }) {
  return (
    <button onClick={handleClick} style={buttonStyle} type="button">
      <p style={textStyle}>{label}</p>
    </button>
  );
}

PreviousButton.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  handleClick: PropTypes.func,
};

PreviousButton.defaultProps = {
  label: <FormattedMessage id="previousButton.previous" />,
  handleClick: () => {},
};

export default PreviousButton;

/* eslint-disable arrow-body-style */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import step2 from '../../../images/custom/screens/Customcounsellormatchcard.png';
import french2 from '../../../images/custom/screens/Customcounsellormatchcardfrench.png';

const featureTwo = {
  id: 'eap.customCounsellor',
  title: 'Custom Counsellor Match',
  descriptionId: 'eap.afterQuestionnaire',
  description:
    'After the questionnaire, you’ll be directed to a provider selection page where matches will be ranked based on effectiveness and your individual needs.',
  image: step2,
  frImage: french2,
};

const Section6 = props => {
  const { id, title, description, descriptionId, image, frImage } = featureTwo;
  return (
    <div className="section-6">
      <div className="v2-s5-image">
        <div
          alt=""
          className="bg-url-image"
          style={{
            backgroundImage: `url(${
              localStorage.getItem('selectedLanguage') !== 'fr'
                ? image
                : frImage
            })`,
          }}
        />
        <img
          className="url-image"
          src={
            localStorage.getItem('selectedLanguage') !== 'fr' ? image : frImage
          }
          alt={title}
        />
      </div>
      <div className="v2-s5-text">
        <div className="text">
          <div className="step-title font-barlow-34">
            <span>
              {props.intl.formatMessage({ id, defaultMessage: title })}
            </span>
          </div>
          <div className="step-description font-open-sans-20">
            {props.intl.formatMessage({
              id: descriptionId,
              defaultMessage: description,
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

Section6.propTypes = {
  intl: PropTypes.object,
};

Section6.defaultProps = {
  intl: {},
};

export default injectIntl(Section6);

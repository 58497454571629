import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Link } from 'gatsby';
import MetaTags from 'react-meta-tags';
import _ from 'lodash';

import { validEmail } from 'src/utils/form';
import Consent from '../auth/consent';
import SessionTwo from '../../images/custom/illustrations/session-2.svg';
import { SERVER_URL } from '../../utils/environment';
import { redirectToTherapy } from '../../utils/redirect';
import {
  unauthAuditProgress,
  auditProgress,
  whichCompany,
} from '../../utils/pageUtils';
import LogEventOnMount from '../LogEventOnMount';
import {
  OPEN_SIGNUP,
  OPEN_FORGOT_PASSWORD,
  logEvent,
  SENT_FORGOT_PASSWORD,
  COMPLETED_SIGNUP,
} from '../../utils/amplitude';
import { minSignupData } from '../../pagePartials/auth';
import EyeIcon from '../EyeIcon';

class DomainGatedSignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      registerMode: false,
      sentReset: false,
      message: false,
      signUpDisabled: false,
      error: {},
      visiblePassword: {},
    };
  }

  componentDidMount() {
    unauthAuditProgress(whichCompany().path, 'open_forgot_password');
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.recentlyUpdated !== prevState.recentlyUpdated) {
      this.validateForm(this.state.recentlyUpdated);
    }
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      sentReset: false,
      message: false,
      recentlyUpdated: name,
    });
  };

  handleChangeCheckbox = event => {
    this.setState({
      privacyConsent: event.target.checked,
      recentlyUpdated: event.target.name,
    });
  };

  forgotPassword = () => {
    const values = {
      email: this.state.email,
      domain: whichCompany().email_domain,
      company: whichCompany().path,
    };
    axios
      .post(`${SERVER_URL}/api/v2/forgot_passwords/domain_gated`, values)
      .then(response => {
        this.setState({ sentReset: true });
        logEvent(SENT_FORGOT_PASSWORD, {
          page: whichCompany().path,
          flow_type: 'b2b registration',
          b2b_page_type: 'custom portal',
          gating_type: 'domain gated',
          company: whichCompany().path,
        });
        unauthAuditProgress(whichCompany().path, 'sent_forgot_password');

        if (response.data.message) {
          if (response.data.message === 'redirect_to_sign_up') {
            this.setState({ registerMode: true });
            logEvent(OPEN_SIGNUP, {
              page: whichCompany().path,
              flow_type: 'b2b registration',
              b2b_page_type: 'custom portal',
              gating_type: 'domain gated',
              company: whichCompany().path,
            });
            unauthAuditProgress(whichCompany().path, 'open_signup');
          } else {
            this.setState({ message: response.data.message });
          }
        }
      })
      .catch(() => {
        this.setState({ sentReset: true });
      });
  };

  signUp = () => {
    const { privacyConsent } = this.state;
    const values = _.omit(this.state, [
      'showForm',
      'message',
      'signUpDisabled',
      'error',
      'signUpError',
      'recentlyUpdated',
      'privacyConsent'
    ]);
    const corporate_account = whichCompany().path;
    const mergedValues = { ...values, source: 'therapy', consent_yn: privacyConsent, corporate_account };
    axios
      .post(`${SERVER_URL}/api/signup`, mergedValues)
      .then(response => {
        const token = response.data.access_token;
        logEvent(COMPLETED_SIGNUP, {
          page: corporate_account,
          flow_type: 'b2b registration',
          b2b_page_type: 'custom portal',
          gating_type: 'domain gated',
          company: corporate_account,
        });
        auditProgress(corporate_account, 'completed_signup', token);
        redirectToTherapy(token);
      })
      .catch(error => {
        if (error.response && error.response.data.error) {
          this.setState({ message: error.response.data.error });
        } else {
          this.setState({ message: 'Error!' });
        }
      });
  };

  handleSubmitForCheck = event => {
    event.preventDefault();
    this.forgotPassword();
  };

  handleSubmitForRegister = event => {
    const { signUpDisabled } = this.state;
    event.preventDefault();

    if (!signUpDisabled) this.signUp();
  };

  emailExists = email => {
    axios
      .get(
        `${SERVER_URL}/api/signup/check_email?email=${encodeURIComponent(
          email,
        )}`,
      )
      .then(() => {})
      .catch(e => {
        const isFr = localStorage.getItem('selectedLanguage') === 'fr';
        this.setState({
          error: {
            ...this.state.error,
            email: isFr ? 'Ce courriel existe déjà' : e.response.data.error ,
          },
        });
      });
  };

  validateForm = recentlyUpdated => {
    const isFr = localStorage.getItem('selectedLanguage') === 'fr';
    const requiredField = isFr
      ? 'Ceci est un champ obligatoire'
      : 'This is a required field';
    const emailValid = isFr
      ? 'Veuillez saisir une adresse courriel - Exemple, utilisateur@exemple.com'
      : 'Please enter an email address - Example, user@example.com';
    const passwordSix = isFr
      ? 'Le mot de passe doit être au moins de 8 caractères '
      : 'Password must be at least 8 characters';
    const passwordMatch = isFr
      ? 'Les mots de passe doivent correspondre'
      : 'Passwords must match';

    const {
      first_name,
      last_name,
      email,
      privacyConsent,
      password,
      password_confirm,
    } = this.state;
    const error = {};

    if (first_name === '') {
      error.first_name = requiredField;
    }
    if (last_name === '') {
      error.last_name = requiredField;
    }
    if (email === '') {
      error.last_name = requiredField;
    }
    if (!privacyConsent) {
      error.privacyConsent = requiredField;
    }
    if (email) {
      if (validEmail.test(email)) {
        // to reduce memory usage, we will call check_email api only when email field updated
        if (recentlyUpdated === 'email') {
          this.emailExists(email);
        }
      } else {
        error.email = emailValid;
      }
    }
    if (password) {
      if (password.length < 8) {
        error.password = passwordSix;
      } else if (password !== password_confirm) {
        error.password_confirm = passwordMatch;
      }
    }

    this.setState({
      recentlyUpdated: null,
      signUpDisabled:
        !_.isEmpty(error) ||
        !first_name ||
        !last_name ||
        !email ||
        !privacyConsent ||
        !password ||
        !password_confirm,
      error,
    });
  };

  renderConsent = () => {
    const { error } = this.state;
    return (
      <Consent error={error} handleChangeCheckbox={this.handleChangeCheckbox} />
    );
  };

  renderInputs = inputs => {
    const { error, visiblePassword } = this.state;
    return _.map(inputs, ({ id, name, type, fullWidth, text, defaultText }) => (
      <div key={id}>
        <h4>
          {this.props.intl.formatMessage({
            id: text,
            defaultMessage: defaultText,
          })}
        </h4>
        {type === 'password' ? (
          <div className="password-input">
            <div className="text-input">
              <input
                id={name}
                className={fullWidth ? 'full-width' : ''}
                onChange={event => this.handleChange(event)}
                type={visiblePassword[name] ? 'text' : 'password'}
                name={name}
                placeholder={this.props.intl.formatMessage({
                  id: text,
                  defaultMessage: defaultText,
                })}
                value={this.state[name]}
              />
            </div>
            <EyeIcon
              isVisible={visiblePassword[name]}
              onClick={() => {
                const visible = { ...visiblePassword };
                visible[name] = !visiblePassword[name];
                this.setState({ visiblePassword: { ...visible } });
              }}
            />
          </div>
        ) : (
          <input
            id={name}
            className={fullWidth ? 'full-width' : ''}
            onChange={event => this.handleChange(event)}
            type={type}
            name={name}
            placeholder={
              type === 'email'
                ? 'example@example.com'
                : this.props.intl.formatMessage({
                    id: text,
                    defaultMessage: defaultText,
                  })
            }
            value={this.state[name]}
            disabled={name === 'email'}
          />
        )}
        <p
          className={`error-text ${error[name] ? '' : 'hidden'}`}
          style={{ fontSize: '11px', position: 'absolute' }}
        >
          {error[name]}
        </p>
      </div>
    ));
  };

  render() {
    const { registerMode, sentReset, message } = this.state;
    const { dependants, path } = whichCompany();
    return registerMode ? (
      <div className="custom-auth custom-dependants" role="main">
        <MetaTags>
          <meta name="robots" content="noindex" />
        </MetaTags>

        <div className="dependants-container custom-auth-container">
          <div className="dependant-auth-form">
            <h2>
              {this.props.intl.formatMessage({
                id: 'eap.signup',
                defaultMessage: 'Sign up',
              })}
            </h2>
            <form onSubmit={this.handleSubmitForRegister}>
              {this.renderInputs(minSignupData)}
              {this.renderConsent()}
              <div>
                <button type="submit">
                  {this.props.intl.formatMessage({
                    id: 'eap.signup',
                    defaultMessage: 'Sign Up',
                  })}
                </button>
              </div>
              <div style={{ marginTop: 20 }}>
                <p>{message}</p>
              </div>
            </form>
          </div>
        </div>
      </div>
    ) : (
      <div className="custom-auth" role="main">
        <div className="auth-container custom-auth-container">
          <div className="custom-auth-text">
            <h2>
              {this.props.intl.formatMessage({
                id: 'eap.gettingStarted',
                defaultMessage: 'Getting Started',
              })}
            </h2>
            <form onSubmit={this.handleSubmitForCheck}>
              <label className="accessibility-h4">
                {whichCompany().customLabelForSignUpInputField
                  ? this.props.intl.formatMessage({
                      id: whichCompany().customLabelForSignUpInputField,
                    })
                  : this.props.intl.formatMessage({
                      id: 'eap.workEmail',
                      defaultMessage: 'Work Email *',
                    })}
              </label>
              <input name="email" type="text" onChange={this.handleChange} />
              <button disabled={sentReset} type="submit">
                {this.props.intl.formatMessage({
                  id: 'eap.submit',
                  defaultMessage: 'Submit',
                })}
              </button>
            </form>
            {sentReset && (
              <p>
                {message ||
                  this.props.intl.formatMessage({
                    id: 'eap.emailLink',
                    defaultMessage:
                      'An email with a link to complete your registration will be sent to you shortly.',
                  })}
              </p>
            )}
            {dependants && (
              <h4>
                {this.props.intl.formatMessage({
                  id: 'eap.spouseOrDependant',
                  defaultMessage: 'Are you a spouse or a dependant?',
                })}{' '}
                <Link
                  to={`/dependants?company=${path}`}
                  style={{ color: '#2a5ccd' }}
                >
                  {this.props.intl.formatMessage({
                    id: 'eap.registerHere',
                    defaultMessage: 'Sign Up Here',
                  })}
                </Link>
              </h4>
            )}
            <span className="accessibility-h4">
              {this.props.intl.formatMessage({
                id: 'eap.alreadyHave',
                defaultMessage: 'Already have an account?',
              })}
              <Link
                to={`/sign_in?company=${path}`}
                style={{ color: '#2a5ccd' }}
              >
                {this.props.intl.formatMessage({
                  id: 'eap.signin',
                  defaultMessage: 'Sign In',
                })}
              </Link>
            </span>
          </div>
          <img src={SessionTwo} alt="" />
        </div>
        <LogEventOnMount
          eventType={OPEN_FORGOT_PASSWORD}
          eventProperties={{
            page: path,
            flow_type: 'b2b registration',
            b2b_page_type: 'custom portal',
            gating_type: 'domain gated',
            company: path,
          }}
        />
      </div>
    );
  }
}

DomainGatedSignUp.propTypes = {
  intl: PropTypes.object,
};

DomainGatedSignUp.defaultProps = {
  intl: {},
};

export default injectIntl(DomainGatedSignUp);

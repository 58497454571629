import React from 'react';

import MetaTags from 'react-meta-tags';
import ATBLogo from '../../images/people-connect-total-health/atb-logo.png';

import InkblotEFAP from '../../images/people-connect-total-health/inkblot-efap-logo.svg';
import Headversity from '../../images/people-connect-total-health/headversity-logo.png';
import SunLife from '../../images/people-connect-total-health/sun-life-logo.png';
import BlueCross from '../../images/people-connect-total-health/blue-cross-logo.png';
import Balance from '../../images/people-connect-total-health/balance-logo.png';

import HeadversityWhite from '../../images/people-connect-total-health/headversity-logo-white.png';
import GooglePlay from '../../images/people-connect-total-health/google-play.svg';
import AppStore from '../../images/people-connect-total-health/app-store.svg';
import BlueCrossWhite from '../../images/people-connect-total-health/blue-cross-logo-white.png';
import ATBWhite from '../../images/people-connect-total-health/atb-logo-white.png';

const Index = () => (
  <div className="people-connect-health">
    <MetaTags>
      <meta name="robots" content="noindex" />
    </MetaTags>
    <div className="section-1 atb-section-1">
      <div className="people-logo atb-logo">
        <img src={ATBLogo} alt="ATB Logo" />
      </div>
      <h1>Your Total Health Resources</h1>
      <h2>Learn more about your mental health benefits available.</h2>
      <div className="divider" />
      <h4>your providers:</h4>
      <div className="company-container">
        <img src={InkblotEFAP} alt="Inkblot EFAP" />
        <img src={Headversity} alt="headversity" />
        <img src={SunLife} alt="SunLife" />
        <img src={BlueCross} alt="Alberta Blue Cross" />
        <img src={Balance} alt="Balance" />
      </div>
    </div>
    <div className="section-2 atb-section-2">
      <div className="container">
        <div className="left">
          <div className="white-box">
            <img src={InkblotEFAP} alt="Inkblot EFAP logo" />
          </div>
        </div>
        <div className="right">
          <h2>Employee &amp; Family Assistance Program</h2>
          <h1>Inkblot EFAP</h1>
          <p>
            Your Employee &amp; Family Assistance Program (EFAP) provides you
            with immediate and confidential help for any work, health or life
            concern. We’re available anytime and anywhere. Let us help.
          </p>

          <p>
            Suitable for those seeking online counselling or coaching (legal,
            financial, career, health, life transitions).
          </p>
          <br />
          <a
            className="atb-button"
            href="https://inkblottherapy.com/atb"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>LAUNCH INKBLOT EFAP</span>
          </a>
        </div>
      </div>
    </div>
    <div className="section-3 atb-section-3">
      <div className="container">
        <div className="left">
          <div className="white-box">
            <img src={HeadversityWhite} alt="Headversity" />
          </div>
        </div>
        <div className="right">
          <h2>workplace mental health</h2>
          <h1>Headversity</h1>
          <p>
            headversity is a workplace mental health and resilience platform
            that’s built for the modern workforce. Like a personalized
            resilience trainer in your pocket, headversity puts tools and
            content in your hands so you can level up your mental health,
            everyday. With training that’s designed in micro form on your mobile
            device, you can experience benefits in building your foundational
            resilience skills in just a few minutes a day.
          </p>
          <div className="divider" />
          <div>
            <a
              href="https://play.google.com/store/apps/details?id=com.headversity.mobile"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt="Google play store"
                src={GooglePlay}
                style={{ marginRight: '20px' }}
              />
            </a>
            <a
              href="https://apps.apple.com/ca/app/headversity/id1480434092"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img alt="Apple app store" src={AppStore} />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div className="section-4 atb-section-4">
      <div className="container">
        <div className="left">
          <div className="white-box">
            <img src={SunLife} alt="SunLife" />
          </div>
        </div>
        <div className="right">
          <h2>Retirement</h2>
          <h1>SunLife Financial</h1>
          <p>
            Sun Life Group Retirement Services has been ranked as the leading
            provider of Capital Accumulation Plans in Canada for more than 17
            years with:
          </p>
          <ul>
            <li>Nearly $80 billion in assets under management</li>
            <li>More than 11,200 group retirement policies in force</li>
            <li>Over 1.9 million participants</li>
            <li>Plans ranging in size from one to over 60,000 members</li>
          </ul>
          <div className="divider" />
          <p className="details">
            The Flexible Pension Plan through{' '}
            <a
              href="https://www.sunnet.sunlife.com/signin/mysunlife/home.wca?"
              target="_blank"
              rel="noopener noreferrer"
            >
              Sun Life
            </a>{' '}
            is available to permanent team members in pay grades J and above.
            Whether it’s a financial, physical or mental health goal -- they are
            available to guide you.
          </p>
          <p className="details">
            For more information log into your{' '}
            <a
              href="https://www.sunnet.sunlife.com/signin/mysunlife/home.wca?"
              target="_blank"
              rel="noopener noreferrer"
            >
              Sun Life
            </a>{' '}
            account or call Sun Life Customer Care Centre (24-hour service) -
            1-866-733-8612.
          </p>{' '}
          <p className="details">
            <i>
              Note: The Public Service Pension Plan (PSPP) is for non-management
              team members and information is available at the{' '}
              <a
                href="https://www.pspp.ca/page/pspp-home"
                target="_blank"
                rel="noopener noreferrer"
              >
                PSPP website
              </a>
              .
            </i>
          </p>{' '}
          <p className="details" style={{ marginBottom: '0px' }}>
            For more information please contact{' '}
            <a href="mailto:pensions@atb.com"> pensions@atb.com</a>.
          </p>
        </div>
      </div>
    </div>
    <div className="section-5 atb-section-5">
      <div className="container">
        <div className="left">
          <h2>benefits provider</h2>
          <h1>Alberta Blue Cross</h1>
          <p>
            As Alberta&apos;s leading benefit provider, Alberta Blue Cross
            provides practical benefits support to over 7 million Albertans,
            over 5,000 Alberta employers -- including ATB. Alberta Blue Cross
            offers coverage that Albertans know and prefer. With electronic
            claims submission and direct billing options, making claims is fast
            and convenient.
          </p>
          <div className="divider" />
          <p className="details">
            <a
              href="https://www.ab.bluecross.ca/plan-members/gpm-secure-services.php"
              target="_blank"
              rel="noopener noreferrer"
            >
              Alberta Blue Cross
            </a>{' '}
            is your benefits provider.
          </p>
          <p className="details">
            Their website lets you view your claims or submit one from any
            computer.
          </p>
          <p className="details">
            Contact by phone at 1-800-661-6995 or online{' '}
            <a
              href="https://www.ab.bluecross.ca/plan-members/gpm-secure-services.php"
              target="_blank"
              rel="noopener noreferrer"
            >
              Alberta Blue Cross
            </a>
            .
          </p>
        </div>
        <div className="right">
          <div className="white-box">
            <img src={BlueCrossWhite} alt="Alberta Blue Cross" />
          </div>
        </div>
      </div>
    </div>
    <div className="section-6 atb-section-6">
      <div className="container">
        <div className="left">
          <div className="white-box">
            <img src={Balance} alt="Balance" />
          </div>
        </div>
        <div className="right">
          <h2>wellness rewards program</h2>
          <h1>Balance</h1>
          <p>
            The &apos;Balance&apos; program by Alberta Blue Cross, is now
            available to help you live a healthier life.
          </p>
          <p>
            Balance includes a health risk assessment tool, is a resource for
            information on a variety of health issues, and provides tools to
            track the small steps you are taking to achieve a healthier
            lifestyle.
          </p>
          <div className="divider" />
          <p className="details">
            Access{' '}
            <a
              href="https://ab.bluecross.ca/employer-groups/gp-balance.php"
              target="_blank"
              rel="noopener noreferrer"
            >
              Balance
            </a>{' '}
            by signing into your Alberta Blue Cross plan
          </p>
        </div>
      </div>
    </div>
    <div className="section-7 atb-section-7">
      <div className="container">
        <div className="left">
          <h2>extra benefits</h2>
          <h1>Paramedical</h1>
          <p>
            Mental Health paramedical benefits coverage is available for
            eligible plan participants. This coverage can be used towards
            psychologists or social workers, either in-person or on other
            platforms.
          </p>

          <p>
            Most suitable for those who would like to access services of a
            psychologist or for additional sessions with their EFAP counsellor.
          </p>
          <div className="divider" />
          <p className="details">
            For more details log into your{' '}
            <a
              href="https://atbbenefits.hroffice.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              ATB Benefits Portal
            </a>
            .
          </p>
        </div>
        <div className="right">
          <div className="white-box">
            <img src={ATBWhite} alt="ATB" />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Index;

import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { isCFS, isNSHN } from '../../../utils/pageUtils';
import inkblotLogo from '../../../images/cfs/inkblot-logo.png';
import nshnLogo from '../../../images/cfs/nshn-logo.png';
import nshnLogoFr from '../../../images/cfs/nshn-logo-fr.png';
import gscLogo from '../../../images/cfs/gsc-logo.png';
import gscLogoFr from '../../../images/cfs/gsc-logo-fr.png';
import { getLocale } from '../../../utils/locale';
import cfsLogo from '../../../images/cfs/cfs-logo.png';

const Section3 = () => {
  const isFr = getLocale() === 'fr';
  const path =
    typeof window !== 'undefined' &&
    window &&
    window.location &&
    window.location.pathname;
  return (
    <div className="section-3">
      <div className="flex-row">
        <div className="section-3-half-column">
          <div className="s3-text">
            <div className="s3-header">
              <FormattedMessage id="cfs.yourPlan" defaultMessage="Your Plan" />
            </div>
            <div className="s3-subheader">
              {isNSHN(path) ? (
                <FormattedMessage
                  id="nshn.yourMental"
                  defaultMessage="Your mental health matters and that is why GSC, National Student Health Network and Inkblot have partnered together to provide you with a confidential and convenient Student Assistance Program that includes 5 hours of video counselling, legal and financial consultations, health, life and career coaching, plus 24/7/365 immediate crisis support."
                />
              ) : (
                <FormattedMessage
                  id="cfs.yourMental"
                  defaultMessage="Your mental health matters and that is why Canadian Federation of Students and Inkblot have partnered together to provide you with confidential and convenient video counselling and mental health support."
                />
              )}
            </div>
            <div className="s3-subheader bold">
              <FormattedMessage
                id="cfs.yourInitial"
                defaultMessage="Your initial individual consultation is complimentary."
              />
            </div>
            <div className="s3-subheader">
              {isNSHN(path) ? (
                <FormattedHTMLMessage
                  id="nshn.subsequentIn"
                  defaultMessage="Subsequent sessions cost just $90/hour which may be which may be reimbursed through your GSC health plan. To learn more about your GSC Benefits Plan <a style='color: #0d24e1; text-decoration: underline' href='https://www.greenshield.ca/en-ca/student-centre'>click here</a>"
                />
              ) : (
                <FormattedHTMLMessage
                  id="cfs.subsequentIn"
                  defaultMessage="Subsequent sessions cost just $90/hour which may be covered by your private health insurance, benefits coverage or paid privately."
                />
              )}
            </div>
          </div>
        </div>
        <div className="section-3-half-column">
          <div className="logo-stack">
            <img
              src={inkblotLogo}
              alt="Inkblot Logo"
              height="75px"
              width="271px"
            />
            {isNSHN(path) ? (
              <>
                <img
                  src={isFr ? nshnLogoFr : nshnLogo}
                  alt="NSHN Logo"
                  height="100px"
                  width="340.7px"
                  style={{ 'padding-right': isFr ? '120px' : '0px' }}
                />
                <img
                  src={isFr ? gscLogoFr : gscLogo}
                  alt="GSC Logo"
                  height="99px"
                  width="225px"
                />
              </>
            ) : (
              <img
                src={cfsLogo}
                alt="CFS Logo"
                height="100px"
                width="340.7px"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

Section3.propTypes = {};

export default Section3;

import React, { useState } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import MetaTags from 'react-meta-tags';
import ModalVideo from 'react-modal-video';
import LanguageSelector from './LanguageSelector';
import PeopleLogo from '../../images/people-connect-total-health/people-logo.png';
import PeopleLogoTwo from '../../images/people-connect-total-health/people-logo-two.png';
import FrPeopleLogo from '../../images/people-connect-total-health/people-logo-french.png';
import FrPeopleLogoTwo from '../../images/people-connect-total-health/people-logo-french-two.png';
import Telus from '../../images/people-connect-total-health/telus.png';
import TelusFr from '../../images/people-connect-total-health/telusFr.png';
import PocketPills from '../../images/people-connect-total-health/pocket-pills.png';
import FrPocketPills from '../../images/people-connect-total-health/pocket-pills-fr.png';
import appStoreImage from '../../images/people-connect-total-health/app-store.svg';
import CarePath from '../../images/people-connect-total-health/carepath-logo.png';
import CarePathFr from '../../images/people-connect-total-health/carepath-logo-fr.png';
import NutritionalHealthCoachingFr from '../../images/people-connect-total-health/nutritional-health-coaching-logo-fr.png';
import NutritionalHealthCoaching from '../../images/people-connect-total-health/nutritional-health-coaching-logo.png';
import googlePlayImage from '../../images/people-connect-total-health/google-play.svg';
import HCFRLogo from '../../images/custom/logos/HCFR-logo.png';

const Index = props => {
  const [isOpenPC, setOpenPC] = useState(false);
  const [isOpenTelus, setOpenTelus] = useState(false);
  const [isOpenPocketPills, setOpenPocketPills] = useState(false);
  const isFrench = localStorage.getItem('selectedLanguage') === 'fr';
  const isPCMCHCHealthTelushealth = () => {
    return props.path === '/PCMCHC_health_telushealth';
  };

  return (
    <div className="people-connect-health">
      <MetaTags>
        <meta name="robots" content="noindex" />
      </MetaTags>
      <LanguageSelector />
      <div className="section-1">
        {isFrench ? (
          <img
            className="people-logo"
            src={FrPeopleLogo}
            alt="la people corporation"
          />
        ) : (
          <img className="people-logo" src={PeopleLogo} alt="people Logo" />
        )}
        {isPCMCHCHealthTelushealth() ?
          <>
            <FormattedHTMLMessage
              tagName="h1"
              id="pch.peopleConnectMedicalCare"
              defaultMessage="People Connect Medical Care"
            />
            <div className="divider" />
            <div className="a-unique">
              <FormattedHTMLMessage tagName="p" id="pch.aUnique" />
            </div> 
          </> :
          <>
            <FormattedHTMLMessage
              tagName="h1"
              id="pch.peopleConnectTotalHealth"
              defaultMessage="People Connect Total Health"
            />
            <div className="divider" />
            <div className="a-unique">
              <FormattedHTMLMessage tagName="p" id="pch.telusHealthDescription" />
            </div> 
          </>

        }
        <FormattedHTMLMessage tagName="h4" id="pch.yourProviders" />
        <div className="company-container">
          <div>
            {isFrench ? (
              <img src={FrPeopleLogoTwo} alt="people connecte logo" />
            ) : (
              <img src={PeopleLogoTwo} alt="people connect logo" />
            )}
          </div>
          <div>
            <img src={isFrench ? TelusFr : Telus} alt="telus" />
          </div>
          <div>
            <img
              src={isFrench ? FrPocketPills : PocketPills}
              alt="PocketPills"
            />
          </div>
          {isPCMCHCHealthTelushealth() && (
            <>
              <div>
                <img
                  src={
                    isFrench
                      ? HCFRLogo
                      : NutritionalHealthCoaching
                  }
                  alt="telus"
                />
              </div>
              <div>
                <img src={isFrench ? CarePathFr : CarePath} alt="telus" />
              </div>
            </>
          )}
        </div>
      </div>
      <div className="section-2">
        <div className="container">
          <div className="left">
            <div className="white-box">
              {isFrench ? (
                <img src={FrPeopleLogoTwo} alt="people connecte logo" />
              ) : (
                <img src={PeopleLogoTwo} alt="people connect logo" />
              )}
            </div>
          </div>
          <div className="right">
            <FormattedHTMLMessage
              tagName="h2"
              id="pch.mentalHealthSolution"
              defaultMessage="Mental Health Solution"
            />
            <FormattedHTMLMessage
              tagName="h1"
              id="pch.peopleConnect"
              defaultMessage="People Connect"
            />
            <FormattedHTMLMessage tagName="p" id="pch.proprietary" />
            <a
              className="pch-button"
              href={
                isFrench
                  ? 'https://peoplevousconnecte.com/'
                  : 'https://pcpeopleconnect.com/'
              }
              target="_blank"
              rel="noreferrer"
            >
              <FormattedHTMLMessage tagName="a" id="pch.launchPeopleConnect" />
            </a>
            <a
              className="pch-button"
              onClick={() => setOpenPC(true)}
              role="presentation"
            >
              <FormattedHTMLMessage tagName="a" id="pch.learnMore" />
            </a>
          </div>
        </div>
      </div>
      <div className="section-3">
        <div className="container">
          <div className="left">
            <div className="white-box">
              <img src={isFrench ? TelusFr : Telus} alt="telus" />
            </div>
          </div>
          <div className="right">
            <FormattedHTMLMessage
              tagName="h2"
              id="pch.nursePractitioners"
              defaultMessage="Nurse practitioners"
            />
            <FormattedHTMLMessage
              tagName="h1"
              id="pch.telus"
              defaultMessage="TELUS Health"
            />
            <FormattedHTMLMessage tagName="p" id="pch.partneredTelus" />
            <div className="left">
              <a
                href="https://apps.apple.com/ca/app/akira-a-doctor-in-your-pocket/id1084429206"
                target="_blank"
                rel="noreferrer"
              >
                <img src={appStoreImage} alt="App Store" />
              </a>
              &nbsp;&nbsp;
              <a
                href="https://play.google.com/store/apps/details?id=md.akira"
                target="_blank"
                rel="noreferrer"
              >
                <img src={googlePlayImage} alt="Google Play" />
              </a>
            </div>
            <a
              className="pch-button"
              onClick={() => setOpenTelus(true)}
              role="presentation"
            >
              <FormattedHTMLMessage
                tagName="a"
                id="pch.learnMoreTelus"
                defaultMessage="Learn more about TELUS Health"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="section-4">
        <div className="container">
          <div className="left">
            <div className="white-box">
              <img
                src={isFrench ? FrPocketPills : PocketPills}
                alt="PocketPills"
              />
            </div>
          </div>
          <div className="right">
            <FormattedHTMLMessage
              tagName="h2"
              id="pch.pharmacy"
              defaultMessage="Pharmacy"
            />
            <FormattedHTMLMessage
              tagName="h1"
              id="pch.pocketPills"
              defaultMessage="PocketPills"
            />
            <FormattedHTMLMessage tagName="p" id="pch.saveonDrug" />
            <a
              className="pch-button"
              href="https://www.pocketpills.com/ "
              target="_blank"
              rel="noreferrer"
            >
              <FormattedHTMLMessage tagName="a" id="pch.viewDetails" />
            </a>
            <br />
            <br />
            <FormattedHTMLMessage tagName="p" id="pch.support" />
            <a
              className="pch-button"
              onClick={() => setOpenPocketPills(true)}
              role="presentation"
            >
              <FormattedHTMLMessage tagName="a" id="pch.learnMorePocketPills" />
            </a>
          </div>
        </div>
      </div>
      {isPCMCHCHealthTelushealth() && (
        <>
          <div className="section-5">
            <div className="container">
              <div className="left">
                <div className="white-box">
                  <img
                    src={
                      isFrench
                        ? HCFRLogo
                        : NutritionalHealthCoaching
                    }
                    alt="NutritionalHealthCoachingLogo"
                  />
                </div>
              </div>
              <div className="right">
                <FormattedHTMLMessage tagName="h1" id="pch.nutritional" />
                <FormattedHTMLMessage
                  tagName="p"
                  id="pch.youAndYourDependentFamilyMembers"
                />
                <FormattedHTMLMessage tagName="p" id="pch.goalTracker" />
                <a
                  className="pch-button"
                  href="https://enroll.ecounsellors.ca/peoplecorp"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FormattedHTMLMessage tagName="a" id="pch.viewDetails" />
                </a>
                <br />
                <br />
                <ol>
                  <FormattedHTMLMessage tagName="p" id="pch.clickLetsStart" />
                </ol>
              </div>
            </div>
          </div>
          <div className="section-6">
            <div className="container">
              <div className="left">
                <div className="white-box">
                  <img
                    src={isFrench ? CarePathFr : CarePath}
                    alt="NutritionalHealthCoachingLogo"
                  />
                </div>
              </div>
              <div className="right">
                <FormattedHTMLMessage tagName="h1" id="pch.carePath" />
                <FormattedHTMLMessage
                  tagName="p"
                  id="pch.carePathProvidesHandsOn"
                />
                <FormattedHTMLMessage
                  tagName="p"
                  id="pch.healthNavigationAsuiteOfSupport"
                />
                <a
                  className="pch-button"
                  href={
                    isFrench
                      ? 'https://www.peopleconnectcarepath.ca/fr'
                      : 'https://www.peopleconnectcarepath.ca/'
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <FormattedHTMLMessage tagName="a" id="pch.viewDetails" />
                </a>
                <br />
                <br />
              </div>
            </div>
          </div>
        </>
      )}
      <ModalVideo
        channel="vimeo"
        autoplay
        isOpen={isOpenPC}
        videoId={isFrench ? '738967866' : '738734767'}
        onClose={() => setOpenPC(false)}
      />
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpenTelus}
        videoId={isFrench ? 'iqSU9FHD61Y' : 'GMVkoxoh8RQ'}
        onClose={() => setOpenTelus(false)}
      />
      <ModalVideo
        channel="vimeo"
        autoplay
        isOpen={isOpenPocketPills}
        videoId={isFrench ? '480019502' : '380532695'}
        onClose={() => setOpenPocketPills(false)}
      />
    </div>
  );
};

export default Index;

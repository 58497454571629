import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { injectIntl } from 'react-intl';
import { Link } from 'gatsby';
import MetaTags from 'react-meta-tags';
import _ from 'lodash';
import { validEmail } from 'src/utils/form';
import Consent from '../auth/consent';
import {
  SERVER_URL,
  THERAPY_URL,
  OTHER_SERVER_URL,
  OTHER_THERAPY_URL,
  COUNTRY,
} from '../../utils/environment';
import { redirectToTherapy } from '../../utils/redirect';
import {
  unauthAuditProgress,
  auditProgress,
  parseCompanyCodeFromPath,
} from '../../utils/pageUtils';
import LogEventOnMount from '../LogEventOnMount';
import { OPEN_SIGNUP, logEvent, COMPLETED_SIGNUP } from '../../utils/amplitude';
import { minSignupData } from '../../pagePartials/auth';
import EyeIcon from '../EyeIcon';
class MLFSignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signUpDisabled: false,
      error: {},
      visiblePassword: {},
    };
  }

  componentDidMount() {
    unauthAuditProgress(parseCompanyCodeFromPath(), 'open_signup');
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.recentlyUpdated !== prevState.recentlyUpdated) {
      this.validateForm(this.state.recentlyUpdated);
    }
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      recentlyUpdated: name,
    });
  };

  handleChangeCheckbox = event => {
    this.setState({
      privacyConsent: event.target.checked,
      recentlyUpdated: event.target.name,
    });
  };

  handleSubmit = event => {
    const { signUpDisabled } = this.state;
    event.preventDefault();

    if (!signUpDisabled) this.signUp();
  };

  emailExists = email => {
    axios
      .get(
        `${SERVER_URL}/api/signup/check_email?email=${encodeURIComponent(
          email,
        )}`,
      )
      .then(() => {})
      .catch(e => {
        this.setState({
          error: {
            ...this.state.error,
            email: e.response.data.error,
          },
        });
      });
  };

  signUp = () => {
    const { emailError, country, status, privacyConsent } = this.state;
    if (emailError) {
      return false;
    }
    const corporate_account = new URL(window.location.href).searchParams.get('company');

    let apiUrl;
    let appUrl;

    if (country === 'Canada' && COUNTRY === 'CA') {
      apiUrl = SERVER_URL;
      appUrl = THERAPY_URL;
    } else {
      apiUrl = OTHER_SERVER_URL;
      appUrl = OTHER_THERAPY_URL;
    }
    const values = _.omit(this.state, [
      'showForm',
      'message',
      'signUpDisabled',
      'error',
      'signUpError',
      'recentlyUpdated',
      'privacyConsent'
    ]);
    const mergedValues = { ...values, source: 'therapy', consent_yn: privacyConsent, corporate_account };
    if (status === '1') {
      axios
        .post(`${SERVER_URL}/api/signup`, mergedValues)
        .then(response => {
          const token = response.data.access_token;
          logEvent(COMPLETED_SIGNUP, { page: parseCompanyCodeFromPath() });
          auditProgress(parseCompanyCodeFromPath(), 'completed_signup', token);
          redirectToTherapy(token);
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.log(e);
        });
    } else {
      const { email, first_name, last_name, password, privacyConsent } = this.state;
      axios
        .post(`${apiUrl}/api/v2/eap_dependants`, {
          corporate_account,
          country,
          email,
          first_name,
          last_name,
          password,
          relationship: status === '3' ? 'spouse' : 'dependant',
          source: 'therapy',
          consent_yn: privacyConsent
        })
        .then(response => {
          const token = response.data.access_token;
          redirectToTherapy(token, null, appUrl);
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.log(e);
        });
    }
    return true;
  };

  validateForm = recentlyUpdated => {
    const isFr = localStorage.getItem('selectedLanguage') === 'fr';
    const requiredField = isFr
      ? 'Ceci est un champ obligatoire'
      : 'This is a required field';
    const emailValid = isFr
      ? 'Veuillez saisir une adresse courriel - Exemple, utilisateur@exemple.com'
      : 'Please enter an email address - Example, user@example.com';
    const passwordSix = isFr
      ? 'Le mot de passe doit être au moins de 8 caractères '
      : 'Password must be at least 8 characters';
    const passwordMatch = isFr
      ? 'Les mots de passe doivent correspondre'
      : 'Passwords must match';

    const {
      first_name,
      last_name,
      email,
      privacyConsent,
      password,
      password_confirm,
    } = this.state;
    const error = {};

    if (first_name === '') {
      error.first_name = requiredField;
    }
    if (last_name === '') {
      error.last_name = requiredField;
    }
    if (email === '') {
      error.email = requiredField;
    }
    if (!privacyConsent) {
      error.privacyConsent = requiredField;
    }
    if (email) {
      if (validEmail.test(email)) {
        // to reduce memory usage, we will call check_email api only when email field updated
        if (recentlyUpdated === 'email') {
          this.emailExists(email);
        }
      } else {
        error.email = emailValid;
      }
    }
    if (password) {
      if (password.length < 8) {
        error.password = passwordSix;
      } else if (password !== password_confirm) {
        error.password_confirm = passwordMatch;
      }
    }

    this.setState({
      recentlyUpdated: null,
      signUpDisabled:
        !_.isEmpty(error) ||
        !first_name ||
        !last_name ||
        !email ||
        !privacyConsent ||
        !password ||
        !password_confirm,
      error,
    });
  };

  renderConsent = () => {
    const { error } = this.state;
    return (
      <Consent error={error} handleChangeCheckbox={this.handleChangeCheckbox} />
    );
  };

  renderInputs = inputs => {
    const { error, visiblePassword } = this.state;
    return _.map(inputs, ({ id, name, type, fullWidth, text, defaultText }) => (
      <div key={id}>
        <h4>
          {this.props.intl.formatMessage({
            id: text,
            defaultMessage: defaultText,
          })}
        </h4>
        {type === 'password' ? (
          <div className="password-input">
            <div className="text-input">
              <input
                id={name}
                className={fullWidth ? 'full-width' : ''}
                onChange={event => this.handleChange(event)}
                type={visiblePassword[name] ? 'text' : 'password'}
                name={name}
                placeholder={this.props.intl.formatMessage({
                  id: text,
                  defaultMessage: defaultText,
                })}
                value={this.state[name]}
              />
            </div>
            <EyeIcon isVisible={visiblePassword[name]} onClick={() => {
              const visible = { ...visiblePassword };
              visible[name] = !visiblePassword[name];
              this.setState({ visiblePassword: { ...visible } });
            }}
            />
          </div>
        ) : (
          <input
            id={name}
            className={fullWidth ? 'full-width' : ''}
            onChange={event => this.handleChange(event)}
            type={type}
            name={name}
            placeholder={
              type === 'email'
                ? 'example@example.com'
                : this.props.intl.formatMessage({
                  id: text,
                  defaultMessage: defaultText,
                })
            }
            value={this.state[name]}
          />
        )}
        <p
          className={`error-text ${error[name] ? '' : 'hidden'}`}
          style={{ fontSize: '11px', position: 'absolute' }}
        >
          {error[name]}
        </p>
      </div>
    ));
  };

  render() {
    return (
      <div className="custom-auth custom-dependants" role="main">
        <MetaTags>
          <meta name="robots" content="noindex" />
        </MetaTags>
        <div
          className="dependants-container custom-auth-container"
          style={{ margin: '50px auto' }}
        >
          <div className="dependant-auth-form">
            <h2>
              {this.props.intl.formatMessage({
                id: 'eap.signup',
                defaultMessage: 'Sign up',
              })}
            </h2>
            <form onSubmit={this.handleSubmit}>
              {this.renderInputs(minSignupData)}
              <div>
                <h4>
                  {this.props.intl.formatMessage({
                    id: 'eap.role',
                    defaultMessage: 'Status',
                  })}
                </h4>
                <select
                  className="role-dropdown"
                  onChange={value => this.handleChange(value)}
                  type="text"
                  name="status"
                  defaultValue=""
                  required
                >
                  <option value="">Select an option</option>
                  <option value="1">Employee/Member</option>
                  <option value="3">Spouse</option>
                  <option value="4">Dependent</option>
                </select>
              </div>
              <div>
                <h4>
                  {this.props.intl.formatMessage({
                    id: 'eap.country',
                    defaultMessage: 'Country',
                  })}
                </h4>
                <select
                  className="role-dropdown"
                  onChange={value => this.handleChange(value)}
                  type="text"
                  name="country"
                  defaultValue=""
                  required
                >
                  <option value="">Select an option</option>
                  <option value="Canada">Canada</option>
                  <option value="United States">United States</option>
                </select>
              </div>
              {this.renderConsent()}
              <div>
                <button type="submit">
                  {this.props.intl.formatMessage({
                    id: 'eap.signup',
                    defaultMessage: 'Sign Up',
                  })}
                </button>
              </div>
            </form>
            <span className="accessibility-h4">
              {this.props.intl.formatMessage({
                id: 'eap.alreadyHave',
                defaultMessage: 'Already have an account?',
              })}
              <Link
                to="/sign_in?company=mapleleaffoods"
                style={{ color: '#2a5ccd' }}
              >
                {this.props.intl.formatMessage({
                  id: 'eap.signin',
                  defaultMessage: 'Sign In',
                })}
              </Link>
            </span>
          </div>
        </div>
        <LogEventOnMount
          eventType={OPEN_SIGNUP}
          eventProperties={{ page: parseCompanyCodeFromPath() }}
        />
      </div>
    );
  }
}

MLFSignup.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(MLFSignup);

import React from 'react';
import Section1 from '../../pagePartials/maple-leaf-foods/Section1';
import Section2 from '../../pagePartials/maple-leaf-foods/Section2';
import Section3 from '../../pagePartials/maple-leaf-foods/Section3';
import Section3a from '../../pagePartials/maple-leaf-foods/Section3a';
import Section4 from '../../pagePartials/maple-leaf-foods/Section4';
import Section5 from '../../pagePartials/maple-leaf-foods/Section5';

const Index = () => {
  const locale = localStorage.getItem('selectedLanguage');

  return (
    <div className="mlf-index-page">
      <Section1 locale={locale}/>
      <Section2 />
      <Section3 />
      <Section3a />
      <Section4 locale={locale}/>
      <Section5 />
    </div>
  );
}

export default Index;

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import GatedSignUp from './GatedSignUp';
import NonGatedSignUp from './NonGatedSignUp';

function TrustBased({ eligibilityFile, ...props }) {
  const [showSignup, setShowSignup] = useState(!eligibilityFile);

  return showSignup ? (
    <NonGatedSignUp {...props} />
  ) : (
    <GatedSignUp {...props} redirectToSignup setShowSignup={setShowSignup} />
  );
}

TrustBased.propTypes = {
  eligibilityFile: PropTypes.bool.isRequired,
};

export default TrustBased;

import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';

import SearchIcon from '../../images/search.svg';
import {FormattedMessage} from 'react-intl';

function SearchDropdown({
  label,
  handleChange,
  handleInputChange,
  name,
  options,
  placeholder,
  filterOption,
}) {
  const DropdownIndicator = props => (
    <components.DropdownIndicator {...props}>
      <img src={SearchIcon} alt="search" style={{ width: 20, height: 20 }} />
    </components.DropdownIndicator>
  );

  return (
    <div className="search-dropdown">
      <h4 className="label">{label}</h4>
      <Select
        classNamePrefix="search-dropdown"
        options={options}
        placeholder={placeholder}
        isSearchable
        isClearable
        name={name}
        components={{ DropdownIndicator }}
        onInputChange={newQuery => handleInputChange(newQuery)}
        onChange={value => handleChange(value)}
        filterOption={filterOption}
        noOptionsMessage={() => <FormattedMessage id="eap.companySearchPlaceholder" />}
      />
    </div>
  );
}

SearchDropdown.propTypes = {
  handleChange: PropTypes.func,
  handleInputChange: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  filterOption: PropTypes.func,
};

SearchDropdown.defaultProps = {
  handleChange: () => {},
  handleInputChange: () => {},
  label: '',
  options: [],
  placeholder: '',
};

export default SearchDropdown;

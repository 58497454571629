import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import MetaTags from 'react-meta-tags';
import LifeInc from '../../images/people-connect-total-health/life-inc-logo.png';

import PeopleLogoTwo from '../../images/people-connect-total-health/people-logo-two.png';
import FrPeopleLogoTwo from '../../images/people-connect-total-health/people-logo-french-two.png';
import Telus from '../../images/people-connect-total-health/telus.png';
import TelusFr from '../../images/people-connect-total-health/telusFr.png';
import PocketPills from '../../images/people-connect-total-health/pocket-pills.png';

const Index = () => {
  const isFrench = localStorage.getItem('selectedLanguage') === 'fr';

  return (
    <div className="people-connect-health">
      <MetaTags>
        <meta name="robots" content="noindex" />
      </MetaTags>
      <div className="lifeinc-section-1">
        <img className="lifeinc-logo" src={LifeInc} alt="life inc Logo" />
        <h1 className="lifeinc-h1">Life&apos;s Virtual Health Solution</h1>
        <div className="lifeinc-divider" />
        <div className="a-unique">
          <p>
            Life – A Benefit Plan for First Nations, has developed Life’s
            Virtual Health Solution, a unique and proprietary end to end virtual
            solution. From medical consultation and mental health support, to
            medication delivery; it provides access to virtual health care
            professionals, therapists, diagnostic tools, psychiatric
            collaboration, and a digital pharmacy that provides home delivery.
          </p>
        </div>
        <FormattedHTMLMessage tagName="h4" id="pch.yourProviders" />
        <div className="company-container">
          <div>
            {isFrench ? (
              <img src={FrPeopleLogoTwo} alt="people connecte logo" />
            ) : (
              <img src={PeopleLogoTwo} alt="people connect logo" />
            )}
          </div>
          <div>
            <img src={isFrench ? TelusFr : Telus} alt="telus" />
          </div>
          <div>
            <img src={PocketPills} alt="PocketPills" />
          </div>
        </div>
      </div>
      <div className="lifeinc-section-2">
        <div className="container">
          <div className="left">
            <div className="white-box">
              {isFrench ? (
                <img src={FrPeopleLogoTwo} alt="people connecte logo" />
              ) : (
                <img src={PeopleLogoTwo} alt="people connect logo" />
              )}
            </div>
          </div>
          <div className="right">
            <FormattedHTMLMessage
              tagName="h2"
              id="pch.mentalHealthSolution"
              defaultMessage="Mental Health Solution"
            />
            <FormattedHTMLMessage
              tagName="h1"
              id="pch.peopleConnect"
              defaultMessage="People Connect"
            />
            <FormattedHTMLMessage
              tagName="p"
              id="pch.lifeincProprietary"
              defaultMessage="A proprietary solution built to positively support your mental health through any situation.  This service provides educational resources and access to immediate care through virtual counselling.  Included is a full diagnostic tool to better understand your mental health. People Connect is the only provider that includes consultation with a psychiatrist in a virtual collaboration meeting.<br><br>People Connect utilizes the coverage provided under your group insurance paramedical coverage making it easier to get affordable therapy.  The first consultation is free of charge and  additional sessions can be submitted under the paramedical coverage of your group insurance.<br><br>Available to you and your adult dependants (over the age of 18) listed on your health care plan."
            />
            <a
              className="pch-button"
              href="https://lifeinc.pcpeopleconnect.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedHTMLMessage
                tagName="a"
                id="pch.launchPeopleConnect"
                defaultMessage="Launch People Connect"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="section-3">
        <div className="container">
          <div className="left">
            <div className="white-box">
              <img src={isFrench ? TelusFr : Telus} alt="akira" />
            </div>
          </div>
          <div className="right">
            <FormattedHTMLMessage
              tagName="h2"
              id="pch.nursePractitioners"
              defaultMessage="Nurse practitioners"
            />
            <FormattedHTMLMessage
              tagName="h1"
              id="pch.telus"
              defaultMessage="TELUS Health"
            />
            <FormattedHTMLMessage
              tagName="p"
              id="pch.partneredLifeIncTelus"
              defaultMessage="TELUS Health is a app-based service that connects employees and their families with nurses and nurse practitioners. Service is available 24/7/365 and health professionals can answer medical questions, diagnose, suggest treatment, renew prescriptions, and write referrals.<br><br>Available to you and all your dependants listed on your health care plan."
            />
            <a
              className="pch-button"
              href="http://plus.telushealth.co/page/employers/akira/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedHTMLMessage
                tagName="a"
                id="pch.visitWebsite"
                defaultMessage="Visit Website"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="section-4">
        <div className="container">
          <div className="left">
            <div className="white-box">
              <img src={PocketPills} alt="PocketPills" />
            </div>
          </div>
          <div className="right">
            <FormattedHTMLMessage
              tagName="h2"
              id="pch.pharmacy"
              defaultMessage="Pharmacy"
            />
            <FormattedHTMLMessage
              tagName="h1"
              id="pch.pocketPills"
              defaultMessage="PocketPills"
            />
            <FormattedHTMLMessage
              tagName="p"
              id="pch.saveonDrug"
              defaultMessage="Pocketpills is Canada’s leading online pharmacy. Through our easy-to-use app and website, members can fill prescriptions, chat with pharmacists, and order medication with fast, free delivery. Join Pocketpills today and discover a faster, friendlier pharmacy experience! <br><br> <ul><li>Free delivery anywhere in Canada</li><li> Automatic refills and easy renewals </li> <li> Pre-sorted medication Pocketpacks </li> <li> Consult with pharmacists via phone, text, email, or online chat</li></ul>"
            />
            <a
              className="pch-button"
              href="https://www.youtube.com/watch?v=F8nM85c4PdI&feature=youtu.be"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedHTMLMessage
                tagName="a"
                id="pch.viewDetails"
                defaultMessage="View Details"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { validEmail } from 'src/utils/form';

function validateEmail(email) {
  return validEmail.test(email);
}

export default function formValidation(values) {
  const errors = {};
  const requiredField = <FormattedMessage id="error.requiredField" />;
  const emailMustBeValid = <FormattedMessage id="error.emailMustBeValid" />;
  const passwordLength = <FormattedMessage id="error.passwordLength" />;
  const passwordMatch = <FormattedMessage id="error.passwordMatch" />;

  if (values.first_name === '') {
    errors.first_name = requiredField;
  }
  if (values.last_name === '') {
    errors.last_name = requiredField;
  }
  if (values.email === '') {
    errors.email = requiredField;
  }
  if (values.password === '') {
    errors.password = requiredField;
  }
  if (values.relationship === '') {
    errors.relationship = requiredField;
  }
  if (!values.privacyConsent) {
    errors.privacyConsent = requiredField;
  }

  if (values.email && !validateEmail(values.email)) {
    errors.email = emailMustBeValid;
  }

  if (values.password) {
    if (values.password.length < 8) {
      errors.password = passwordLength;
    } else if (values.password !== values.password_confirm) {
      errors.password_confirm = passwordMatch;
    }
  }

  return errors;
}

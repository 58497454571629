/* eslint-disable arrow-body-style */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import step2 from '../../../images/custom/screens/Customcounsellormatchcard.png';
import french2 from '../../../images/custom/screens/Customcounsellormatchcardfrench.png';
import blob from '../../../images/ilob/blob-blue.png';

const featureTwo = {
  id: 'ilob.customCounsellor',
  title: 'Custom Counsellor Match',
  descriptionId: 'ilob.afterQuestionnaire',
  description:
    'After the questionnaire, you’ll be directed to a provider selection page where matches will be ranked based on effectiveness and your individual needs.',
  image: step2,
  frImage: french2,
};

const Section6 = props => {
  const { id, title, description, descriptionId, image, frImage } = featureTwo;
  return (
    <div className="section-6">
      <div className="s6-image">
        <img
          src={blob}
          alt="Background Blob"
          height="837.5px"
          width="643.6px"
        />
      </div>
      <div className="v2-s5-image">
        <div
          alt=""
          className="bg-url-image"
          style={{
            backgroundImage: `url(${
              localStorage.getItem('selectedLanguage') !== 'fr'
                ? image
                : frImage
            })`,
          }}
        />
        <img
          className="url-image"
          src={
            localStorage.getItem('selectedLanguage') !== 'fr' ? image : frImage
          }
          alt={title}
        />
      </div>
      <div className="v2-s5-text">
        <div className="text">
          <span>{props.intl.formatMessage({ id, defaultMessage: title })}</span>
        </div>
        <div className="subheader">
          {props.intl.formatMessage({
            id: descriptionId,
            defaultMessage: description,
          })}
        </div>
      </div>
    </div>
  );
};

Section6.propTypes = {
  intl: PropTypes.object,
};

Section6.defaultProps = {
  intl: {},
};

export default injectIntl(Section6);

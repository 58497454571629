/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { injectIntl } from 'react-intl';
import { Link } from 'gatsby';
import MetaTags from 'react-meta-tags';

import { validEmail } from 'src/utils/form';
import Consent from '../auth/consent';
import SessionTwo from '../../images/custom/illustrations/session-2.svg';
import { SERVER_URL } from '../../utils/environment';
import { redirectToTherapy } from '../../utils/redirect';
import {
  unauthAuditProgress,
  whichCompany,
  auditProgress,
} from '../../utils/pageUtils';
import LogEventOnMount from '../LogEventOnMount';
import {
  OPEN_SIGNUP,
  OPEN_FORGOT_PASSWORD,
  logEvent,
  COMPLETED_SIGNUP,
} from '../../utils/amplitude';
import { atbSignupData } from '../../pagePartials/auth';
import EyeIcon from '../EyeIcon';

class ATBSignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signUpDisabled: false,
      message: false,
      showForm: false,
      error: {},
      visiblePassword: {},
    };
  }

  componentDidMount() {
    unauthAuditProgress(whichCompany().path, 'open_forgot_password');
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.recentlyUpdated !== prevState.recentlyUpdated) {
      this.validateForm(this.state.recentlyUpdated);
    }
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      message: false,
      recentlyUpdated: name,
    });
  };

  handleChangeCheckbox = event => {
    this.setState({
      privacyConsent: event.target.checked,
      recentlyUpdated: event.target.name,
    });
  };

  forgotPassword = () => {
    const { employee_id } = this.state;
    axios
      .post(`${SERVER_URL}/api/v2/forgot_passwords/atb_signup`, { employee_id })
      .then(response => {
        if (
          response.data.message &&
          response.data.message === 'welcome_email_sent'
        ) {
          logEvent(OPEN_SIGNUP, { page: whichCompany().path });
          unauthAuditProgress(whichCompany().path, 'sent_forgot_password');
          this.setState({
            message:
              'We will send you an email to create your account shortly.',
          });
        } else {
          this.setState({ message: response.data.message });
        }
      })
      .catch(() => {
        this.setState({ message: 'Not a valid Employee ID.' });
      });
  };

  signup = () => {
    const { privacyConsent } = this.state;
    const values = _.omit(this.state, [
      'showForm',
      'message',
      'signUpDisabled',
      'error',
      'signUpError',
      'recentlyUpdated',
      'privacyConsent'
    ]);
    const corporate_account = whichCompany().path;
    const mergedValues = { ...values, source: 'therapy', consent_yn: privacyConsent };
    axios
      .post(`${SERVER_URL}/api/v2/forgot_passwords/atb_employee_leave`, mergedValues)
      .then(response => {
        const token = response.data.access_token;
        logEvent(COMPLETED_SIGNUP, { page: corporate_account });
        auditProgress(corporate_account, 'completed_signup', token);
        redirectToTherapy(token);
      })
      .catch(error => {
        if (error.response && error.response.data.error) {
          this.setState({ message: error.response.data.error });
        } else {
          this.setState({ message: 'Error!' });
        }
      });
  };

  handleSubmitForCheck = event => {
    event.preventDefault();
    this.forgotPassword();
  };

  handleSignupForCheck = event => {
    const { signUpDisabled } = this.state;
    event.preventDefault();

    if (!signUpDisabled) this.signup();
  };

  emailExists = email => {
    axios
      .get(
        `${SERVER_URL}/api/signup/check_email?email=${encodeURIComponent(
          email,
        )}`,
      )
      .then(() => {})
      .catch(e => {
        const isFr = localStorage.getItem('selectedLanguage') === 'fr';
        this.setState({
          error: {
            ...this.state.error,
            email: isFr ? 'Ce courriel existe déjà' : e.response.data.error ,
          },
        });
      });
  };

  validateForm = recentlyUpdated => {
    const requiredField = 'This is a required field';
    const emailValid =
      'Please enter an email address - Example, user@example.com';
    const employeeIdValid = 'Employee ID must follow e## or E### format';
    const passwordSix = 'Password must be at least 8 characters';
    const passwordMatch = 'Passwords must match';

    const {
      first_name,
      last_name,
      email,
      privacyConsent,
      password,
      password_confirm,
      employee_id,
    } = this.state;
    const error = {};

    if (first_name === '') {
      error.first_name = requiredField;
    }
    if (last_name === '') {
      error.last_name = requiredField;
    }
    if (!privacyConsent) {
      error.privacyConsent = requiredField;
    }
    if (email) {
      if (validEmail.test(email)) {
        // to reduce memory usage, we will call check_email api only when email field updated
        if (recentlyUpdated === 'email') {
          this.emailExists(email);
        }
      } else {
        error.email = emailValid;
      }
    }
    if (password) {
      if (password.length < 8) {
        error.password = passwordSix;
      } else if (password !== password_confirm) {
        error.password_confirm = passwordMatch;
      }
    }
    if (employee_id) {
      const validEmployeeId = /^(e|E)[0-9]+$/;
      if (!validEmployeeId.test(employee_id)) {
        error.employee_id = employeeIdValid;
      }
    }

    this.setState({
      recentlyUpdated: null,
      signUpDisabled:
        !_.isEmpty(error) ||
        !first_name ||
        !last_name ||
        !privacyConsent ||
        !email ||
        !password ||
        !password_confirm ||
        !employee_id,
      error,
    });
  };

  renderInputs = inputs => {
    const { error, visiblePassword } = this.state;
    return (
      _.map(inputs, ({
        id, name, type, fullWidth, text, defaultText,
      }) => (
        <div key={id}>
          <h4>
            {this.props.intl.formatMessage({
              id: text,
              defaultMessage: defaultText,
            })}
          </h4>
          {type === 'password' ? (
            <div className="password-input">
              <div className="text-input">
                <input
                  id={name}
                  className={fullWidth ? 'full-width' : ''}
                  onChange={event => this.handleChange(event)}
                  type={visiblePassword[name] ? 'text' : 'password'}
                  name={name}
                  placeholder={defaultText}
                  value={this.state[name]}
                />
              </div>
              <EyeIcon isVisible={visiblePassword[name]} onClick={() => {
                const visible = { ...visiblePassword };
                visible[name] = !visiblePassword[name];
                this.setState({ visiblePassword: { ...visible } });
              }}
              />
            </div>
        ) : (
          <input
            id={name}
            className={fullWidth ? 'full-width' : ''}
            onChange={event => this.handleChange(event)}
            type={type}
            name={name}
            placeholder={defaultText}
            value={this.state[name]}
          />
        )}
        <p
          className={`error-text ${error[name] ? '' : 'hidden'}`}
          style={{ fontSize: '11px', position: 'absolute' }}
        >
          {error[name]}
        </p>
      </div>
      ))
    );
  };

  renderConsent = () => {
    const { error } = this.state;
    return (
      <Consent error={error} handleChangeCheckbox={this.handleChangeCheckbox} />
    );
  };

  renderSignupForm = () => (
    <div className="dependants-container custom-auth-container">
      <div className="dependant-auth-form">
        <h2>
          {this.props.intl.formatMessage({
            id: 'eap.accountCreation',
            defaultMessage: 'Account Creation',
          })}
        </h2>
        <form onSubmit={this.handleSignupForCheck}>
          {this.renderInputs(atbSignupData)}
          {this.renderConsent()}
          <div>
            <button type="submit" disabled={this.state.signUpDisabled}>
              {this.props.intl.formatMessage({
                id: 'eap.signup',
                defaultMessage: 'Sign Up',
              })}
            </button>
          </div>
        </form>
        <span
          role="button"
          tabIndex={0}
          onClick={() => this.setState({ showForm: false })}
          style={{
            color: 'rgb(42, 92, 205)',
            cursor: 'pointer',
            fontSize: '18px',
            fontFamily: 'Barlow',
            fontWeight: 'bold',
          }}
        >
          Back
        </span>
      </div>
    </div>
  );

  render() {
    const { message, showForm } = this.state;
    const { path } = whichCompany();
    return (
      <div className="custom-auth" role="main">
        <MetaTags>
          <meta name="robots" content="noindex" />
        </MetaTags>
        {
          showForm ? this.renderSignupForm() :
            <div className="auth-container custom-auth-container">
              <div className="custom-auth-text">
                <h2>
                  {this.props.intl.formatMessage({
                    id: 'eap.gettingStarted',
                    defaultMessage: 'Getting Started',
                  })}
                </h2>
                <form onSubmit={this.handleSubmitForCheck}>
                  <h4>
                    {this.props.intl.formatMessage({
                      id: 'eap.employeeIdStarred',
                      defaultMessage: 'Employee ID *',
                    })}
                  </h4>
                  <input name="employee_id" type="text" onChange={this.handleChange} />
                  <button disabled={message} type="submit">
                    {this.props.intl.formatMessage({
                      id: 'eap.submit',
                      defaultMessage: 'Submit',
                    })}
                  </button>
                </form>
                {message && (
                  <p>
                    {message}
                  </p>
                )}
                <h4>
                  {this.props.intl.formatMessage({
                    id: 'eap.spouseOrDependant',
                    defaultMessage: 'Are you a spouse or a dependant?',
                  })}{' '}
                  <Link
                    to={`/dependants?company=${path}`}
                    style={{ color: '#2a5ccd' }}
                  >
                    {this.props.intl.formatMessage({
                      id: 'eap.registerHere',
                      defaultMessage: 'Sign Up Here',
                    })}
                  </Link>
                </h4>
                <h4>
                  {this.props.intl.formatMessage({
                    id: 'eap.memberOnLeave',
                    defaultMessage: 'Are you a team member on leave?',
                  })}{' '}
                  <span
                    role="button"
                    tabIndex={0}
                    onClick={() => this.setState({ showForm: true })}
                    style={{ color: '#2a5ccd', cursor: 'pointer' }}
                  >
                    {this.props.intl.formatMessage({
                      id: 'eap.registerHere',
                      defaultMessage: 'Sign Up Here',
                    })}
                  </span>
                </h4>
                <span className="accessibility-h4">
                  {this.props.intl.formatMessage({
                    id: 'eap.alreadyHave',
                    defaultMessage: 'Already have an account?',
                  })}
                  <Link to={`/sign_in?company=${path}`} style={{ color: '#2a5ccd' }}>
                    {this.props.intl.formatMessage({
                      id: 'eap.signin',
                      defaultMessage: 'Sign In',
                    })}
                  </Link>
                </span>
              </div>
              <img src={SessionTwo} alt="" />
            </div>
        }
        <LogEventOnMount
          eventType={OPEN_FORGOT_PASSWORD}
          eventProperties={{ page: path }}
        />
      </div>
    );
  }
}

ATBSignup.propTypes = {
  intl: PropTypes.object,
};

ATBSignup.defaultProps = {
  intl: {},
};

export default injectIntl(ATBSignup);

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';
import queryString from 'query-string';

import { SERVER_URL } from '../../utils/environment';
import {
  OPEN_FORGOT_PASSWORD,
  logEvent,
  SENT_FORGOT_PASSWORD,
} from '../../utils/amplitude';
import { getLocale } from '../../utils/locale';
import TextInput from '../TextInput';
import LogEventOnMount from '../LogEventOnMount';
import FormButton from './FormButton';

function GatedSignUp({
  clearError,
  companyId,
  companyName,
  employeeId,
  goBack,
  path,
  redirectToSignup,
  setError,
  setShowSignup,
}) {
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [sentReset, setSentReset] = useState(false);

  const inputLabelId = employeeId ? 'eap.employeeIdStarred' : 'eap.workEmail';
  const inputName = employeeId ? 'employee_id' : 'email';

  function handleChange(event) {
    setInput(event.target.value);
  }

  function handleSubmit(event) {
    const identification = employeeId
      ? { employee_id: input }
      : { email: input };
    const params = {
      ...identification,
      company_id: companyId,
      locale: getLocale(),
    };
    const requestUrl = `${SERVER_URL}/api/v2/b2b_auth/portal_gating?${queryString.stringify(
      params,
    )}`;

    event.preventDefault();
    clearError();
    setLoading(true);

    axios
      .get(requestUrl)
      .then(() => {
        setLoading(false);
        setSentReset(true);

        logEvent(SENT_FORGOT_PASSWORD, {
          company: companyName,
          page: path,
          page_type: 'reseller portal - gsc, honeybee',
          flow_type: 'b2b registration',
        });
      })
      .catch(error => {
        setLoading(false);

        if (error.response.status === 404 && redirectToSignup) {
          setShowSignup(true);
        } else {
          setError(error);
        }
      });
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <TextInput
          handleChange={handleChange}
          label={<FormattedMessage id={inputLabelId} />}
          name={inputName}
        />
        <FormButton disabled={sentReset} loading={loading} goBack={goBack} />
      </form>
      {sentReset && (
        <p className="success-message">
          <FormattedMessage id="eap.emailLink" />
        </p>
      )}
      <LogEventOnMount
        eventType={OPEN_FORGOT_PASSWORD}
        eventProperties={{
          company: companyName,
          page: path,
          page_type: 'reseller portal - gsc, honeybee',
          flow_type: 'b2b registration',
        }}
      />
    </div>
  );
}

GatedSignUp.propTypes = {
  clearError: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  companyName: PropTypes.string.isRequired,
  employeeId: PropTypes.bool,
  goBack: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  redirectToSignup: PropTypes.bool,
  setError: PropTypes.func.isRequired,
  setShowSignup: PropTypes.func,
};

GatedSignUp.defaultProps = {
  employeeId: false,
  redirectToSignup: false,
  setShowSignup: () => {},
};

export default GatedSignUp;

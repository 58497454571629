import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import mlfLogo from '../../images/custom/logos/maple-leaf-foods-logo.svg';

const Section5 = () => (
  <div className="section section-5">
    <div className="image">
      <img src={mlfLogo} alt="Maple Leaf logo" />
    </div>
    <div className="text">
      <FormattedHTMLMessage
        tagName="h3"
        id="mlf.extraCoverage"
        defaultMessage="EXTRA COVERAGE"
      />
      <hr style={{ width: '276px', marginBottom: '20px', backgroundColor: '#ffffff' }} />
      <FormattedHTMLMessage
        tagName="h2"
        id="mlf.paramedical"
        defaultMessage="Paramedical"
      />
      <FormattedHTMLMessage
        tagName="p"
        id="mlf.yourParamedical"
        defaultMessage="Your paramedical benefits can be used to see psychologists, social workers
        (e.g. MSWs) or psychotherapistsin-person or on other platforms. You have around $1500/year.
        Please note this amount includes care from other types of healthcare professionals
        (physiotherapists,massage therapists, etc.)"
      />
      <FormattedHTMLMessage
        tagName="p"
        id="mlf.mostSuitableMaple"
        defaultMessage="<span class='second-p'>Most suitable for those who would like to see in-person
        counsellors near them.</span>"
      />
    </div>
  </div>
);

export default Section5;

import React, { useState } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import MetaTags from 'react-meta-tags';
import ModalVideo from 'react-modal-video';
import LanguageSelector from './LanguageSelector';
import PeopleLogo from '../../images/people-connect-total-health/people-logo.png';
import PeopleLogoTwo from '../../images/people-connect-total-health/people-logo-two.png';
import FrPeopleLogo from '../../images/people-connect-total-health/people-logo-french.png';
import FrPeopleLogoTwo from '../../images/people-connect-total-health/people-logo-french-two.png';
import Maple from '../../images/people-connect-total-health/maple.png';
import PocketPills from '../../images/people-connect-total-health/pocket-pills.png';

const Index = () => {
  const [isOpenPC, setOpenPC] = useState(false);
  const [isOpenMaple, setOpenMaple] = useState(false);
  const [isOpenPocketPills, setOpenPocketPills] = useState(false);
  const isFrench = localStorage.getItem('selectedLanguage') === 'fr';

  return (
    <div className="people-connect-health">
      <MetaTags>
        <meta name="robots" content="noindex" />
      </MetaTags>
      <LanguageSelector />
      <div className="section-1">
        {isFrench ? (
          <img className="people-logo" src={FrPeopleLogo} alt="la people corporation" />
        )  : (
          <img className="people-logo" src={PeopleLogo} alt="people Logo" />
        )}
        <FormattedHTMLMessage
          tagName="h1"
          id="pch.peopleConnectTotalHealth"
          defaultMessage="People Connect Total Health"
        />
        <div className="divider" />
        <div className="a-unique">
          <FormattedHTMLMessage tagName="p" id="pch.mapleDescription" />
        </div>
        <FormattedHTMLMessage tagName="h4" id="pch.yourProviders" />
        <div className="company-container">
          <div>
            {isFrench ? (
              <img src={FrPeopleLogoTwo} alt="people connecte logo" />
            ) : (
              <img src={PeopleLogoTwo} alt="people connect logo" />
            )}
          </div>
          <div>
            <img src={Maple} alt="Maple" />
          </div>
          <div>
            <img src={PocketPills} alt="PocketPills" />
          </div>
        </div>
      </div>
      <div className="section-2">
        <div className="container">
          <div className="left">
            <div className="white-box">
              {isFrench ? (
                <img src={FrPeopleLogoTwo} alt="people connecte logo" />
              ) : (
                <img src={PeopleLogoTwo} alt="people connect logo" />
              )}
            </div>
          </div>
          <div className="right">
            <FormattedHTMLMessage
              tagName="h2"
              id="pch.mentalHealthSolution"
              defaultMessage="Mental Health Solution"
            />
            <FormattedHTMLMessage
              tagName="h1"
              id="pch.peopleConnect"
              defaultMessage="People Connect"
            />
            <FormattedHTMLMessage
              tagName="p"
              id="pch.proprietary"
            />
            <a
              className="pch-button"
              href={isFrench ? 'https://peoplevousconnecte.com/' : 'https://pcpeopleconnect.com/'}
              target="_blank"
              rel="noreferrer"
            >
              <FormattedHTMLMessage
                tagName="a"
                id="pch.launchPeopleConnect"
              />
            </a>
            <a
              className="pch-button"
              onClick={() => setOpenPC(true)}
              role="presentation"
            >
              <FormattedHTMLMessage
                tagName="a"
                id="pch.learnMore"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="section-3">
        <div className="container">
          <div className="left">
            <div className="white-box">
              <img src={Maple} alt="maple" />
            </div>
          </div>
          <div className="right">
            <FormattedHTMLMessage
              tagName="h2"
              id="pch.virtualHealth"
              defaultMessage="Virtual Health"
            />
            <FormattedHTMLMessage
              tagName="h1"
              id="pch.maple"
              defaultMessage="Maple"
            />
            <FormattedHTMLMessage
              tagName="p"
              id="pch.mapleIsAPhysician"
            />
            <a
              className="pch-button"
              onClick={() => setOpenMaple(true)}
              role="presentation"
            >
              <FormattedHTMLMessage
                tagName="a"
                id="pch.learnMoreMaple"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="section-4">
        <div className="container">
          <div className="left">
            <div className="white-box">
              <img src={PocketPills} alt="people" />
            </div>
          </div>
          <div className="right">
            <FormattedHTMLMessage
              tagName="h2"
              id="pch.pharmacy"
              defaultMessage="Pharmacy"
            />
            <FormattedHTMLMessage
              tagName="h1"
              id="pch.pocketPills"
              defaultMessage="PocketPills"
            />
            <FormattedHTMLMessage
              tagName="p"
              id="pch.saveonDrug"
            />
            <a
              className="pch-button"
              href="https://www.pocketpills.com"
              target="_blank"
              rel="noreferrer"
            >
              <FormattedHTMLMessage
                tagName="a"
                id="pch.viewDetails"
              />
            </a>
            <br /><br />
            <FormattedHTMLMessage
              tagName="p"
              id="pch.support"
            />
            <a
              className="pch-button"
              onClick={() => setOpenPocketPills(true)}
              role="presentation"
            >
              <FormattedHTMLMessage
                tagName="a"
                id="pch.learnMorePocketPills"
              />
            </a>
          </div>
        </div>
      </div>
      <ModalVideo
        channel="vimeo"
        autoplay
        isOpen={isOpenPC}
        videoId={isFrench ? '738967866' : '738734767'}
        onClose={() => setOpenPC(false)}
      />
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpenMaple}
        videoId={isFrench ? 'UkFINBILQlM' : '8hN-d7EaXNw'}
        onClose={() => setOpenMaple(false)}
      />
      <ModalVideo
        channel="vimeo"
        autoplay
        isOpen={isOpenPocketPills}
        videoId={isFrench ? '480019502' : '380532695'}
        onClose={() => setOpenPocketPills(false)}
      />
    </div>
  );
};

export default Index;

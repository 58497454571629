import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { Element, scroller } from 'react-scroll';
import PropTypes from 'prop-types';

import SectionContainer from '../SectionContainer';
import { faqData } from '../../pagePartials/faq';
import FaqDropdown from '../../pagePartials/gallivan/FaqDropdown';

const Faq = props => {
  const inputRef = useRef();
  const [locationStateElement, setLocationStateElement] = useState('');

  useEffect(() => {
    if (props.location.state) {
      if (props.location.state.element) {
        setLocationStateElement(props.location.state.element);
      }
    }
  }, []);

  // to scroll to certain section if locationStateElement!==''
  // to focus to certain section if locationStateElement!==''
  useEffect(() => {
    if (locationStateElement !== '') {
      scroller.scrollTo(props.location.state.element, {
        duration: 1500,
        smooth: 'easeInOutQuart',
      });
      inputRef.current.focus();
    }
  }, [locationStateElement]);

  return (
    <div className="gallivan-faq-page">
      <SectionContainer container num={1}>
        <h1>FAQ</h1>
      </SectionContainer>
      <SectionContainer container num={2}>
        {_.map(faqData, eachFaQDataObject =>
          eachFaQDataObject.element &&
          locationStateElement === eachFaQDataObject.element ? (
            <div
              key={`div-for-focus-${eachFaQDataObject.id}`}
              tabIndex="-1"
              ref={inputRef}
            >
              <Element
                key={`element-for-scroll-${eachFaQDataObject.id}`}
                name={eachFaQDataObject.element}
              >
                <FaqDropdown
                  key={eachFaQDataObject.id}
                  question={eachFaQDataObject.question}
                  answer={eachFaQDataObject.answer}
                />
              </Element>
            </div>
          ) : (
            <FaqDropdown
              key={eachFaQDataObject.id}
              question={eachFaQDataObject.question}
              answer={eachFaQDataObject.answer}
            />
          ),
        )}
      </SectionContainer>
    </div>
  );
};

Faq.propTypes = {
  location: PropTypes.object,
};

export default Faq;

import React from 'react';
import Section1 from '../../pagePartials/league/Section1';
import Section2 from '../../pagePartials/league/Section2';
import Section3 from '../../pagePartials/new/Section6';
import Section3a from '../ReferralLanding/Sections/Section4';
import Section3b from '../ReferralLanding/Sections/Section5';
import Section3c from '../ReferralLanding/Sections/Section6';
import Section3d from '../ReferralLanding/Sections/Section7';
import Section4 from '../../pagePartials/league/Section4';
import Section5 from '../../pagePartials/league/Section5';

const Index = () => (
  <div className="new league" role="main">
    <Section1 />
    <Section2 />
    <Section3 />
    <Section3a />
    <Section3b />
    <Section3c />
    <Section3d />
    <Section4 />
    <Section5 />
  </div>
);

Index.propTypes = {};

export default Index;

import React from 'react';
import { FormattedMessage } from 'react-intl';
import inkblotLogo from '../../../images/cfs/inkblot-logo.png';
import gscLogo from '../../../images/cfs/gsc-logo.png';
import gscLogoFr from '../../../images/cfs/gsc-logo-fr.png';
import { getLocale } from '../../../utils/locale';

const Section3 = () => {
  const isFr = getLocale() === 'fr';

  return (
    <div className="section-3">
      <div className="flex-row">
        <div className="section-3-half-column">
          <div className="s3-text">
            <div className="s3-header">
              <FormattedMessage
                id="ilob.yourCoverage"
                defaultMessage="Your Coverage"
              />
            </div>
            <div className="s3-subheader bold">
              <FormattedMessage
                id="ilob.yourHealthPlan"
                defaultMessage="Your health plan provides two hours of individual and two hours of couples counselling. (Your initial consultation is complimentary)."
              />
            </div>
            <div className="s3-subheader">
              <FormattedMessage
                id="ilob.thereAre"
                defaultMessage="There are no out-of-pocket expenses for these four hours of therapy. Additional sessions with your Inkblot therapist are easy to arrange. And you can rest assured:  Inkblot's fees are significantly lower than the national average, and eligible for coverage and reimbursement under the Psychology benefit of your plan."
              />
            </div>
          </div>
        </div>
        <div className="section-3-half-column">
          <div className="logo-stack">
            <img
              src={inkblotLogo}
              alt="Inkblot Logo"
              height="75px"
              width="271px"
            />
            <img
              src={isFr ? gscLogoFr : gscLogo}
              alt="GSC Logo"
              height="99px"
              width="225px"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Section3.propTypes = {};

export default Section3;

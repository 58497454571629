import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import mlfLogo from '../../images/custom/logos/maple-leaf-foods-logo.svg';
import inkblotLogo from '../../images/custom/logos/inkblot-logo-mlf.png';
import mindBeaconLogo from '../../images/custom/logos/mind-beacon-logo.png';
import telusHealthLogo from '../../images/custom/logos/telus-health-logo.png';
import telusSanteLogo from '../../images/custom/logos/telus-sante-logo.png';

const Section1 = ({locale}) => {
  const setLanguage = lang => {
    localStorage.setItem('selectedLanguage', lang);
    window.location.reload();
  };

  return (
    <div className="section-1">
      <div className="en-fr">
        {
          localStorage.getItem('selectedLanguage') === 'en' ?
            <div>
              <span>
                EN
              </span>
              <span role="presentation" onClick={() => setLanguage('fr')} style={{ fontWeight: 300 }}>
                FR
              </span>
            </div>
            :
            <div>
              <span role="presentation" onClick={() => setLanguage('en')} style={{ fontWeight: 300 }}>
                EN
              </span>
              <span>
                FR
              </span>
            </div>
          }
      </div>
      <img src={mlfLogo} alt="Maple Leaf Foods logo" />
      <FormattedHTMLMessage
        tagName="h1"
        id="mlf.youreNotAlone"
        defaultMessage="You Are Not Alone: Your mental health resources"
      />
      <hr style={{ width: '276px', margin: '0 auto 20px auto', backgroundColor: '#999999' }} />
      <FormattedHTMLMessage
        tagName="h4"
        id="mlf.learnMore"
        defaultMessage="Learn more about your comprehensive benefits package."
      />
      <FormattedHTMLMessage
        tagName="h5"
        id="mlf.yourProviders"
        defaultMessage="YOUR PROVIDERS:"
      />
      <div className="logos">
        <img src={inkblotLogo} id="inkblot" alt="Inkblot logo" />
        <img src={mindBeaconLogo} id="beacon" alt="Beacon logo" />
        {locale === 'fr' ?
          <img src={telusSanteLogo} id="morneau" alt="Telus Health logo" /> :
          <img src={telusHealthLogo} id="morneau" alt="Telus Health logo" />
        }
      </div>
    </div>
  );
};

export default Section1;

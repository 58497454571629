/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { injectIntl } from 'react-intl';
import { Link } from 'gatsby';
import MetaTags from 'react-meta-tags';

import { validEmail } from 'src/utils/form';
import Consent from '../auth/consent';
import SessionTwo from '../../images/custom/illustrations/session-2.svg';
import { SERVER_URL } from '../../utils/environment';
import { redirectToTherapy } from '../../utils/redirect';
import {
  unauthAuditProgress,
  whichCompany,
  auditProgress,
} from '../../utils/pageUtils';
import LogEventOnMount from '../LogEventOnMount';
import {
  OPEN_SIGNUP,
  OPEN_FORGOT_PASSWORD,
  logEvent,
  COMPLETED_SIGNUP,
} from '../../utils/amplitude';
import { employeeIdData } from '../../pagePartials/auth';
import EyeIcon from '../EyeIcon';

export function getEmployeeTittleEN (path) {
  if (path === 'saftcab') return 'RWAM'
  else if (path === 'arta') return 'Member'
  else if (path === 'eiqc') return 'Student'
  else if (path === 'asebp') return 'ASEBP'
  else return 'Employee'
}

export function getEmployeeTittleFR (path) {
  if (path === 'saftcab') return 'RWAM'
  else if (path === 'arta') return 'membres'
  else if (path === 'eiqc') return 'étudiant'
  else if (path === 'asebp') return 'ASEBP'
  else return 'employé(e)'
}

class EmployeeIdSignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: false,
      showForm: false,
      error: {},
      errorExistingEmail: null,
      employee_id: null,
      visiblePassword: {},
      signUpDisabled: true,
    };
  }

  componentDidMount() {
    unauthAuditProgress(whichCompany().path, 'open_forgot_password');
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.recentlyUpdated !== prevState.recentlyUpdated) {
      this.validateForm(this.state.recentlyUpdated);
    }
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      message: false,
      recentlyUpdated: name,
    });
  };

  handleChangeCheckbox = event => {
    this.setState({
      privacyConsent: event.target.checked,
      recentlyUpdated: event.target.name,
    });
  };

  forgotPassword = () => {
    const { companyID } = this.props;
    const { employee_id } = this.state;
    const methodParams = {
      employee_id,
      company_id: companyID, // company query by id is higher piority than url
      company_url: whichCompany().path,
    };
    axios
      .post(
        `${SERVER_URL}/api/v2/forgot_passwords/employee_id_signup`,
        methodParams,
      )
      .then(
        response => {
          if (response.data.message) {
            if (response.data.message === 'redirect_to_sign_up') {
              this.setState({ showForm: true, employee_id });
            } else if (response.data.message === 'welcome_email_sent') {
              logEvent(OPEN_SIGNUP, {
                page: whichCompany().path,
                flow_type: 'b2b registration',
                b2b_page_type: 'custom portal',
                gating_type: 'fully gated w/ ei',
                company: whichCompany().path,
              });
              unauthAuditProgress(whichCompany().path, 'sent_forgot_password');
              this.setState({
                message:
                  'An email with a link to complete your registration will be sent to you shortly.',
              });
            } else {
              this.setState({ message: response.data.message });
            }
          }
        },
        error => {
          const apiError = error.response?.data?.error;
          if (apiError) throw apiError;
        },
      )
      .catch(error => {
        this.setState({ message: 'Not a valid Employee ID.' });
      });
  };

  renderConsent = () => {
    const { error } = this.state;
    return (
      <Consent error={error} handleChangeCheckbox={this.handleChangeCheckbox} />
    );
  };

  signup = () => {
    const {
      email,
      employee_id,
      password,
      password_confirm,
      privacyConsent,
    } = this.state;
    const corporate_account = whichCompany().path;
    const params = {
      email,
      employee_id,
      password,
      password_confirm,
      company_id: this.props.companyID, // company query by id is higher piority than url
      company_url: whichCompany().path,
      consent_yn: privacyConsent,
    };
    axios
      .post(`${SERVER_URL}/api/v2/forgot_passwords/email_signup`, params)
      .then(response => {
        const token = response.data.access_token;
        logEvent(COMPLETED_SIGNUP, { page: corporate_account });
        auditProgress(corporate_account, 'completed_signup', token);
        redirectToTherapy(token);
      })
      .catch(() => {
        this.setState({ message: 'Not a valid Employee ID.' });
      });
  };

  handleSubmitForCheck = event => {
    event.preventDefault();
    this.forgotPassword();
  };

  handleSignupForCheck = event => {
    event.preventDefault();
    this.signup();
  };

  emailExists = email => {
    axios
      .get(
        `${SERVER_URL}/api/signup/check_email?email=${encodeURIComponent(
          email,
        )}`,
      )
      .then(() =>
        this.setState({ errorExistingEmail: null }, () => this.validateForm()),
      )
      .catch(e =>
        this.setState({ errorExistingEmail: e.response.data.error }, () =>
          this.validateForm(),
        ),
      );
  };

  validateForm = recentlyUpdated => {
    const isFr = localStorage.getItem('selectedLanguage') === 'fr';
    const emailValid =
      'Please enter an email address - Example, user@example.com';
    const passwordSix = 'Password must be at least 8 characters';
    const passwordMatch = 'Passwords must match';
    const requiredField = isFr
      ? 'Ceci est un champ obligatoire'
      : 'This is a required field';

    const {
      email,
      password,
      password_confirm,
      employee_id,
      privacyConsent,
    } = this.state;
    const error = {};

    if (email) {
      if (validEmail.test(email)) {
        // to reduce memory usage, we will call check_email api only when email field updated
        if (recentlyUpdated === 'email') {
          this.emailExists(email);
        }
      } else {
        error.email = emailValid;
        this.setState({ errorExistingEmail: null });
      }
    }
    if (password) {
      if (password.length < 8) {
        error.password = passwordSix;
      } else if (password !== password_confirm) {
        error.password_confirm = passwordMatch;
      }
    }

    if (!privacyConsent) {
      error.privacyConsent = requiredField;
    }

    this.setState(state => ({
      recentlyUpdated: null,
      signUpDisabled:
        !_.isEmpty(error) ||
        !email ||
        !password ||
        !password_confirm ||
        !employee_id ||
        !_.isEmpty(state.errorExistingEmail),
      error,
    }));
  };

  renderInputs = inputs => {
    const {
      error,
      visiblePassword,
      employee_id,
      errorExistingEmail,
    } = this.state;
    return _.map(inputs, ({ id, name, type, fullWidth, text, defaultText }) => (
      <div key={id}>
        <h4>
          {this.props.intl.formatMessage({
            id: text,
            defaultMessage: defaultText,
          })}
        </h4>
        {type === 'password' && (
          <div className="password-input">
            <div className="text-input">
              <input
                id={name}
                className={fullWidth ? 'full-width' : ''}
                onChange={event => this.handleChange(event)}
                type={visiblePassword[name] ? 'text' : 'password'}
                name={name}
                placeholder={defaultText}
                value={this.state[name]}
              />
            </div>
            <EyeIcon
              isVisible={visiblePassword[name]}
              onClick={() => {
                const visible = { ...visiblePassword };
                visible[name] = !visiblePassword[name];
                this.setState({ visiblePassword: { ...visible } });
              }}
            />
          </div>
        )}
        {type !== 'password' &&
          (name === 'employee_id' ? (
            <input
              id={name}
              onChange={value => this.handleChange(value)}
              type={type}
              name={name}
              className={fullWidth ? 'full-width' : ''}
              required
              value={employee_id}
              disabled
            />
          ) : (
            <input
              id={name}
              onChange={value => this.handleChange(value)}
              type={type}
              name={name}
              className={fullWidth ? 'full-width' : ''}
              required
            />
          ))}
        {name === 'email' ? (
          <p
            className={`error-text ${
              error[name] || errorExistingEmail ? '' : 'hidden'
            }`}
            style={{ fontSize: '11px', position: 'absolute' }}
          >
            {error[name]} {errorExistingEmail}
          </p>
        ) : (
          <p
            className={`error-text ${error[name] ? '' : 'hidden'}`}
            style={{ fontSize: '11px', position: 'absolute' }}
          >
            {error[name]}
          </p>
        )}
      </div>
    ));
  };

  renderSignupForm = () => (
    <div className="dependants-container custom-auth-container">
      <div className="dependant-auth-form">
        <h2>
          {this.props.intl.formatMessage({
            id: 'eap.accountCreation',
            defaultMessage: 'Account Creation',
          })}
        </h2>
        <form onSubmit={this.handleSignupForCheck}>
          {this.renderInputs(employeeIdData)}
          {this.renderConsent()}
          <div>
            <button type="submit" disabled={this.state.signUpDisabled}>
              {this.props.intl.formatMessage({
                id: 'eap.signup',
                defaultMessage: 'Sign Up',
              })}
            </button>
          </div>
        </form>
        {this.state.message && <p>{this.state.message}</p>}
        <span
          role="button"
          tabIndex={0}
          onClick={() => this.setState({ showForm: false })}
          disabled={this.state.signUpDisabled}
        >
          Back
        </span>
      </div>
    </div>
  );

  renderMessage = () => {
    const { intl } = this.props;
    const { message } = this.state;
    let formattedMessage;

    if (!message) return <p />;

    if (message === 'Not a valid Employee ID.') {
      formattedMessage = intl.formatMessage(
        {
          id: 'eap.invalidEmployeeId',
          defaultMessage: 'Not a valid {employeeEN} ID.',
        },
        {
          employeeEN:
            whichCompany().path === 'arta'
              ? 'Member'
              : whichCompany().path === 'eiqc'
              ? 'Student'
              : 'Employee',
          employeeFR:
            whichCompany().path === 'arta'
              ? 'membres'
              : whichCompany().path === 'eiqc'
              ? 'étudiant'
              : 'employé(e)',
        },
      );
    } else if (message === 'Email already exists.') {
      formattedMessage = intl.formatMessage({
        id: 'eap.emailExists',
        defaultMessage: 'An account already exists. Please sign in instead.',
      });
    } else {
      formattedMessage = intl.formatMessage({
        id: 'eap.emailLink',
        defaultMessage:
          'An email with a link to complete your registration will be sent to you shortly.',
      });
    }

    return <p>{formattedMessage}</p>;
  };

  render() {
    const { message, showForm } = this.state;
    const { path } = whichCompany();

    // reseller's child companies should be associated to child not parent
    const { childCompany } = this.props;
    const renderDependantLink = childCompany
      ? childCompany?.dependants
      : whichCompany()?.dependants;
    const dependantURL = childCompany
      ? `/dependants?companyId=${childCompany.value}`
      : `/dependants?company=${path}`;

    return (
      <div className="custom-auth" role="main">
        <MetaTags>
          <meta name="robots" content="noindex" />
        </MetaTags>
        {showForm ? (
          this.renderSignupForm()
        ) : (
          <div className="auth-container custom-auth-container">
            <div className="custom-auth-text">
              <h2>
                {this.props.intl.formatMessage({
                  id: 'eap.gettingStarted',
                  defaultMessage: 'Getting Started',
                })}
              </h2>
              <form onSubmit={this.handleSubmitForCheck}>
                <h4>
                  {this.props.intl.formatMessage(
                    {
                      id: path === 'firstcanadian'
                      ? 'eap.benefitCertificateNumber'
                      : 'eap.employeeIdStarredMemberOrEmployee',
                      defaultMessage: '{employeeEN} ID *',
                    },
                    {
                      employeeEN: getEmployeeTittleEN(whichCompany().path),
                      employeeFR: getEmployeeTittleFR(whichCompany().path)
                    },
                  )}
                </h4>
                <input
                  name="employee_id"
                  type="text"
                  onChange={this.handleChange}
                />
                <button disabled={message} type="submit">
                  {this.props.intl.formatMessage({
                    id: 'eap.submit',
                    defaultMessage: 'Submit',
                  })}
                </button>
              </form>
              {this.renderMessage()}
              {renderDependantLink && (
                <h4>
                  {this.props.intl.formatMessage({
                    id: 'eap.spouseOrDependant',
                    defaultMessage: 'Are you a spouse or a dependant?',
                  })}{' '}
                  <Link
                    to={dependantURL}
                    style={{ color: '#2a5ccd' }}
                  >
                    {this.props.intl.formatMessage({
                      id: 'eap.registerHere',
                      defaultMessage: 'Sign Up Here',
                    })}
                  </Link>
                </h4>
              )}
              <span className="accessibility-h4">
                {this.props.intl.formatMessage({
                  id: 'eap.alreadyHave',
                  defaultMessage: 'Already have an account?',
                })}
                <Link
                  to={`/sign_in?company=${path}`}
                  style={{ color: '#2a5ccd' }}
                >
                  {this.props.intl.formatMessage({
                    id: 'eap.signin',
                    defaultMessage: 'Sign In',
                  })}
                </Link>
              </span>
            </div>
            <img src={SessionTwo} alt="" />
          </div>
        )}
        <LogEventOnMount
          eventType={OPEN_FORGOT_PASSWORD}
          eventProperties={{
            page: path,
            flow_type: 'b2b registration',
            b2b_page_type: 'custom portal',
            gating_type: 'fully gated w/ ei',
            company: path,
          }}
        />
      </div>
    );
  }
}

EmployeeIdSignup.propTypes = {
  intl: PropTypes.object,
  childCompany: PropTypes.object,
};

EmployeeIdSignup.defaultProps = {
  intl: {},
};

export default injectIntl(EmployeeIdSignup);

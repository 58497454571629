/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { motion } from 'framer-motion';
import InkblotLogo from '../../images/nav/inkblot-logo.svg';
import Teamwork from '../../images/custom/illustrations/teamwork-white-8.svg';

const NotFound = () => (
  <div className="onboarding-auth">
    <motion.div
      initial={{ scale: 0 }}
      animate={{ rotate: 0, scale: 1 }}
      transition={{
          type: 'spring',
          stiffness: 100,
          damping: 15,
        }}
    >
      <div className="onboarding-container custom-onboarding-container">
        <div className="custom-onboard custom-auth-text custom-inkblot-therapy">
          <h2 style={{ marginBottom: 40 }}>
            <FormattedMessage
              id="onboarding.uhoh"
              defaultMessage="Uh oh!"
            />
          </h2>
          <p style={{ marginBottom: 10 }}>
            <FormattedMessage
              id="onboarding.errorPage1"
              defaultMessage="Something went wrong. Please try again by refreshing this page."
            />
          </p>
          <p>
            <FormattedMessage
              id="onboarding.errorPage2"
              defaultMessage="If you continue to see this screen, please {email} support or click on the support chat icon at the bottom right corner of the screen."
              values={{
                  email: (
                    <a
                      style={{ textDecoration: 'underline', color: '#2e5fca' }}
                      href="mailto:support@inkblottherapy.com"
                    >
                      email
                    </a>
                  ),
                }}
            />
          </p>
        </div>
        <img src={Teamwork} alt="not found" />
        <div className="custom-auth-logo">
          <img src={InkblotLogo} alt="inkblot logo" />
        </div>
      </div>
    </motion.div>
  </div>
);

export default NotFound;
